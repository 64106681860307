const strings = {
    status: {
        searching: 'Searching facilities...',
        pendingActivation: 'Activate your job search',
        active: '',
        noProcesses: '',
    },
    menu: {
        support: 'Support',
        privacyPolicy: 'Privacy policy',
        logout: 'Sign out',
    },
    profile: {
        title: 'Profile',
    },
};

export default strings;

import { useAppDispatch } from '@/_helpers/store';
import sessionSettingsSlice, { SessionSettings } from '@/_reducers/sessionSettings';
import { SessionSettingsState } from '@/_selectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

function useSessionSetting<T extends keyof SessionSettings>(setting: T) {
    const dispatch = useAppDispatch();
    const setVal = useCallback(
        (value: SessionSettings[T]) => {
            dispatch(sessionSettingsSlice.actions.updateSettings({ [setting]: value }));
        },
        [dispatch, setting],
    );
    const val = useSelector(SessionSettingsState)[setting];

    return {
        val,
        setVal,
    };
}

export default useSessionSetting;

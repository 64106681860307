import useExternalState from '@/hooks/useExternalState';
import { cn } from '@/lib/utils';
import { reusableComponents as componentsStrings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import MonthAndYearInput from './monthAndYearInput';
import { DateInputProps, InnerDateType } from './types';
import { dateToTextValue, monthAndYearToDate, parseDateString } from './utils';

const strings = componentsStrings.dateInput;

function DateInput({
    value: startDate,
    setValue: setStartDate,
    label: startDateLabel,
    endDate: endDateObj,
    error: externalError,
    setError: externalSetError,
    trackTextChange,
    withEndDate,
    className,
    ...props
}: DateInputProps) {
    const [startDateText, setStartDateText] = useState(dateToTextValue(startDate));
    const [endDateText, setEndDateText] = useState(
        dateToTextValue(endDateObj?.value ?? null),
    );
    const [endDate, setEndDate] = useExternalState(
        endDateObj?.value,
        endDateObj?.setValue,
    );

    const [startDateError, setStartError] = useState('');
    const [endDateError, setEndError] = useState('');
    const [inputError, setRangeError] = useState('');
    const [error, setError] = useExternalState(externalError, externalSetError);

    const indicateError = !!error;

    const handleChange = (type: InnerDateType, newVal: string) => {
        const setVal = type === InnerDateType.Start ? setStartDateText : setEndDateText;
        const setDate = type === InnerDateType.Start ? setStartDate : setEndDate;
        const setDateError = type === InnerDateType.Start ? setStartError : setEndError;
        setDateError('');
        trackTextChange && trackTextChange(type, newVal);
        if (newVal.replace(/\D/g, '').length < 6) {
            setDate(null);
        } else {
            const { month, year, error } = parseDateString(newVal);
            if (error) {
                setDateError(error);
            } else {
                setDate(monthAndYearToDate(Number(month), Number(year)));
                return;
            }
        }
        setVal(newVal);
    };

    const updateMainError = useCallback(() => {
        if (startDateError) {
            setError(startDateError);
        } else if (withEndDate && endDateError) {
            setError(endDateError);
        } else if (inputError) {
            setError(inputError);
        } else {
            setError('');
        }
    }, [startDateError, endDateError, withEndDate, inputError, setError]);

    useEffect(() => {
        updateMainError();
    }, [updateMainError]);

    useEffect(() => {
        if (startDate) {
            setStartDateText(dateToTextValue(startDate));
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) {
            setEndDateText(dateToTextValue(endDate));
        }
    }, [endDate]);

    useEffect(() => {
        if (startDate && startDate > new Date()) {
            setRangeError(strings.errors.futureStartDate);
        } else if (startDate && endDate && withEndDate && startDate > endDate) {
            setRangeError(strings.errors.invalidRange);
        } else {
            setRangeError('');
        }
    }, [startDate, endDate, withEndDate]);

    const endDateLabel = withEndDate ? endDateObj?.label : undefined;

    return (
        <div className={cn('w-full relative')}>
            {(startDateLabel || endDateLabel) && (
                <div className={cn('font-semibold text-13 mb-3 flex w-full')}>
                    <div className={cn('w-full')}>{startDateLabel}</div>
                    {endDateLabel && <div className={cn('w-full')}>{endDateLabel}</div>}
                </div>
            )}
            <div
                className={cn(
                    'relative flex items-center h-[54px] w-full',
                    'px-0 text-16 font-medium',
                    'rounded-2xl border  border-primary bg-white',
                    { 'border-red200': indicateError },
                    { 'opacity-50': props?.disabled },
                    className,
                )}
            >
                <MonthAndYearInput
                    value={startDateText}
                    setValue={(val) => handleChange(InnerDateType.Start, val)}
                    error={startDateError}
                    {...props}
                />
                {withEndDate && (
                    <>
                        <div className={cn('h-full w-0 border-l border-black/30')} />
                        <MonthAndYearInput
                            value={endDateText}
                            setValue={(val) => handleChange(InnerDateType.End, val)}
                            error={endDateError}
                            {...props}
                        />
                    </>
                )}
            </div>
            {indicateError && (
                <p className={cn('mt-3 font-medium text-red200 text-14')}>{error}</p>
            )}
        </div>
    );
}

export default DateInput;

import { useAppDispatch } from '@/_helpers/store';
import { enterDevMode } from '@/_reducers/devMode';
import Mousetrap from 'mousetrap';
import { useEffect } from 'react';

const SECRET_SEQUENCE = 'shulmandev';

function useEnterDevMode() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        Mousetrap.bind(SECRET_SEQUENCE.split('').join(' '), () => {
            console.log('Developer mode activated!');
            dispatch(enterDevMode());
        });
    }, [dispatch]);
}

export default useEnterDevMode;

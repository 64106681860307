import { valuesOf } from '@/lib/utils';
import { difference } from 'lodash-es';
import { createSelector } from 'reselect';

import { Organizations, ProcessModels } from '../generation_1';

export const MissingOrganizationIds = createSelector(
    ProcessModels,
    Organizations,
    (processes, organization) => {
        const neededOrgIds = valuesOf(processes).map((p) => p.organizationId);
        const existingOrganizationIds = valuesOf(organization).map((org) => org.id);
        return difference(neededOrgIds, existingOrganizationIds);
    },
);

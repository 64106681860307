import Logger from '@/_helpers/logger';
import { useAppDispatch } from '@/_helpers/store';
import { setDialogOpen } from '@/_reducers/dialogs';
import { recheduleProcessEvent, scheduleEventForProcess } from '@/_reducers/processes';
import { ScheduleInterviewDialogData } from '@/_selectors';
import { IsScheduleInterviewDialogOpen } from '@/_selectors/generation_1/dialogs';
import { DateIsoString } from '@/lib/base';
import { DialogName, ScheduleInterviewDialogVariant } from '@/lib/types';
import { getEventTypeForProcess } from '@/lib/utils';
import { dialogs as dialogStrings } from '@/strings';
import { useSelector } from 'react-redux';

import ScheduleInterviewDialog from './ScheduleInterviewDialog';

const strings = dialogStrings.scheduleInterview;

function ScheduleInterviewDialogProvider() {
    const dispatch = useAppDispatch();
    const shouldBeOpen = useSelector(IsScheduleInterviewDialogOpen);
    const { process, variant = ScheduleInterviewDialogVariant.AcceptInterview } =
        useSelector(ScheduleInterviewDialogData);
    const isReschedule = variant === ScheduleInterviewDialogVariant.Reschedule;
    const hasData = !!process && (!isReschedule || !!process.relevantEvent);

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        dispatch(setDialogOpen(DialogName.ScheduleInterview, open));
    };
    const handleClose = () => setOpen(false);

    const scheduleNewEvent = async (timeSlot: DateIsoString) => {
        if (!hasData) {
            return;
        }
        const eventType = getEventTypeForProcess(process);
        if (!eventType) {
            Logger.error(
                `Cannot find eventType for process with status ${process.status} (processId: ${process.id})`,
            );
            throw new Error(strings.submit.failureSubtitle);
        }
        return await dispatch(
            scheduleEventForProcess({
                processId: process.id,
                startAt: timeSlot,
                eventType,
            }),
        );
    };

    const rescheduleEvent = async (timeSlot: DateIsoString) => {
        if (!hasData || !process.relevantEvent) {
            return;
        }
        return await dispatch(
            recheduleProcessEvent({
                eventId: process.relevantEvent.id,
                startAt: timeSlot,
            }),
        );
    };

    return (
        <>
            {hasData && (
                <ScheduleInterviewDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    submitSchedule={isReschedule ? rescheduleEvent : scheduleNewEvent}
                    variant={variant}
                />
            )}
        </>
    );
}

export default ScheduleInterviewDialogProvider;

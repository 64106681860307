import { genericToasts, terminationReasonsToStrings } from '../common';

const strings = {
    declineInterview: {
        reasonPlacehloder: 'Choose a reason for declining',
        title: 'Decline interview and terminate process',
        subtitle: 'Choose a reason for declining.',
        submitButton: 'Decline interview and terminate process',
        success: {
            title: 'The interview was declined and process terminated',
            subtitle:
                "Thanks for your feedback! \nWe'll keep trying to find the best match for you",
        },
        headerNote: {
            label: 'Note',
            content: 'This will terminate the current process.',
        },
    },
    success: {
        title: 'Process terminated',
        subtitle:
            "Thanks for your feedback! \nWe'll keep trying to find the best match for you",
    },
    reasonPlacehloder: 'Choose a reason',
    title: 'Terminate process',
    subtitle: 'Let us know why you terminated the process',
    freeTextPlaceholder: {
        required: 'Add a description here',
        optional: 'Anything else you feel we should know? (Optional)',
    },
    terminationReasons: { ...terminationReasonsToStrings },
    submitButton: 'Terminate process',
    failureTitle: genericToasts.failure.failureTitle,
    confirmation: {
        title: {
            func: (org: string) =>
                `Are you sure you want to terminate the process with ${org}?`,
            example: ['RegalCare'],
        },
        subtitle: "You won't be able to change this later.",
        confirm: "I'm sure",
        cancel: "Wait, I'm not sure",
    },
};

export default strings;

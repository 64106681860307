import { VIcon } from '@/components/svgs';
import { OrgPerkCategory } from '@/lib/api/v1';
import { OrganizationBenefitModel } from '@/lib/types';
import { cn } from '@/lib/utils';
import { processes as processesStrings } from '@/strings';

const strings = processesStrings.processCard;

interface Props {
    benefits: OrganizationBenefitModel[];
}

export function PotentialBenefitsSection({ benefits }: Props) {
    return (
        <div className={cn('w-full px-10 flex flex-col gap-2')}>
            <div className={cn('text-16 font-bold')}>{strings.benefits}</div>
            {benefits
                .filter((p) => p.category === OrgPerkCategory.Benefit)
                .map((benefit) => (
                    <div
                        key={benefit.name}
                        className={cn('flex items-center w-full gap-4')}
                    >
                        <div
                            className={cn(
                                'w-4 h-4 flex items-center justify-center border-black border-[1.5px] rounded-full',
                            )}
                        >
                            <VIcon className={cn('stroke-black border-black h-[4px]')} />
                        </div>
                        <div className={cn('text-14 font-semibold')}>
                            {strings.orgBenefitToString[benefit.name]}
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default PotentialBenefitsSection;

import config from '@/config';

const RECAPTCHA_IFRAME_QUERY = 'iframe[src*="recaptcha"]';

export function getRecaptchaMainParent(element: Element): Element | null {
    const parent = element.parentElement;
    if (!parent) {
        return null;
    }
    if (parent.tagName.toLowerCase() === 'body') {
        return element;
    }
    return getRecaptchaMainParent(parent);
}

export const isRecaptchaVisible = (container: Element) => {
    const iframe = container.querySelector(RECAPTCHA_IFRAME_QUERY);
    if (iframe) {
        const rect = iframe.getBoundingClientRect();
        const style = window.getComputedStyle(iframe);
        return (
            rect.width > 0 &&
            rect.height > 0 &&
            style.visibility !== 'hidden' &&
            style.display !== 'none'
        );
    }
    return false;
};

export const getRecaptchaContainer = (): Element | null => {
    const iframes = Array.from(document.body.querySelectorAll(RECAPTCHA_IFRAME_QUERY));

    for (const iframe of iframes.reverse()) {
        const mainParent = getRecaptchaMainParent(iframe);
        if (mainParent?.getAttribute('id') !== config.RECAPTCHA_CONTAINER_ID) {
            return mainParent;
        }
    }
    return null;
};

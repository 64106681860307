import {
    ProcessAction,
    ProcessHistoryAdditionalAction,
    ProcessStatus,
} from '@/lib/api/v1';
import { CurrentProcessActionItemType, CustomHistoryAction, Process } from '@/lib/types';
import { format } from 'date-fns';

import {
    capitalizeFirstLetter,
    eventTypeToName,
    processStatusToEventString,
} from '../common';
import { exampleProcess } from '../examples';
import processesStrings from '../processes';

const getEventName = (status: ProcessStatus, capital = false) => {
    const text = processStatusToEventString(status) ?? 'event';
    return capital ? capitalizeFirstLetter(text) : text;
};

const processHistoryActionToString: Record<
    string,
    | string
    | {
          func: (status: ProcessStatus) => string;
          example: [ProcessStatus];
      }
> = {
    [ProcessAction.RecruiterApproval]: {
        func: (status) => `${getEventName(status, true)} requested`,
        example: [ProcessStatus.PhoneInterview],
    },
    [ProcessAction.CreateEvent]: {
        func: (status) => `${getEventName(status, true)} scheduled`,
        example: [ProcessStatus.PhoneInterview],
    },
    [ProcessAction.CompleteEvent]: {
        func: (status) => `${getEventName(status, true)}`,
        example: [ProcessStatus.InPersonInterview],
    },
    [ProcessAction.RequestRescheduleEvent]: {
        func: (status) => `${getEventName(status, true)} canceled`,
        example: [ProcessStatus.InPersonInterview],
    },
    [ProcessAction.CancelEvent]: {
        func: (status) => `${getEventName(status, true)} canceled`,
        example: [ProcessStatus.InPersonInterview],
    },
    [CustomHistoryAction.Revive]: 'Restart process',
    [ProcessAction.MoveToPhoneInterview]: 'Phone interview requested',
    [ProcessAction.MoveToInPersonInterview]: 'In-person interview requested',
    [ProcessAction.MoveToOrientation]: 'Orientation requested',
    [ProcessAction.MoveToPaperwork]: 'Start application docs & screening',
    [ProcessAction.TerminateProcess]: 'Process terminated',
    [ProcessAction.MarkSuccess]: 'Hired',
    [ProcessAction.UpdateFacility]: 'Facility updated',

    [ProcessHistoryAdditionalAction.MarkEventUnoccurred]: {
        func: (status) => `Additional ${getEventName(status).toLowerCase()} requested`,
        example: [ProcessStatus.InPersonInterview],
    },
};

type ActionItemLeaf =
    | string
    | {
          func: (process: Process) => string;
          example: [Process];
      }
    | {
          func: (process: Process) => string;
          examples: [Process][];
      };

const actionItemToStrings: Record<
    CurrentProcessActionItemType,
    { title: ActionItemLeaf; subtitle: ActionItemLeaf }
> = {
    [CurrentProcessActionItemType.EventScheduled]: {
        title: {
            func: (process) =>
                process.relevantEvent
                    ? `Pending ${eventTypeToName[process.relevantEvent.eventType]?.toLocaleLowerCase()}`
                    : '',
            example: [exampleProcess],
        },
        subtitle: {
            func: (process) =>
                process.relevantEvent
                    ? `on ${format(new Date(process.relevantEvent.startAt), 'MMM dd, HH:mm a')}`
                    : '',
            example: [exampleProcess],
        },
    },
    [CurrentProcessActionItemType.RecruiterFeedbackRequired]: {
        title: 'Awaiting recruiter',
        subtitle: "You'll be notified when a decision is made to move forward",
    },
    [CurrentProcessActionItemType.PendingPaperwork]: {
        title: 'Pending application docs and background checks',
        subtitle: "We're tracking your progress. \nNeed help? Reach out to our team.",
    },
    [CurrentProcessActionItemType.EventRequested]: {
        title: {
            func: (process) => {
                if (process.status === ProcessStatus.Orientation) {
                    return 'Pending orientation date';
                }
                return 'Awaiting your response';
            },
            examples: [
                [exampleProcess],
                [{ ...exampleProcess, status: ProcessStatus.Orientation }],
            ],
        },
        subtitle: {
            func: (process) => {
                if (process.status === ProcessStatus.Orientation) {
                    return "We'll reach out to schedule your orientation";
                }
                return `Click "${processesStrings.actions.acceptInterview}" to select the time and date.`;
            },
            examples: [
                [exampleProcess],
                [{ ...exampleProcess, status: ProcessStatus.Orientation }],
            ],
        },
    },
};

const strings = {
    processHistoryActionToString,
    actionItemToStrings,
    title: 'Your process',
};

export default strings;

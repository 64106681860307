import useClearQueryParams from '@/hooks/useClearQueryParams';
import { QueryParam } from '@/lib/types';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

function useQueryParamsAndClear<T extends QueryParam>(params: T[]) {
    const location = useLocation();
    const clearQueryParams = useClearQueryParams(params);
    const { search } = location;

    const useOnce = useCallback(() => {
        const queryParamsFromUrl = queryString.parse(search);
        const res: { [key in T]?: string } = {};
        for (const param of params) {
            if (typeof queryParamsFromUrl[param] === 'string') {
                res[param] = queryParamsFromUrl[param] as string;
            }
        }
        clearQueryParams();
        return res;
    }, [search, clearQueryParams, params]);

    return useOnce;
}

export default useQueryParamsAndClear;

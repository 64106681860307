import Logger from '@/_helpers/logger';
import { Button, DialogContentWithFooter, LoadingButton } from '@/components/ui';
import { EventType } from '@/lib/api/v1';
import { DateIsoString } from '@/lib/base';
import { ScheduleInterviewDialogVariant } from '@/lib/types';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';
import { format } from 'date-fns';
import { useState } from 'react';

import { ProcessEventSlot } from './types';

const strings = dialogsStrings.scheduleInterview.timeSlotSelection;

interface Props {
    eventType: EventType;
    slots: ProcessEventSlot[];
    submit: (timeSlot: DateIsoString) => Promise<void>;
    variant: ScheduleInterviewDialogVariant;
    trackSelectTimeSlot: (timeSlot: DateIsoString) => void;
}

export function SelectTimeSlotContent({
    slots,
    eventType,
    submit,
    trackSelectTimeSlot,
    variant,
}: Props) {
    const [timeSlot, setTimeSlot] = useState<DateIsoString>();

    const variantStrings =
        variant === ScheduleInterviewDialogVariant.Reschedule
            ? strings.reschedule
            : strings;

    const handleSubmit = async () => {
        if (!timeSlot) {
            Logger.error('Tried to scheduleEvent without selected timeSlot');
            return;
        }
        return await submit(timeSlot);
    };

    return (
        <DialogContentWithFooter
            footer={
                <div className={cn('flex justify-center')}>
                    <LoadingButton
                        className={cn('w-full max-w-[400px]')}
                        disabled={!timeSlot}
                        onClick={handleSubmit}
                    >
                        {variantStrings.submitButton.func(eventType)}
                    </LoadingButton>
                </div>
            }
            bodyProps={{ className: cn('py-0') }}
        >
            <div className={cn('flex flex-col items-center w-full py-8 gap-4')}>
                {slots.map(({ start }) => (
                    <Button
                        key={start}
                        variant={timeSlot === start ? 'default' : 'secondary'}
                        className={cn('w-full max-w-[350px] h-16')}
                        size={'md'}
                        onClick={() => {
                            trackSelectTimeSlot(start);
                            setTimeSlot(start);
                        }}
                    >
                        {format(new Date(start), 'hh:mm a')}
                    </Button>
                ))}
            </div>
        </DialogContentWithFooter>
    );
}

export default SelectTimeSlotContent;

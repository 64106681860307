import { Skeleton } from '@/components/ui/skeleton';
import { DefaultDivProps } from '@/lib/base';

import EventLayout from './EventLayout';

function EventSkeleton({ rootProps }: { rootProps?: DefaultDivProps }) {
    return (
        <EventLayout
            date={<Skeleton className={'w-full h-full'} />}
            header={<Skeleton className={'w-full h-6'} />}
            description={<Skeleton className={'w-full h-4 mt-6'} />}
            menu={null}
            rootProps={rootProps}
        />
    );
}

export default EventSkeleton;

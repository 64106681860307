import { FacilityIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import ActiveProcessesView from '@/processes/ActiveProcessesView';
import { statusPage as strings } from '@/strings';

import EmptyView from './EmptyView';

function ProcessesView({ processes }: { processes: Process[] }) {
    const hasData = processes.length > 0;

    return (
        <div className={cn('relative h-full w-full overflow-auto')}>
            {!hasData && (
                <EmptyView
                    icon={
                        <FacilityIcon
                            className={cn('h-24 w-24 fill-neutral100 stroke-neutral100')}
                        />
                    }
                    text={strings.processes.emptyLabel}
                />
            )}
            {hasData && (
                <div className={cn('py-8')}>
                    <ActiveProcessesView processes={processes} />
                </div>
            )}
        </div>
    );
}

export default ProcessesView;

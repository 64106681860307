import { ProcessHistoryWithParsedAction } from '@/lib/types/processHistory';
import { format } from 'date-fns';

import HistoryItemContent from './HistoryItemContent';
import HistoryRow from './HistoryRow';

interface Props {
    historyItem: ProcessHistoryWithParsedAction;
    isTopItem?: boolean;
    isBottomItem?: boolean;
}

function ProcessHistoryItemRow({
    historyItem,
    isTopItem = false,
    isBottomItem = false,
}: Props) {
    return (
        <>
            {!isTopItem && <HistoryRow />}
            <HistoryRow
                content={
                    <HistoryItemContent
                        title={historyItem.actionName}
                        subtitle={format(
                            new Date(historyItem.createdAt),
                            'MMM dd, HH:mm a',
                        )}
                    />
                }
                isTopItem={isTopItem}
                isBottomItem={isBottomItem}
            />
        </>
    );
}

export default ProcessHistoryItemRow;

import { IsLoggedIn, IsReregisteringCandidate } from '@/_selectors';
import Loader from '@/components/lotties/Loader';
import useCandidateAppState from '@/fetchers/useCandidateAppState';
import useGoTo from '@/hooks/useGoTo';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import RegisterFormContextProvider from './RegisterContext';
import RegisterPageContent from './RegisterPageContent';
import RegistrationHeader from './RegistrationHeader';
import { ShouldRedirectToHomepage } from './types';

let shouldRedirect: boolean | null = null;
function useShouldRedirectToHomepage(): ShouldRedirectToHomepage {
    const {
        isReady,
        data: { finishedRegistration },
    } = useCandidateAppState();
    const isLoggedIn = useSelector(IsLoggedIn);

    if (shouldRedirect !== null) {
        return shouldRedirect
            ? ShouldRedirectToHomepage.Yes
            : ShouldRedirectToHomepage.No;
    }
    if (!isLoggedIn) {
        shouldRedirect = false;
        return ShouldRedirectToHomepage.No;
    }
    if (!isReady) {
        return ShouldRedirectToHomepage.WaitingForData;
    }
    if (finishedRegistration) {
        shouldRedirect = true;
        return ShouldRedirectToHomepage.Yes;
    }
    shouldRedirect = false;
    return ShouldRedirectToHomepage.No;
}

function RegisterPage() {
    const shouldRedirect = useShouldRedirectToHomepage();
    const isLoggedIn = useSelector(IsLoggedIn);
    const isLoadingReregister = useSelector(IsReregisteringCandidate);
    const { goToHome } = useGoTo();
    const showLoaderForReregistration = isLoadingReregister && isLoggedIn;
    const showLoader =
        shouldRedirect === ShouldRedirectToHomepage.WaitingForData ||
        showLoaderForReregistration;

    useEffect(() => {
        if (shouldRedirect === ShouldRedirectToHomepage.Yes) {
            goToHome();
        }
    }, [shouldRedirect, goToHome]);

    return (
        <RegisterFormContextProvider>
            <div className={cn('flex flex-col w-full h-full bg-white')}>
                {showLoader && (
                    <div
                        className={cn(
                            'flex justify-center items-center py-6 h-full w-full',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' delay={700} />
                    </div>
                )}
                {!showLoader && shouldRedirect === ShouldRedirectToHomepage.No && (
                    <>
                        <RegistrationHeader />
                        <RegisterPageContent />
                    </>
                )}
            </div>
        </RegisterFormContextProvider>
    );
}

export default RegisterPage;

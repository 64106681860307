import { cn } from '@/lib/utils';

interface Props {
    title: string;
    subtitle?: string;
}

function StepTitle({ title, subtitle }: Props) {
    return (
        <div>
            <div className={cn('text-26 font-bold')}>{title}</div>
            {subtitle && (
                <div className={cn('text-15 font-medium mt-3')}>{subtitle}</div>
            )}
        </div>
    );
}

export default StepTitle;

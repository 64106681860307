import { RootState } from '@/_helpers/store';

export const AuthState = (state: RootState) => state.auth;
export const DialogsState = (state: RootState) => state.dialogs;
export const ProcessesState = (state: RootState) => state.processes;
export const OrganizationsState = (state: RootState) => state.organizations;
export const AppStateBase = (state: RootState) => state.appState;
export const CandidateSliceState = (state: RootState) => state.candidate;
export const ScreeningState = (state: RootState) => state.screening;
export const ProcessAvailabilityWindowsState = (state: RootState) =>
    state.availabilityWindows;
export const ProcessHistoryStateSelector = (state: RootState) => state.processHistory;
export const IsDevModeOn = (state: RootState) => state.devMode;
export const SessionSettingsState = (state: RootState) => state.sessionSettings;

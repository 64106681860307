import { RefObject, useEffect, useRef } from 'react';

function useReachedScrollEnd({
    callback,
    containerRef: externalContainerRef,
    distanceFromBottom = 0,
}: {
    callback?: () => void;
    containerRef?: RefObject<HTMLDivElement | null>;
    distanceFromBottom?: number;
}) {
    const innerContainerRef = useRef<HTMLDivElement | null>(null);
    const hasReachedBottom = useRef<boolean>(false);
    const containerRef = externalContainerRef ?? innerContainerRef;

    useEffect(() => {
        if (!callback) {
            return;
        }
        const handleScroll = () => {
            if (containerRef.current && !hasReachedBottom.current) {
                const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
                if (scrollTop + clientHeight + distanceFromBottom >= scrollHeight) {
                    hasReachedBottom.current = true;
                    callback();
                }
            }
        };

        const element = containerRef.current;
        element?.addEventListener('scroll', handleScroll);

        return () => {
            element?.removeEventListener('scroll', handleScroll);
        };
    }, [callback, distanceFromBottom, containerRef]);

    return containerRef;
}

export default useReachedScrollEnd;

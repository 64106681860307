import { StatusPageTab } from '@/lib/types/queryParams';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SessionSettings } from './types';

const initialState: SessionSettings = {
    statusPageTab: StatusPageTab.UpcomingEvents,
    reregisteredCandidate: null,
    isReregistering: false,
};

const sessionSettingsSlice = createSlice({
    name: 'sessionSettings',
    initialState,
    reducers: {
        updateSettings: (state, action: PayloadAction<Partial<SessionSettings>>) => ({
            ...state,
            ...action.payload,
        }),
        reset: () => initialState,
    },
});

export default sessionSettingsSlice;

import { firebaseAuth } from '@/firebase';
import { alertFirebaseSignInError } from '@/lib/utils';
import { ConfirmationResult, signInWithPhoneNumber } from 'firebase/auth';
import { useCallback } from 'react';

import recaptchaVerifier from './recaptcha/verifier';

export const usePhoneSignIn = () => {
    const signinWithPhone = useCallback(
        async (phoneNumber: string, callback: (res: ConfirmationResult) => void) => {
            try {
                await signInWithPhoneNumber(
                    firebaseAuth,
                    phoneNumber,
                    recaptchaVerifier,
                ).then((confirmationResult: ConfirmationResult) => {
                    callback(confirmationResult);
                });
            } catch (error) {
                alertFirebaseSignInError(error);
                throw error;
            }
        },
        [],
    );

    return { signinWithPhone };
};
export default usePhoneSignIn;

import { createSelector } from 'reselect';

import { SessionSettingsState } from './base';

export const ReregisteredCandidateData = createSelector(
    SessionSettingsState,
    (s) => s.reregisteredCandidate,
);

export const IsReregisteringCandidate = createSelector(
    SessionSettingsState,
    (s) => s.isReregistering,
);

import { AppDispatch } from '@/_helpers/store';
import { createSlice } from '@reduxjs/toolkit';

export const enterDevMode = () => (dispatch: AppDispatch) => {
    dispatch(devModeSlice.actions.enter());
};

export const exitDevMode = () => (dispatch: AppDispatch) => {
    dispatch(devModeSlice.actions.exit());
};

const devModeSlice = createSlice({
    name: 'devMode',
    initialState: false,
    reducers: {
        enter: () => true,
        exit: () => false,
    },
});

export default devModeSlice;

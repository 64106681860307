import { DialogContentWithFooter, LoadingButton, Select } from '@/components/ui';
import { Textarea } from '@/components/ui/textarea';
import { CandidateTerminationReason, candidateTerminationReason } from '@/lib/api/v1';
import { sortEnumList } from '@/lib/base';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import { SelectedTerminationReason } from './types';

const strings = dialogsStrings.termination;

const terminationReasonsByOrder = [
    CandidateTerminationReason.Commute,
    CandidateTerminationReason.PayRate,
    CandidateTerminationReason.NoLongerLooking,
    CandidateTerminationReason.NotInterestedInNursingHome,
    CandidateTerminationReason.Other,
];

interface Props {
    submit: () => void;
    reason: SelectedTerminationReason;
    setReason: (reason: SelectedTerminationReason) => void;
    freeText: string;
    setFreeText: (reason: string) => void;
    submitText: string;
    reasonPlacehloder: string;
}

export function TerminateProcessDialogContent({
    reason,
    setReason,
    freeText,
    setFreeText,
    submit,
    submitText,
    reasonPlacehloder,
}: Props) {
    const isFreeTextRequired = reason === CandidateTerminationReason.Other;
    const disabled = !reason || (isFreeTextRequired && !freeText);
    return (
        <DialogContentWithFooter
            footer={
                <div className={cn('flex justify-center')}>
                    <LoadingButton
                        className={cn('w-full max-w-[400px]')}
                        variant={'danger'}
                        disabled={disabled}
                        onClick={submit}
                    >
                        {submitText}
                    </LoadingButton>
                </div>
            }
            bodyProps={{ className: cn('p-0') }}
        >
            <div className={cn('relative w-full')}>
                <div className={cn('p-8 pt-0')}>
                    <Select
                        triggerProps={{
                            className: cn('w-full'),
                        }}
                        value={reason}
                        onChange={(v) => {
                            setReason(v);
                        }}
                        placeholder={reasonPlacehloder}
                        options={sortEnumList(
                            candidateTerminationReason,
                            terminationReasonsByOrder,
                        ).map((r) => ({
                            value: r,
                            label: strings.terminationReasons[r],
                            id: r,
                        }))}
                    />
                    <Textarea
                        className={cn('mt-6 h-[150px] resize-none')}
                        value={freeText}
                        onChange={(e) => setFreeText(e.target.value)}
                        placeholder={
                            isFreeTextRequired
                                ? strings.freeTextPlaceholder.required
                                : strings.freeTextPlaceholder.optional
                        }
                        tabIndex={-1}
                    />
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default TerminateProcessDialogContent;

import { Button } from '@/components/ui';
import useClearQueryParamOnUnmount from '@/hooks/useClearQueryParamOnUnmount';
import { getAllEnumValues } from '@/lib/base';
import { OnboardingQuestion } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { registration as strings } from '@/strings';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';
import LastWorkPlaceFormLayout from './resume/LastWorkPlaceFormLayout';
import UploadResumeForm from './resume/ResumeUploadForm';

enum Section {
    Init = 'init',
    CV = 'resume',
    RecentJob = 'lastWorkPlace',
}

const SECTION_QUERY_PARAM = 'resumeSection';

function ResumeStep() {
    const [searchParams, setSearchParams] = useSearchParams();
    const sectionFromQuery = searchParams.get(SECTION_QUERY_PARAM);
    useClearQueryParamOnUnmount(SECTION_QUERY_PARAM);
    const step =
        sectionFromQuery &&
        getAllEnumValues(Section).includes(sectionFromQuery as Section)
            ? (sectionFromQuery as Section)
            : Section.Init;
    const updateStep = useCallback(
        (value: Section) => {
            searchParams.set(SECTION_QUERY_PARAM, value);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams],
    );

    const updateHasResumeAnswer = (hasResume: boolean) => {
        trackEvent(MixpanelEvent.RegistrationUpdateAnswer, {
            question: OnboardingQuestion.HasResumeReady,
            answer: hasResume,
        });
        updateStep(hasResume ? Section.CV : Section.RecentJob);
    };

    const goBack = useCallback(() => updateStep(Section.Init), [updateStep]);

    return (
        <>
            {step === Section.CV && <UploadResumeForm goBack={goBack} />}
            {step === Section.RecentJob && <LastWorkPlaceFormLayout goBack={goBack} />}
            {step === Section.Init && (
                <RegistrationStepLayout canGoNext={false} hideFooter>
                    <div
                        className={cn('w-full max-w-[400px] flex flex-col items-center')}
                    >
                        <div className={cn('flex flex-col items-center gap-8')}>
                            <StepTitle
                                title={strings.resume.haveResume.title}
                                subtitle={strings.resume.haveResume.subtitle}
                            />
                            <div className={cn('w-full max-w-[350px]')}>
                                <Button
                                    variant={'secondary'}
                                    className={cn('w-full')}
                                    size={'lg'}
                                    onClick={() => updateHasResumeAnswer(true)}
                                >
                                    {strings.answerYes}
                                </Button>
                                <Button
                                    variant={'secondary'}
                                    className={cn('w-full mt-6')}
                                    size={'lg'}
                                    onClick={() => updateHasResumeAnswer(false)}
                                >
                                    {strings.answerNo}
                                </Button>
                            </div>
                        </div>
                    </div>
                </RegistrationStepLayout>
            )}
        </>
    );
}

export default ResumeStep;

import { CheckboxButton } from '@/components/ui/button';
import { candidateCareSetting } from '@/lib/api/v1';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function CareSettingsStep() {
    const { careSettings, toggleCareSetting } = useRegisterFormContext();
    const canGoNext = careSettings.length > 0;
    return (
        <RegistrationStepLayout canGoNext={canGoNext}>
            <div className={cn('flex flex-col items-center gap-8')}>
                <StepTitle
                    title={strings.careSettingsTitle}
                    subtitle={strings.selectManyDescription}
                />
                <div className={cn('flex flex-wrap gap-6 mt-2')}>
                    {candidateCareSetting.map((cs) => (
                        <CheckboxButton
                            key={cs}
                            className={cn('w-full')}
                            checked={careSettings.includes(cs)}
                            onClick={() => toggleCareSetting(cs)}
                            label={strings.careSettingsLabels[cs]}
                        />
                    ))}
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default CareSettingsStep;

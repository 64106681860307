import Logger from '@/_helpers/logger';
import { NumberString, toNumberOrNone } from '@/lib/base';
import { Candidate, CandidateUpdatePayload } from '@/lib/types';
import { pick } from 'lodash-es';
import { FieldErrors } from 'react-hook-form';

import { ProfileFormValues, SalaryObject } from './types';

const ADDITIONAL_ATTRIBUTES_FIELD_NAMES = ['hasDrivingLicense', 'hasCar'] as const;

export function hasSalaryChange(salary: SalaryObject, originalSalary: SalaryObject) {
    if (salary.flexible !== originalSalary.flexible) {
        return true;
    }
    if (
        !salary.flexible &&
        toNumberOrNone(salary.value) !== toNumberOrNone(originalSalary.value)
    ) {
        return true;
    }
    return false;
}

export function getDefaultValues(candidate: Candidate): ProfileFormValues {
    const { hourlySalaryFlexible: flexibleSalary } = candidate;
    const hourlySalary = flexibleSalary ? undefined : candidate.hourlySalary;
    return {
        name: candidate.name ?? '',
        email: candidate.email ?? '',
        yearsOfExperience: String(candidate.yearsOfExperience ?? '') as NumberString,
        hourlySalary: {
            value: String(hourlySalary ?? '') as NumberString,
            flexible: flexibleSalary,
        },
        shifts: candidate.shifts ?? [],
        jobTypes: candidate.jobTypes ?? [],
        careSettings: candidate.careSettings ?? [],
        hasCar: !!candidate.hasCar,
        hasDrivingLicense: !!candidate.hasDrivingLicense,
    };
}

export function formatFormPayload(
    payload: ProfileFormValues,
    {
        includeAdditionalAttrs,
        updatedFields,
    }: { includeAdditionalAttrs: boolean; updatedFields: (keyof ProfileFormValues)[] },
): CandidateUpdatePayload {
    const res: CandidateUpdatePayload = Object.assign(
        pick(payload, updatedFields),
        updatedFields.includes('yearsOfExperience')
            ? {
                  yearsOfExperience: Number(payload.yearsOfExperience),
              }
            : {},
        updatedFields.includes('hourlySalary')
            ? {
                  hourlySalaryFlexible:
                      payload.hourlySalary.flexible ||
                      toNumberOrNone(payload.hourlySalary.value) === undefined,
                  hourlySalary: payload.hourlySalary.flexible
                      ? null
                      : toNumberOrNone(payload.hourlySalary.value) ?? null,
              }
            : {},
    );
    if (!includeAdditionalAttrs) {
        for (const field of ADDITIONAL_ATTRIBUTES_FIELD_NAMES) {
            delete res[field];
        }
    }
    return res;
}

export function reportErrorsOnSubmit(errors: FieldErrors<ProfileFormValues>) {
    Logger.error(`Got errors on profile form submit: ${JSON.stringify(errors)}`);
}

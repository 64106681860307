export enum AppPageId {
    Home = 'Home',
    Status = 'Status',
    Profile = 'Profile',
    Register = 'Register',
    ScheduleIntroCall = 'Schedule Intro Call',
    OnboardingScheduleIntroCall = 'Schedule Intro Call Post Registration',
}

export const anonymousPageIds = [AppPageId.Register] as const;
export const noFooterRoutes = [
    AppPageId.ScheduleIntroCall,
    AppPageId.OnboardingScheduleIntroCall,
] as const;
export type NoFooterRoute = (typeof noFooterRoutes)[number];

export type AnonumousPageId = (typeof anonymousPageIds)[number];

import { DateIsoString, DayString, isoDatetoLocalTimeString } from '@/lib/base';
import { EventRelatedProcess } from '@/lib/types';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';

export function getMixpanelEventTrackers(process: EventRelatedProcess) {
    const processData = extractProcessDataForMixpanel(process);
    return {
        trackGoToNextMonth: (currentMonth: string) => {
            trackEvent(MixpanelEvent.ScheduleInterviewGoToNextMonth, {
                ...processData,
                current_month: currentMonth,
            });
        },
        trackGoToPrevMonth: (currentMonth: string) => {
            trackEvent(MixpanelEvent.ScheduleInterviewGoToPrevMonth, {
                ...processData,
                current_month: currentMonth,
            });
        },
        trackClickTalkToUs: () => {
            trackEvent(MixpanelEvent.ScheduleInterviewClickTalkToUs, {
                ...processData,
            });
        },
        trackSelectDate: (day: DayString) => {
            trackEvent(MixpanelEvent.ScheduleInterviewSelectDate, {
                ...processData,
                selected_date: day,
            });
        },
        trackSelectTimeSlot: (timeSlot: DateIsoString) => {
            trackEvent(MixpanelEvent.ScheduleInterviewSelectTime, {
                ...processData,
                selected_time: isoDatetoLocalTimeString(timeSlot),
                selected_timestamp: timeSlot,
            });
        },
        trackSubmitTimeSlot: (timeSlot: DateIsoString) => {
            trackEvent(MixpanelEvent.ScheduleInterviewSubmitTime, {
                ...processData,
                selected_time: isoDatetoLocalTimeString(timeSlot),
                selected_timestamp: timeSlot,
            });
        },
        trackGoBack: () => {
            trackEvent(MixpanelEvent.ScheduleInterviewGoBack);
        },
    };
}

import { AvatarCNA, AvatarHHA, AvatarLPN, AvatarNP, AvatarRN } from '@/components/svgs';
import { Profession } from '@/lib/types';

type Props = React.SVGProps<SVGSVGElement> & {
    profession: Profession;
};

const ProfessionAvatar = ({ profession, ...props }: Props) => {
    return (
        <>
            {profession === Profession.NP && <AvatarNP {...props} />}
            {profession === Profession.LPN && <AvatarLPN {...props} />}
            {profession === Profession.RN && <AvatarRN {...props} />}
            {profession === Profession.HHA && <AvatarHHA {...props} />}
            {profession === Profession.CNA && <AvatarCNA {...props} />}
        </>
    );
};
export default ProfessionAvatar;

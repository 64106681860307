import { ArrowBack } from '@/components/svgs';
import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';

export function GoBackArrow({
    onClick,
    ...props
}: DefaultDivProps & {
    onClick: () => void;
}) {
    return (
        <div
            {...props}
            className={cn(
                'h-12 w-12 rounded-full bg-accent200',
                'flex items-center justify-center cursor-pointer',
                props.className,
            )}
            onClick={onClick}
        >
            <ArrowBack />
        </div>
    );
}

export default GoBackArrow;

import { AuthUser, Env } from '@/lib/types';
import { cn } from '@/lib/utils';
import { useState } from 'react';

import ImpersonationIndicationContent from './ImpersonationIndicationContent';

interface Props {
    exitImpersonation: () => void;
    impersonatedUser: AuthUser;
    env: Env;
}

function ImpersonationIndication({ exitImpersonation, impersonatedUser, env }: Props) {
    const [pinned, setPinned] = useState(true);
    const isProd = env === Env.Prod;
    const collapsed = !pinned && !isProd;
    const overlapping = !pinned && isProd;
    return (
        <div className={cn('relative w-full')}>
            <div
                className={cn('relative w-full bg-sky-300 px-2 py-1', {
                    'bg-red-600': isProd,
                    'fixed z-[100] left-0 top-0 w-fit px-4': collapsed,
                    absolute: overlapping,
                })}
            >
                <ImpersonationIndicationContent
                    pinned={pinned}
                    setPinned={setPinned}
                    exitImpersonation={exitImpersonation}
                    impersonatedUser={impersonatedUser}
                    env={env}
                />
            </div>
        </div>
    );
}

export default ImpersonationIndication;

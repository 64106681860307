import Logger from '@/_helpers/logger';
import config from '@/config';
import { firebaseAuth } from '@/firebase';
import { RecaptchaVerifier } from 'firebase/auth';

import RecaptchaStateManager from './RecaptchaManager';

const verifier = new RecaptchaVerifier(firebaseAuth, config.RECAPTCHA_CONTAINER_ID, {
    size: 'invisible',
    callback: () => {
        RecaptchaStateManager.verified();
    },
    'expired-callback': (data: unknown) => {
        Logger.warn(`expired reCAPTCHA: ${data}`);
        RecaptchaStateManager.failed();
    },
    'error-callback': (error: Error) => {
        Logger.warn(`reCAPTCHA error: ${error}`);
        RecaptchaStateManager.failed();
    },
});

export default verifier;

import { Dialog, DialogContentWithFooter, PageLoader } from '@/components/ui';
import EmptyDialogHeader from '@/components/ui/dialog/emptyDialogHeader';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useCandidateId from '@/hooks/useCandidateId';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { CalendlyWidgetProps } from './types';

function CalendlyWidget({ open, setOpen, link, onEventScheduled }: CalendlyWidgetProps) {
    useCalendlyEventListener({
        onDateAndTimeSelected: () => {
            trackEvent(MixpanelEvent.CalendlyWidgetSelectSlot);
        },
        onEventScheduled: (e) => {
            trackEvent(MixpanelEvent.CalendlyWidgetScheduleMeeting);
            onEventScheduled && onEventScheduled(e);
        },
        onEventTypeViewed: () => {
            trackEvent(MixpanelEvent.CalendlyWidgetViewCalendar);
        },
    });

    const candidateId = useCandidateId();
    const { data: candidate } = useCurrentCandidate();
    const { phone, name, email } = candidate;

    return (
        <Dialog
            alwaysExpanded
            open={open}
            setOpen={setOpen}
            customHeader={<EmptyDialogHeader handleClose={() => setOpen(false)} />}
        >
            <DialogContentWithFooter
                bodyProps={{
                    className: cn('p-0 max-h-full h-[100vh] overflow-auto'),
                }}
            >
                <InlineWidget
                    url={link}
                    styles={{ height: '101%' }}
                    prefill={{
                        name,
                        email,
                        location: phone,
                    }}
                    utm={{ utmSource: candidateId }}
                    LoadingSpinner={() => (
                        <PageLoader
                            delay={0}
                            containerProps={{ className: cn('absolute') }}
                            onFinish={() => {
                                trackEvent(MixpanelEvent.CalendlyWidgetIframeLoaded);
                            }}
                        />
                    )}
                />
            </DialogContentWithFooter>
        </Dialog>
    );
}

export default CalendlyWidget;

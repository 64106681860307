import { serializeFetcherState } from '@/lib/utils';
import { mapValues } from 'lodash-es';
import { createSelector } from 'reselect';

import { ProcessAvailabilityWindowsState, ProcessesState } from './base';

export const ProcessModels = createSelector(ProcessesState, (s) => s.data);

export const ProcessesFetcherData = createSelector(ProcessesState, (processesState) =>
    serializeFetcherState(processesState.fetchers.all),
);

export const AvailabilityWindowsDataByProcessId = createSelector(
    ProcessAvailabilityWindowsState,
    (s) => s.data,
);

export const AvailabilityWindowsFetcherDataByProcessId = createSelector(
    ProcessAvailabilityWindowsState,
    (s) => mapValues(s.fetchers, serializeFetcherState),
);

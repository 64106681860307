import { QueryParam } from '@/lib/types';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useClearQueryParams = (params: QueryParam[]) => {
    const navigate = useNavigate();

    const clearQueryParams = useCallback(() => {
        const { search, hash, pathname } = window.location;
        const queryParams = queryString.parse(search);
        for (const param of params) {
            delete queryParams[param];
        }
        navigate(
            {
                pathname,
                search: queryString.stringify(queryParams),
                hash,
            },
            { replace: true },
        );
    }, [navigate, params]);

    return clearQueryParams;
};

export default useClearQueryParams;

import { CodeInput, LoadingButton } from '@/components/ui';
import { FIREBASE_INVALID_CODE_ERROR_CODE, INVALID_PHONE_NUMBER } from '@/firebase';
import useLoadingAction from '@/hooks/useLoadingAction';
import { cn, formatInternationalPhoneNumber } from '@/lib/utils';
import { extractErrorMsg, extractErrorType } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import ResendCodeButton from '@/phoneSignIn/ResendCodeButton';
import { VERIFICATION_CODE_LENGTH } from '@/phoneSignIn/consts';
import strings from '@/strings/login';
import { useState } from 'react';

import { CodeVerificationSectionProps } from './types';

function CodeVerificationSection({
    submit,
    phone,
    resendCode,
    prefix,
}: CodeVerificationSectionProps) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        trackEvent(MixpanelEvent.LoginSubmitVerificationCode);
        setError('');
        await submit(code).catch((error) => {
            let msg = strings.codeVerification.error;
            if (extractErrorType(error) === FIREBASE_INVALID_CODE_ERROR_CODE) {
                msg = strings.codeVerification.invalidCodeError;
            }
            setError(msg);
        });
    };

    const { action: onSubmit, isLoading } = useLoadingAction(handleSubmit);

    const onResendCode = async (callback: () => void) => {
        trackEvent(MixpanelEvent.LoginClickResendCode);
        setError('');
        return await resendCode()
            .then(() => {
                setCode('');
                callback();
            })
            .catch((error) => {
                const type = extractErrorType(error);
                let msg = extractErrorMsg(error);
                if (type === INVALID_PHONE_NUMBER) {
                    msg = strings.errors.invalidPhone;
                }
                setError(msg);
            });
    };

    return (
        <div className={cn('w-full h-full flex justify-center')}>
            <div className={cn('flex flex-col max-w-[360px] w-full h-full')}>
                <div className={cn('')}>
                    <p className={cn('font-bold text-26')}>
                        {strings.codeVerification.title}
                    </p>
                </div>
                <div className={cn('mt-1')}>
                    <p className={cn('font-medium text-15')}>
                        {strings.codeVerification.subtitle.func(
                            formatInternationalPhoneNumber({
                                phone,
                                prefix,
                            }),
                        )}
                    </p>
                </div>
                <div className={cn('mt-6')}>
                    <div className={cn('w-full')}>
                        <CodeInput
                            value={code}
                            setValue={setCode}
                            error={error}
                            clearError={() => setError('')}
                            onSubmit={onSubmit}
                            errorElemProps={{ className: cn('mt-2') }}
                        />
                        <LoadingButton
                            onClick={onSubmit}
                            disabled={code.length < VERIFICATION_CODE_LENGTH}
                            className={cn('w-full mt-10')}
                            size={'lg'}
                            loading={isLoading}
                        >
                            {strings.submitButton}
                        </LoadingButton>
                    </div>
                </div>
                <div className={cn('flex-grow flex flex-col justify-end pt-14')}>
                    <ResendCodeButton resendCode={onResendCode} className={cn('h-7')} />
                </div>
            </div>
        </div>
    );
}

export default CodeVerificationSection;

import { ClockIcon, LocationIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';

interface Props {
    startAt: Date;
    endAt: Date;
    address?: string;
}

function EventDetailsView({ startAt, endAt, address }: Props) {
    const showAdress = !!address;
    return (
        <>
            <div className={cn('w-full mt-3 flex items-center gap-2')}>
                {showAdress && <ClockIcon className={cn('shrink-0 h-4 w-4')} />}
                <div
                    className={cn('text-12 font-medium', {
                        'text-11-14': showAdress,
                    })}
                >
                    {format(startAt, 'eeee, hh:mm')}
                    {' - '}
                    {format(endAt, 'hh:mm a')}
                </div>
            </div>
            {showAdress && (
                <div className={cn('w-full mt-3.5 flex items-center gap-2')}>
                    <LocationIcon className={cn('shrink-0 h-4 w-4')} />
                    <div className={cn('text-11-14 font-medium')}>{address}</div>
                </div>
            )}
        </>
    );
}

export default EventDetailsView;

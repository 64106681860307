import { profile as strings } from '@/strings';

import { SalaryObject } from './types';

export function validateSalary(
    current: SalaryObject,
    original: SalaryObject,
): string | undefined {
    if (original.value === current.value && original.flexible === current.flexible) {
        return undefined;
    }
    if (current.flexible) {
        return undefined;
    }
    const value = current.value;
    if (value === '') {
        return strings.errors.requiredField;
    }
    if (isNaN(Number(value))) {
        return strings.errors.invalidNumber;
    }
    if (Number(value) > 999) {
        return strings.errors.SalaryOutOfRange;
    }
    if (Number(value) <= 0) {
        return strings.errors.SalaryZero;
    }

    return undefined;
}

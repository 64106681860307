import { cn } from '@/lib/utils';

export type LinkProps = React.HTMLAttributes<HTMLSpanElement> & {
    onClick: () => void;
};

const Link = ({ children, onClick, ...props }: LinkProps) => {
    return (
        <span
            {...props}
            className={cn('underline cursor-pointer', props.className)}
            onClick={onClick}
        >
            {children}
        </span>
    );
};

export { Link };

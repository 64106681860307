import Logger from '@/_helpers/logger';
import { AppDispatch, GetRootState } from '@/_helpers/store';
import { CurrentUserId } from '@/_selectors';
import apiClient from '@/lib/api';
import { CandidateUpdatePayload } from '@/lib/types';

import candidateSlice, { fetchCandidateThunk } from './candidateSlice';

export const updateCandidate =
    (params: CandidateUpdatePayload) =>
    async (dispatch: AppDispatch, getState: GetRootState) => {
        const candidateId = CurrentUserId(getState());
        if (!candidateId) {
            Logger.error('Tried to update candidate app state without a candidate ID');
            return;
        }
        const response = await apiClient.PATCH('/api/v1/candidates/me', {
            body: { ...params },
        });
        dispatch(candidateSlice.actions.update(response.data!));
    };

export const fetchCurrentCandidate =
    () => (dispatch: AppDispatch, getState: GetRootState) => {
        const candidateId = CurrentUserId(getState());
        if (!candidateId) {
            Logger.error('Tried to fetch candidate without current user ID');
            return;
        }
        dispatch(fetchCandidateThunk({ candidateId }));
    };

export const resetCandidate = () => (dispatch: AppDispatch) => {
    dispatch(candidateSlice.actions.reset());
};

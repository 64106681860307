import { Button, DialogContentWithFooter, LoadingButton } from '@/components/ui';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.termination;

interface Props {
    confirm: () => Promise<void>;
    cancel: () => void;
    process: Process;
}

export function TerminateDialogConfirmationContent({ process, confirm, cancel }: Props) {
    return (
        <DialogContentWithFooter
            footer={
                <div
                    className={cn(
                        'relative flex max-w-full flex-col items-center gap-4 pb-2',
                    )}
                >
                    <LoadingButton
                        className={cn('w-[400px] max-w-full')}
                        variant={'danger'}
                        size={'md'}
                        onClick={confirm}
                    >
                        {strings.confirmation.confirm}
                    </LoadingButton>
                    <Button
                        variant={'outlinedAction'}
                        size={'md'}
                        className={cn('w-[400px] max-w-full')}
                        onClick={cancel}
                    >
                        {strings.confirmation.cancel}
                    </Button>
                </div>
            }
            footerProps={{ className: cn('border-none pt-0') }}
            bodyProps={{ className: cn('py-0') }}
        >
            <div className={cn('relative w-full py-10 text-center')}>
                <div className={cn('text-16 font-bold')}>
                    {strings.confirmation.title.func(process.organization.name)}
                </div>
                <div className={cn('text-14 font-medium mt-2')}>
                    {strings.confirmation.subtitle}
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default TerminateDialogConfirmationContent;

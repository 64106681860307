import { useAppDispatch } from '@/_helpers/store';
import { updateCandidateInterested } from '@/_reducers/processes';
import { useToast } from '@/components/ui';
import { Process } from '@/lib/types';
import { extractErrorMsg } from '@/lib/utils/errors';
import { processes as processesStrings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import useLoadingAction from './useLoadingAction';

const strings = processesStrings.processCard.candidateInterested;

function useUpdateCandidateInterested(process: Process) {
    const { id, candidateInterested } = process;
    const [value, setValue] = useState(candidateInterested);
    const { toast } = useToast();
    const dispatch = useAppDispatch();
    const updateValue = useCallback(async () => {
        const newVal = !value;
        setValue(newVal);
        await dispatch(
            updateCandidateInterested({ processId: id, value: !value }),
        ).catch((error) => {
            toast({
                title: strings.failureTitle,
                description: extractErrorMsg(error),
                variant: 'destructive',
            });
        });
    }, [id, value, dispatch, toast]);

    const { isLoading, action: toggleInterested } = useLoadingAction(updateValue);

    useEffect(() => {
        if (!isLoading && value !== candidateInterested) {
            setValue(candidateInterested);
        }
    }, [candidateInterested, value, isLoading]);

    return {
        interested: value,
        toggleInterested,
        loading: isLoading,
    };
}

export default useUpdateCandidateInterested;

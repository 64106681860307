import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { errorBoundary as strings } from '@/strings';

function ErrorFallbackView() {
    return (
        <div
            className={cn('h-full w-full flex flex-col items-center justify-center p-4')}
        >
            <p className={cn('text-20 font-semibold text-black')}>{strings.title}</p>
            <p className={cn('text-16 whitespace-pre-line text-black mt-6')}>
                {strings.subtitle}
            </p>
            <Button
                className={cn('mt-10')}
                onClick={() => {
                    window.location.reload();
                }}
            >
                {strings.refreshButton}
            </Button>
        </div>
    );
}
export default ErrorFallbackView;

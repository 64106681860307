import { DayString, toDayString } from '@/lib/base';
import { cn } from '@/lib/utils';

import CalendarDay from './CalendarDay';
import { WeekDates } from './types';
import { yearAndMonthToDate } from './utils';

interface Props {
    week: WeekDates;
    month: number;
    year: number;
    availableDays: DayString[];
    onDaySelect: (day: DayString) => void;
}
function CalendarWeek({ week, month, year, availableDays, onDaySelect }: Props) {
    return (
        <div className={cn('w-full flex items-center gap-1 justify-between')}>
            {week.map((dayInMonth, i) => {
                if (!dayInMonth) {
                    return <div key={i} className={cn('h-14 w-14')} />;
                }
                const dayString = toDayString(
                    yearAndMonthToDate({
                        month,
                        year,
                        day: dayInMonth,
                    }),
                );
                return (
                    <CalendarDay
                        key={i}
                        dayInMonth={dayInMonth}
                        isAvailable={availableDays.includes(dayString)}
                        onClick={() => onDaySelect(dayString)}
                    />
                );
            })}
        </div>
    );
}

export default CalendarWeek;

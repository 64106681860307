import { XIcon } from '@/components/svgs';
import { DivAttributes } from '@/lib/base';
import { cn } from '@/lib/utils';

import { DialogHeader } from './base';
import { ContentViewProps } from './types';
import { useDragToCloseDialogDispatcher } from './useDraggableDialog';

export function DialogHeaderAnchor({
    anchorProps,
    ...props
}: DivAttributes & { anchorProps?: DivAttributes }) {
    return (
        <div
            {...props}
            className={cn(
                'w-full pt-6 flex justify-center cursor-grab',
                props?.className,
            )}
        >
            <div
                {...anchorProps}
                className={cn(
                    'w-12 h-[4px] bg-gray-400 rounded-2xl',
                    anchorProps?.className,
                )}
            />
        </div>
    );
}

function DialogContentView({
    customHeader,
    headerProps,
    titleProps,
    title,
    children,
    headerAnchorProps,
    handleClose,
}: ContentViewProps) {
    const { bindDragProps } = useDragToCloseDialogDispatcher(true);
    return (
        <>
            <div {...bindDragProps()} className={cn('touch-none')}>
                {customHeader}
                {!customHeader && (
                    <>
                        <DialogHeaderAnchor {...headerAnchorProps} />
                        <DialogHeader {...headerProps}>
                            <div
                                {...titleProps}
                                className={cn('w-full pr-4', titleProps?.className)}
                            >
                                {title}
                            </div>
                            <div className={cn('h-full flex items-start')}>
                                <div
                                    className={cn(
                                        'h-8 w-8 flex items-center justify-center cursor-pointer',
                                    )}
                                    onClick={handleClose}
                                >
                                    <XIcon className={cn('w-4 h-4')} />
                                </div>
                            </div>
                        </DialogHeader>
                    </>
                )}
            </div>
            {children}
        </>
    );
}
export default DialogContentView;

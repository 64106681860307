import config from '@/config';
import { Env } from '@/lib/types';
import { getSessionId } from '@/lib/utils';
import mixpanel from 'mixpanel-browser';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { registerScreenSizeInMixpanel, updateScereenSizeInMixpanel } from './metadata';
import useIdentifyMixpanelUser from './useIdentifyMixpanelUser';
import { isMixpanelOn } from './utils';

interface MixpanelState {
    initiated?: boolean;
}
const MixpanelContext = createContext({} as MixpanelState);

function MixpanelController({ children }: { children: ReactNode }) {
    const [initiated, setInitiated] = useState(false);

    useEffect(() => {
        if (!isMixpanelOn()) {
            return;
        }
        mixpanel.init(config.MIXPANEL_TOKEN, {
            debug: config.ENV === Env.Dev,
            persistence: 'localStorage',
        });
        mixpanel.register({ session: getSessionId(), env: config.ENV });
        setInitiated(true);
        registerScreenSizeInMixpanel(false);
        const windowResizeListener = () => updateScereenSizeInMixpanel();
        window.addEventListener('resize', windowResizeListener);
        return () => {
            window.removeEventListener('resize', windowResizeListener);
        };
    }, []);

    useIdentifyMixpanelUser();

    return (
        <MixpanelContext.Provider
            value={{
                initiated,
            }}
        >
            {(initiated || !isMixpanelOn()) && children}
        </MixpanelContext.Provider>
    );
}

export const useMixpanelContext = () => useContext(MixpanelContext);

export default MixpanelController;

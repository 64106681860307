import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function LongTermStep() {
    const { longTermInterest, setLongTermInterest } = useRegisterFormContext();
    const toggleOption = (value: boolean) => {
        if (value === longTermInterest) {
            setLongTermInterest(null);
        } else {
            setLongTermInterest(value);
        }
    };
    return (
        <RegistrationStepLayout canGoNext={typeof longTermInterest === 'boolean'}>
            <div className={cn('flex flex-col items-center gap-8')}>
                <StepTitle title={strings.longTermTitle} />
                <div className={cn('w-full max-w-[350px]')}>
                    <Button
                        variant={longTermInterest ? 'default' : 'secondary'}
                        className={cn('w-full')}
                        size={'lg'}
                        onClick={() => toggleOption(true)}
                    >
                        {strings.answerYes}
                    </Button>
                    <Button
                        variant={longTermInterest === false ? 'default' : 'secondary'}
                        className={cn('w-full mt-6')}
                        size={'lg'}
                        onClick={() => toggleOption(false)}
                    >
                        {strings.answerNo}
                    </Button>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default LongTermStep;

import { Process } from '@/lib/types';
import { isTerminated, valuesOf } from '@/lib/utils';
import { pickBy } from 'lodash-es';
import { createSelector } from 'reselect';

import { Organizations, ProcessModels } from '../generation_1';

export const AllProcesses = createSelector(
    ProcessModels,
    Organizations,
    (processesById, organizationsById) => {
        const processesWithRelations: Record<string, Process> = {};
        for (const process of valuesOf(processesById)) {
            const organization = organizationsById[process.organizationId];
            if (organization) {
                processesWithRelations[process.id] = { ...process, organization };
            }
        }
        return processesWithRelations;
    },
);

export const Processes = createSelector(AllProcesses, (allProcesses) => {
    return pickBy(allProcesses, (p) => !isTerminated(p));
});

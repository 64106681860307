import { VIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

import { SelectOptionViewProps } from './types';

function SelectOptionView<T extends string>({
    value,
    label,
    onClick,
    isSelected = false,
}: SelectOptionViewProps<T>) {
    return (
        <div
            onClick={() => onClick(value)}
            className={cn(
                'py-7 px-6 w-full text-13 font-semibold hover:bg-accent300',
                'flex items-center justify-between cursor-pointer',
            )}
        >
            <div className={cn('text-13 font-semibold')}>{label}</div>
            <div>
                <VIcon
                    className={cn(
                        'w-[13px] h-[9px] fill-primary stroke-primary opacity-0',
                        'transition-opacity duration-200',
                        { 'opacity-100': isSelected },
                    )}
                />
            </div>
        </div>
    );
}

export default SelectOptionView;

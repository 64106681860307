import { useAppDispatch } from '@/_helpers/store';
import { updateCandidate } from '@/_reducers/candidate';
import { LoadingButton, useToast } from '@/components/ui';
import { cn } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { profile as strings } from '@/strings';

import { ProfileFormFooterProps, ProfileFormValues } from './types';
import { formatFormPayload, reportErrorsOnSubmit } from './utils';

function ProfileFormFooter({
    includeAdditionalAttrs,
    hasErrors,
    handleSubmit,
    updatedFields,
}: ProfileFormFooterProps) {
    const { toast } = useToast();
    const dispatch = useAppDispatch();

    const hasUpdates = updatedFields.length > 0;

    const onSubmit = async (data: ProfileFormValues) => {
        trackEvent(MixpanelEvent.ProfileSubmitForm);
        const payload = formatFormPayload(data, {
            includeAdditionalAttrs,
            updatedFields,
        });
        await dispatch(updateCandidate(payload)).catch((error) => {
            toast({
                title: strings.failureToast.failureTitle,
                description: extractErrorMsg(error),
                variant: 'destructive',
            });
        });
    };

    return (
        <div
            className={cn(
                'w-full flex-shrink-0 flex items-center justify-center h-0 px-8 border-t border-neutral100',
                'overflow-hidden transition-all',
                { 'h-28': hasUpdates },
            )}
        >
            {hasUpdates && (
                <div className={cn('w-full max-w-[375px]')}>
                    <LoadingButton
                        className={cn('w-full h-16 text-13')}
                        onClick={handleSubmit(onSubmit, reportErrorsOnSubmit)}
                        disabled={!hasUpdates || hasErrors}
                    >
                        {strings.submit.label}
                    </LoadingButton>
                </div>
            )}
        </div>
    );
}

export default ProfileFormFooter;

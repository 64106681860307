const strings = {
    errors: {
        invalidFormat: 'Invalid date format',
        invalidMonth: 'Invalid month - please enter value between 01-12',
        invalidYear: {
            func: (minYear: number, maxYear: number) =>
                `Invalid year - please enter value between ${minYear}-${maxYear}`,
            example: ['1900', '2100'],
        },
        invalidRange: 'End date should be after start date',
        futureStartDate: 'Start date cannot be in the future',
    },
};

export default strings;

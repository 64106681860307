import Loader, { LoaderProps } from '@/components/lotties/Loader';
import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';

export type PageLoaderProps = LoaderProps & {
    onFinish?: () => void;
    containerProps?: DefaultDivProps;
};

function PageLoader({
    containerProps,
    onFinish = () => {},
    ...loaderProps
}: PageLoaderProps) {
    useEffect(() => {
        return () => {
            onFinish();
        };
    }, [onFinish]);
    return (
        <div
            {...containerProps}
            className={cn(
                'flex justify-center items-center py-6 h-full w-full',
                containerProps?.className,
            )}
        >
            <Loader variant='primary' sizing='lg' delay={700} {...loaderProps} />
        </div>
    );
}

export default PageLoader;

import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

import BaseLottie from './BaseLottie';
import loaderLottieAnimation from './_files/loader.json';
import { LottieProps } from './types';

const loaderVariants = cva(cn(''), {
    variants: {
        variant: {
            default: cn(''),
            primary: cn('[&_path]:stroke-primary'),
        },
        sizing: {
            default: cn('w-10 h-10'),
            lg: cn('w-16 h-16'),
            md: cn('w-8 h-8'),
            xs: cn('w-[16px] h-[16px]'),
        },
    },
    defaultVariants: {
        variant: 'default',
        sizing: 'default',
    },
});

const Loader = ({
    className,
    variant,
    sizing,
    ...props
}: LottieProps & VariantProps<typeof loaderVariants>) => {
    return (
        <BaseLottie
            animationData={loaderLottieAnimation}
            loop={true}
            className={cn(loaderVariants({ variant, sizing }), className)}
            {...props}
        />
    );
};

export type LoaderProps = React.ComponentProps<typeof Loader>;

export default Loader;

import { useState } from 'react';

function useExternalState<T>(
    externalState?: T,
    setExternalState?: (bool: T) => void,
    options: { defaultVal?: T } = {},
) {
    const { defaultVal } = options;
    const [internalState, setInternalState] = useState<T>(defaultVal as T);
    const isControlled = setExternalState !== undefined;
    const state = isControlled ? externalState : internalState;
    const setState = isControlled ? setExternalState : setInternalState;

    return [state as T, setState] as const;
}

export default useExternalState;

import { Dialog } from '@/components/ui';
import EmptyDialogHeader from '@/components/ui/dialog/emptyDialogHeader';

import CancelScreeningDialogContent from './content';
import { DialogProps } from './types';

function CancelScreeningDialog({ id, open, setOpen }: DialogProps) {
    const handleClose = () => setOpen(false);
    return (
        <>
            {open && (
                <Dialog
                    open={open}
                    setOpen={setOpen}
                    customHeader={<EmptyDialogHeader handleClose={handleClose} />}
                >
                    <CancelScreeningDialogContent id={id} handleClose={handleClose} />
                </Dialog>
            )}
        </>
    );
}

export default CancelScreeningDialog;

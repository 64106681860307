import Logger from '@/_helpers/logger';
import config from '@/config';
import { Env } from '@/lib/types';

import { PixelEventName } from './types';

export function isPixelOn() {
    return config.ENV === Env.Prod;
}

const sentPixelEvents = new Set<PixelEventName>();

export function trackFbPixelEventOnce(
    eventName: PixelEventName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventData?: Record<string, any>,
) {
    if (!isPixelOn()) {
        return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fbq = (window as any)['fbq'];
    if (!fbq) {
        Logger.warn(
            `Failed to send pixel event "${eventName}" (data: ${JSON.stringify(eventData)})`,
        );
        return;
    }
    if (sentPixelEvents.has(eventName)) {
        return;
    }
    sentPixelEvents.add(eventName);
    return fbq('track', eventName, eventData);
}

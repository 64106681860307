import { appPageIdsSorted, routesMap } from '@/lib/utils';
import { Navigate, Route, Routes } from 'react-router-dom';

import { pageIdToView } from './pages';

function MainRoutes() {
    return (
        <Routes>
            {appPageIdsSorted.map((pageId) => {
                const path = routesMap[pageId];
                return <Route key={path} path={path} element={pageIdToView[pageId]} />;
            })}
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
}

export default MainRoutes;

import { useCallback } from 'react';
import { FieldValues, Path, UseFormGetValues } from 'react-hook-form';

function useGetFormValue<T extends FieldValues>({
    getValues,
}: {
    getValues: UseFormGetValues<T>;
}) {
    const getValue = useCallback(
        <K extends Path<T>>(k: K) => getValues()[k],
        [getValues],
    );
    return getValue;
}

export default useGetFormValue;

import { DialogHeaderAnchor } from '@/components/ui';
import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

import GoBackArrow from './GoBackArrow';

export const SUBTITLE_TEXT_CLS = 'font-medium text-14';

export function DialogTitleHeader({
    title,
    subtitle,
    goBack,
    rootProps,
    additionalContent,
}: {
    title: string;
    subtitle?: string;
    goBack?: () => void;
    rootProps?: DefaultDivProps;
    additionalContent?: ReactNode;
}) {
    return (
        <div
            {...rootProps}
            className={cn('w-full border-b border-neutral100', rootProps?.className)}
        >
            <DialogHeaderAnchor />
            <div className={cn('p-8 pt-6')}>
                {goBack && <GoBackArrow className={cn('mb-4')} onClick={goBack} />}
                <div className={cn('font-bold text-18')}>{title}</div>
                {subtitle && (
                    <div className={cn(SUBTITLE_TEXT_CLS, 'mt-1')}>{subtitle}</div>
                )}
                {additionalContent}
            </div>
        </div>
    );
}

export default DialogTitleHeader;

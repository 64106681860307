import { DialogName } from '@/lib/types';
import { Fragment, ReactNode } from 'react';

import CancelInterviewDialogDialogProvider from './cancelInterview/CancelInterviewDialogDialogProvider';
import DialogsContextProvider from './dialogsContext';
import ProcessDetailsDialogProvider from './processDetails/ProcessDetailsDialogProvider';
import ScheduleInterviewDialogProvider from './scheduleInterview/ScheduleInterviewDialogProvider';
import SupportDialogProvider from './support/SupportDialogProvider';
import TerminateProcessDialogProvider from './terminateProcess/TerminateProcessDialogProvider';

const dialogsOrder = [
    DialogName.ProcessDetails,
    DialogName.ScheduleInterview,
    DialogName.CancelInterview,
    DialogName.TerminateProcess,
    DialogName.Support,
];

function DialogsProvider({ children }: { children: ReactNode }) {
    return (
        <DialogsContextProvider>
            {dialogsOrder.map((dialog) => (
                <Fragment key={dialog}>
                    {dialog === DialogName.ProcessDetails && (
                        <ProcessDetailsDialogProvider />
                    )}
                    {dialog === DialogName.ScheduleInterview && (
                        <ScheduleInterviewDialogProvider />
                    )}
                    {dialog === DialogName.CancelInterview && (
                        <CancelInterviewDialogDialogProvider />
                    )}
                    {dialog === DialogName.TerminateProcess && (
                        <TerminateProcessDialogProvider />
                    )}
                    {dialog === DialogName.Support && <SupportDialogProvider />}
                </Fragment>
            ))}
            {children}
        </DialogsContextProvider>
    );
}

export default DialogsProvider;

import { IsLoggedIn } from '@/_selectors';
import useGoTo from '@/hooks/useGoTo';
import { OnboardingFormStep } from '@/lib/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { formStepToDataVerifier } from './steps';
import { getStoredState } from './storedState';
import useFinishRegistration from './useFinishRegistration';

function getFirstStepMissingMandatoryData({
    targetStep,
    isLoggedIn,
    loginStepIndex,
    steps,
}: {
    isLoggedIn: boolean;
    targetStep: number;
    loginStepIndex: number;
    steps: OnboardingFormStep[];
}) {
    const storedData = getStoredState();
    for (const i of steps.keys()) {
        if (isLoggedIn && i <= loginStepIndex) {
            continue;
        }
        const step = steps[i];
        const dataChecks = formStepToDataVerifier[step];
        if (targetStep <= i) {
            return targetStep;
        }
        if (!dataChecks || dataChecks.length === 0) {
            continue;
        }
        for (const check of dataChecks) {
            if (check.emptyCheck(storedData[check.key])) {
                return i;
            }
        }
    }
    return steps.length - 1;
}

function getFirstStepIndex({
    isLoggedIn,
    requestedStep,
    steps,
}: {
    isLoggedIn: boolean;
    requestedStep: number;
    steps: OnboardingFormStep[];
}) {
    const loginStepIndex = steps.indexOf(OnboardingFormStep.CodeVerify);
    const postLoginStepIndex = loginStepIndex + 1;
    const targetStep = isLoggedIn
        ? requestedStep && requestedStep >= loginStepIndex
            ? requestedStep
            : postLoginStepIndex
        : requestedStep ?? 0;
    return getFirstStepMissingMandatoryData({
        isLoggedIn,
        loginStepIndex,
        steps,
        targetStep,
    });
}

function useCurrentStep({ steps }: { steps: OnboardingFormStep[] }) {
    const { goToOnboardingScheduleIntroCall, goToRegister } = useGoTo();
    const isLoggedIn = useSelector(IsLoggedIn);

    const { step: requestedStep } = useParams<{ step?: string }>();
    const requestedStepIndex =
        requestedStep && !isNaN(Number(requestedStep)) ? Number(requestedStep) : 0;
    const finishRegistration = useFinishRegistration();

    const stepIndex = useMemo(
        () =>
            getFirstStepIndex({
                isLoggedIn,
                requestedStep: requestedStepIndex,
                steps,
            }),
        [steps, requestedStepIndex, isLoggedIn],
    );

    const totalSteps = steps.length;

    const navigateNext = useCallback(() => {
        const nextStep = stepIndex + 1;
        if (nextStep < totalSteps) {
            goToRegister(nextStep);
        } else {
            goToOnboardingScheduleIntroCall();
            finishRegistration();
        }
    }, [
        stepIndex,
        goToOnboardingScheduleIntroCall,
        goToRegister,
        totalSteps,
        finishRegistration,
    ]);

    const navigateBack = useCallback(() => {
        if (stepIndex > 0) {
            goToRegister(stepIndex - 1);
        }
    }, [stepIndex, goToRegister]);

    useEffect(() => {
        if (!requestedStep || stepIndex !== requestedStepIndex) {
            goToRegister(stepIndex);
        }
    }, [stepIndex, requestedStepIndex, requestedStep, goToRegister]);

    return {
        navigateBack,
        navigateNext,
        stepIndex,
    };
}

export default useCurrentStep;

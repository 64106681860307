import Logger from '@/_helpers/logger';
import apiClient from '@/lib/api';
import { ResumeFileExtension } from '@/lib/api/v1';
import { getFileExtension } from '@/lib/utils';
import { registration as strings } from '@/strings';

export async function uploadResumeFile(candidateId: string, file: File) {
    const fileExt = getFileExtension(file) as ResumeFileExtension;
    const uploadData = await apiClient.GET(
        '/api/v1/candidates/{candidate_id}/resume/upload-url',
        {
            params: {
                path: { candidate_id: candidateId },
                query: { file_ext: fileExt },
            },
        },
    );
    const { url, resumeUuid, contentType, additionalHeaders = {} } = uploadData.data!;
    const headers = new Headers({ 'Content-Type': contentType, ...additionalHeaders });
    try {
        const uploadResponse = await fetch(url, { method: 'PUT', headers, body: file });
        if (!uploadResponse.ok) {
            const responseError = await uploadResponse.text();
            throw new Error(responseError);
        }
        await apiClient
            .POST('/api/v1/candidates/{candidate_id}/resume/uploaded', {
                params: {
                    path: { candidate_id: candidateId },
                },
                body: { resumeUuid, fileExt },
            })
            .catch((e) => {
                Logger.error(e, 'Failed to trigger resume uploaded flow');
            });
    } catch (e) {
        Logger.error(e, 'Failed to upload resume to Cloud Storage');
        throw new Error(strings.resume.uploadResume.errors.uploadError);
    }
}

import { combineReducers } from 'redux';

import appStateSlice from './appState';
import authSlice from './auth';
import candidateSlice from './candidate';
import devModeSlice from './devMode';
import dialogsSlice from './dialogs';
import historySlice from './history';
import organizationsSlice from './organizations';
import processAvailabilityWindowsSlice from './processAvailabilityWindows';
import processesSlice from './processes';
import screeningSlice from './screening';
import sessionSettingsSlice from './sessionSettings';

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    dialogs: dialogsSlice.reducer,
    processes: processesSlice.reducer,
    organizations: organizationsSlice.reducer,
    appState: appStateSlice.reducer,
    candidate: candidateSlice.reducer,
    screening: screeningSlice.reducer,
    availabilityWindows: processAvailabilityWindowsSlice.reducer,
    processHistory: historySlice.reducer,
    devMode: devModeSlice.reducer,
    sessionSettings: sessionSettingsSlice.reducer,
});

export default rootReducer;

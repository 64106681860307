import { PageLoader } from '@/components/ui';
import ProfessionAvatar from '@/components/ui/ProfessionAvatar';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useControlledSetValue from '@/form/useControlledSetValue';
import useMemoFlatObject from '@/hooks/useMemoFlatObject';
import { Profession, professionTypes } from '@/lib/types';
import { cn, keysOf } from '@/lib/utils';
import { isEmpty, pick } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import AppPageLayout from '../AppPageLayout';
import ProfileForm from './ProfileForm';
import ProfileFormFooter from './ProfileFormFooter';
import ProfileHeader from './ProfileHeader';
import { getDefaultValues, hasSalaryChange } from './utils';

function ProfilePage() {
    const { isReady, data: candidate, refresh } = useCurrentCandidate();
    const [originalValues, setOriginalValues] = useState(getDefaultValues(candidate));

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields: dirtyFieldsMap },
        setValue,
        getValues,
        reset,
    } = useForm({
        defaultValues: originalValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const dirtyFields = useMemoFlatObject(
        keysOf(dirtyFieldsMap).filter(
            (field) =>
                dirtyFieldsMap[field] &&
                (!field.startsWith('hourlySalary') ||
                    hasSalaryChange(
                        getValues().hourlySalary,
                        originalValues.hourlySalary,
                    )),
        ),
    );

    const includeAdditionalAttrs = candidate.profession === Profession.HHA;

    useEffect(() => {
        const defaultValues = getDefaultValues(candidate);
        setOriginalValues(defaultValues);
        reset(defaultValues);
    }, [candidate, reset]);

    useEffect(refresh, [refresh]);

    const controlledSetValue = useControlledSetValue(setValue);

    return (
        <AppPageLayout
            header={<ProfileHeader />}
            footer={
                <ProfileFormFooter
                    handleSubmit={handleSubmit}
                    includeAdditionalAttrs={includeAdditionalAttrs}
                    hasErrors={!isEmpty(pick(errors, dirtyFields))}
                    updatedFields={dirtyFields}
                />
            }
        >
            <div className={cn('relative h-full w-full')}>
                {!isReady && <PageLoader />}
                {isReady && candidate && (
                    <div className={cn('relative w-full flex flex-col items-center')}>
                        <div
                            className={cn(
                                'w-full max-w-[450px] mx-auto flex-grow flex flex-col items-center py-10 px-8',
                            )}
                        >
                            <div className={cn('flex flex-col items-center gap-6')}>
                                <ProfessionAvatar
                                    profession={
                                        professionTypes.includes(
                                            candidate.profession as Profession,
                                        )
                                            ? (candidate.profession as Profession)
                                            : Profession.RN
                                    }
                                    className={cn('h-[90px] w-[90px]')}
                                />
                                <div className={cn('text-15 font-bold')}>
                                    {candidate.name}
                                </div>
                            </div>

                            <ProfileForm
                                originalValues={originalValues}
                                getValues={getValues}
                                setValue={controlledSetValue}
                                control={control}
                                errors={errors}
                                showAdditionalAttributes={includeAdditionalAttrs}
                            />
                        </div>
                    </div>
                )}
            </div>
        </AppPageLayout>
    );
}

export default ProfilePage;

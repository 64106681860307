import { CalculatedProcessStatus, Process } from '@/lib/types';
import { cn, getCalculatedProcessStatus } from '@/lib/utils';
import { processes as strings } from '@/strings';

export function getStatusBadgeText(process: Process) {
    const status = getCalculatedProcessStatus(process.status);
    if (status === CalculatedProcessStatus.PhoneInterview) {
        return strings.statuses.phoneInterview;
    }
    if (status === CalculatedProcessStatus.InPersonInterview) {
        return strings.statuses.inPersonInterview;
    }
    if (status === CalculatedProcessStatus.Paperwork) {
        return strings.statuses.paperwork;
    }
    if (status === CalculatedProcessStatus.Orientation) {
        return strings.statuses.orientation;
    }
    if (status === CalculatedProcessStatus.Terminated) {
        return strings.statuses.terminated;
    }
    if (status === CalculatedProcessStatus.Hired) {
        return strings.statuses.hired;
    }
    return '';
}

export function getStatusBadgeColorsCls(process: Process): string {
    const status = getCalculatedProcessStatus(process.status);

    if (status === CalculatedProcessStatus.PhoneInterview) {
        return cn('text-black bg-phoneInterview');
    }
    if (status === CalculatedProcessStatus.InPersonInterview) {
        return cn('text-black', 'bg-inPersonInterview');
    }
    if (status === CalculatedProcessStatus.Paperwork) {
        return cn('text-black bg-paperwork');
    }
    if (status === CalculatedProcessStatus.Orientation) {
        return cn('text-black bg-orientation');
    }
    if (status === CalculatedProcessStatus.Terminated) {
        return cn('text-black bg-rejected');
    }
    if (status === CalculatedProcessStatus.Hired) {
        return cn('text-black bg-hired');
    }
    return cn('');
}

import { CandidateTerminationReason, ProcessStatus } from '@/lib/api/v1';
import { processStatusToEventTypeMap } from '@/lib/types';

import { eventTypeToName } from './events';

const processStatusToString: Record<ProcessStatus, string> = {
    new: 'New',
    phone_interview: 'Phone interview',
    in_person_interview: 'In-person interview',
    paperwork: 'Offer (docs & screening)',
    orientation: 'Orientation',
    terminated: 'Declined',
    hired: 'Hired',
    matchmaking: 'New',
};

const terminationReasonsToStrings: Record<CandidateTerminationReason, string> = {
    [CandidateTerminationReason.Commute]: 'Location / commuting issues',
    [CandidateTerminationReason.PayRate]: 'Salary',
    [CandidateTerminationReason.NoLongerLooking]: 'No longer looking',
    [CandidateTerminationReason.NotInterestedInNursingHome]:
        'Not interested in this type of facility',
    [CandidateTerminationReason.Other]: 'Other',
};

const processStatusToEventString = (status: ProcessStatus) => {
    const eventType = processStatusToEventTypeMap[status];
    return eventType ? eventTypeToName[eventType] : undefined;
};

export {
    processStatusToString,
    terminationReasonsToStrings,
    processStatusToEventString,
};

import { CodeInput } from '@/components/ui';
import { FIREBASE_INVALID_CODE_ERROR_CODE, INVALID_PHONE_NUMBER } from '@/firebase';
import { INTERNATIONAL_PREFIX, cn, formatInternationalPhoneNumber } from '@/lib/utils';
import { extractErrorMsg, extractErrorType } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import ResendCodeButton from '@/phoneSignIn/ResendCodeButton';
import { VERIFICATION_CODE_LENGTH } from '@/phoneSignIn/consts';
import { registration as strings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';
import useReRegisterCandidate from './useReRegisterCandidate';

function CodeVerificationForm({
    phone,
    submit,
    resendCode,
}: {
    phone: string;
    submit: (code: string) => Promise<void>;
    resendCode: () => Promise<void>;
}) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const { setCustomGoNextAction, defaultGoNext, isReregistration } =
        useRegisterFormContext();
    const reregister = useReRegisterCandidate();

    const clearError = () => setError('');

    const submitCode = useCallback(async () => {
        trackEvent(MixpanelEvent.RegistrationSubmitVerificationCode);
        clearError();
        await submit(code)
            .then(async () => {
                defaultGoNext();
                if (isReregistration) {
                    await reregister();
                }
            })
            .catch((error) => {
                let msg = strings.verificationCode.error;
                if (extractErrorType(error) === FIREBASE_INVALID_CODE_ERROR_CODE) {
                    msg = strings.verificationCode.invalidCodeError;
                }
                setError(msg);
            });
    }, [code, submit, defaultGoNext, isReregistration, reregister]);

    const onResendCode = async (callback: () => void) => {
        trackEvent(MixpanelEvent.RegistrationClickResendCode);
        clearError();
        return await resendCode()
            .then(() => {
                setCode('');
                callback();
            })
            .catch((error) => {
                const type = extractErrorType(error);
                let msg = extractErrorMsg(error);
                if (type === INVALID_PHONE_NUMBER) {
                    msg = strings.errors.invalidPhone;
                }
                setError(msg);
            });
    };

    useEffect(() => {
        setCustomGoNextAction(submitCode);
        return () => {
            setCustomGoNextAction(null);
        };
    }, [submitCode, setCustomGoNextAction]);

    const canGoNext = code.length === VERIFICATION_CODE_LENGTH;

    return (
        <RegistrationStepLayout
            canGoNext={canGoNext}
            additionalButtons={<ResendCodeButton resendCode={onResendCode} />}
        >
            <div className={cn('flex flex-col items-center gap-8')}>
                <div className={cn('max-w-full w-[350px]')}>
                    <StepTitle
                        title={strings.verificationCode.title}
                        subtitle={strings.verificationCode.subtitle.func(
                            formatInternationalPhoneNumber({
                                phone,
                                prefix: INTERNATIONAL_PREFIX,
                            }),
                        )}
                    />
                </div>
                <div className={cn('max-w-full w-[350px]')}>
                    <CodeInput
                        value={code}
                        setValue={setCode}
                        error={error}
                        clearError={clearError}
                        onSubmit={submitCode}
                    />
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default CodeVerificationForm;

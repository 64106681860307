import Logger from '@/_helpers/logger';
import useCandidateId from '@/hooks/useCandidateId';
import apiClient from '@/lib/api';
import { getDateOrNull } from '@/lib/base';
import { cn, dateObjToDateString } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { registration as strings } from '@/strings';
import { useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';
import LastWorkPlaceForm from './LastWorkPlaceForm';

function LastWorkPlaceFormLayout({ goBack }: { goBack: () => void }) {
    const {
        setCustomGoBackAction,
        recentJobCompany: company,
        recentJobTitle: role,
        recentJobStart: startDateString,
        recentJobEnd: endDateString,
        recentJobIsCurrent: isCurrentJob,
        setRecentJobCompany: setCompany,
        setRecentJobTitle: setRole,
        setRecentJobStart: setStartDateString,
        setRecentJobEnd: setEndDateString,
        setRecentJobIsCurrent: setIsCurrentJob,
    } = useRegisterFormContext();
    const startDate = getDateOrNull(startDateString);
    const endDate = getDateOrNull(endDateString);
    const [dateError, setDateError] = useState('');
    const candidateId = useCandidateId();

    const hasValidDateForLastWork =
        !!startDate && !dateError && (!!endDate || isCurrentJob);
    const canGoNext = !!company && !!role && hasValidDateForLastWork;

    const onSubmit = async () => {
        apiClient
            .POST('/api/v1/candidates/{candidate_id}/experiences', {
                params: { path: { candidate_id: candidateId } },
                body: {
                    companyName: company,
                    position: role,
                    startDate: dateObjToDateString(startDate!),
                    endDate: isCurrentJob ? null : dateObjToDateString(endDate!),
                    present: isCurrentJob,
                },
            })
            .then(() => {
                apiClient
                    .POST('/api/v1/candidates/{candidate_id}/resume/generate', {
                        params: { path: { candidate_id: candidateId } },
                    })
                    .catch((error) => {
                        Logger.error(extractErrorMsg(error));
                    });
            })
            .catch((error) => {
                Logger.error(extractErrorMsg(error));
            });
    };

    useEffect(() => {
        setCustomGoBackAction(goBack);
        return () => {
            setCustomGoBackAction(null);
        };
    }, [goBack, setCustomGoBackAction]);

    return (
        <RegistrationStepLayout canGoNext={canGoNext} sideEffect={onSubmit}>
            <div className={cn('w-full max-w-[400px] flex flex-col items-center')}>
                <div className={cn('w-full')}>
                    <StepTitle
                        title={strings.resume.lastExperience.title}
                        subtitle={strings.resume.lastExperience.subtitle}
                    />
                    <div className={cn('mt-10')}>
                        <LastWorkPlaceForm
                            company={company}
                            setCompany={setCompany}
                            role={role}
                            setRole={setRole}
                            startDate={startDate}
                            setStartDate={(date) =>
                                setStartDateString(date?.toISOString() ?? '')
                            }
                            endDate={endDate}
                            setEndDate={(date) =>
                                setEndDateString(date?.toISOString() ?? '')
                            }
                            dateError={dateError}
                            setDateError={setDateError}
                            isCurrentJob={isCurrentJob}
                            setIsCurrentJob={setIsCurrentJob}
                        />
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default LastWorkPlaceFormLayout;

import { ReregisteredCandidateData } from '@/_selectors';
import { Candidate, OnboardingFormStep } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { RegisterFormState } from './types';

function shouldSkipStep(step: OnboardingFormStep, candidate: Candidate) {
    const { hourlySalaryFlexible, hourlySalary, hasResume } = candidate;
    if (step === OnboardingFormStep.Salary && !hourlySalaryFlexible && hourlySalary) {
        return true;
    }
    if (step === OnboardingFormStep.Resume && hasResume) {
        return true;
    }
    return false;
}

function useSkipPostRegistrationStep({
    currentStep,
    currentStepIndex,
    defaultGoNext,
}: Pick<RegisterFormState, 'currentStep' | 'currentStepIndex' | 'defaultGoNext'>) {
    const reregisteredCandidate = useSelector(ReregisteredCandidateData);

    const skipCurrentStep = useCallback(() => {
        trackEvent(MixpanelEvent.RegistrationAutoSkipPostRegistrationStep, {
            current_step: currentStep,
            current_step_index: currentStepIndex,
        });
        defaultGoNext();
    }, [defaultGoNext, currentStep, currentStepIndex]);

    useLayoutEffect(() => {
        if (!reregisteredCandidate) {
            return;
        }
        if (shouldSkipStep(currentStep, reregisteredCandidate)) {
            skipCurrentStep();
        }
    }, [skipCurrentStep, currentStep, reregisteredCandidate]);
}

export default useSkipPostRegistrationStep;

import Logger from '@/_helpers/logger';
import {
    EventStatus,
    EventType,
    ProcessAction,
    ProcessStatus,
    ProcessSubstatus,
} from '@/lib/api/v1';
import {
    CalculatedProcessStatus,
    ComputedProcessStep,
    CurrentProcessActionItemType,
    EventableProcessStatus,
    JobMatch,
    Process,
    ProcessApiModel,
    ProcessSection,
    eventableProcessStatuses,
    processStatusToEventTypeMap,
} from '@/lib/types';

const processSubstatusesSorted = [
    ProcessSubstatus.Pending,
    ProcessSubstatus.Scheduled,
    ProcessSubstatus.Done,
];

export function sortOfferProcesses(processA: Process, processB: Process) {
    if (
        processA.status === ProcessStatus.Orientation &&
        !(processB.status === ProcessStatus.Orientation)
    )
        return -1;
    if (
        !(processA.status === ProcessStatus.Orientation) &&
        processB.status === ProcessStatus.Orientation
    )
        return 1;
    if (
        processA.actions.includes(ProcessAction.CreateEvent) &&
        !processB.actions.includes(ProcessAction.CreateEvent)
    )
        return -1;
    if (
        !processA.actions.includes(ProcessAction.CreateEvent) &&
        processB.actions.includes(ProcessAction.CreateEvent)
    )
        return 1;
    if (
        processSubstatusesSorted.indexOf(processA.substatus as ProcessSubstatus) >
        processSubstatusesSorted.indexOf(processB.substatus as ProcessSubstatus)
    )
        return -1;
    if (
        processSubstatusesSorted.indexOf(processA.substatus as ProcessSubstatus) <
        processSubstatusesSorted.indexOf(processB.substatus as ProcessSubstatus)
    )
        return 1;
    return (
        Number(new Date(processB.statusUpdatedAt)) -
        Number(new Date(processA.statusUpdatedAt))
    );
}

export function sortInterviewProcesses(processA: Process, processB: Process) {
    if (
        processA.actions.includes(ProcessAction.CreateEvent) &&
        !processB.actions.includes(ProcessAction.CreateEvent)
    )
        return -1;
    if (
        !processA.actions.includes(ProcessAction.CreateEvent) &&
        processB.actions.includes(ProcessAction.CreateEvent)
    )
        return 1;
    if (
        processSubstatusesSorted.indexOf(processA.substatus as ProcessSubstatus) >
        processSubstatusesSorted.indexOf(processB.substatus as ProcessSubstatus)
    )
        return -1;
    if (
        processSubstatusesSorted.indexOf(processA.substatus as ProcessSubstatus) <
        processSubstatusesSorted.indexOf(processB.substatus as ProcessSubstatus)
    )
        return 1;
    return (
        Number(new Date(processB.statusUpdatedAt)) -
        Number(new Date(processA.statusUpdatedAt))
    );
}

export const isTerminated = (p: ProcessApiModel) =>
    p.status === ProcessStatus.Terminated;

export const isInProgress = (p: ProcessApiModel) =>
    [
        ProcessStatus.PhoneInterview,
        ProcessStatus.InPersonInterview,
        ProcessStatus.Paperwork,
        ProcessStatus.Orientation,
    ].includes(p.status);

export const isNewProcess = (p: ProcessApiModel) =>
    [ProcessStatus.New, ProcessStatus.Matchmaking].includes(p.status);

export const getSelectedJobMatch = (matches: JobMatch[]) => {
    return matches.find((jm) => jm.selected);
};

export const getProcessDisplayName = (process: Process) => {
    return getSelectedJobMatch(process.jobMatches)?.facility.name;
};

export function getCalculatedProcessStatus(
    status: ProcessStatus,
): CalculatedProcessStatus {
    if (status === ProcessStatus.PhoneInterview) {
        return CalculatedProcessStatus.PhoneInterview;
    }
    if (status === ProcessStatus.InPersonInterview) {
        return CalculatedProcessStatus.InPersonInterview;
    }
    if (status === ProcessStatus.Paperwork) {
        return CalculatedProcessStatus.Paperwork;
    }
    if (status === ProcessStatus.Orientation) {
        return CalculatedProcessStatus.Orientation;
    }
    if (status === ProcessStatus.Terminated) {
        return CalculatedProcessStatus.Terminated;
    }
    if (status === ProcessStatus.Hired) {
        return CalculatedProcessStatus.Hired;
    }
    return CalculatedProcessStatus.New;
}

export function getComputedProcessStep(process: ProcessApiModel): ComputedProcessStep {
    const calculatedStatus = getCalculatedProcessStatus(process.status);
    if (calculatedStatus === CalculatedProcessStatus.New) {
        return ComputedProcessStep.New;
    }
    if (calculatedStatus === CalculatedProcessStatus.Paperwork) {
        return ComputedProcessStep.Paperwork;
    }
    if (calculatedStatus === CalculatedProcessStatus.Terminated) {
        return ComputedProcessStep.Terminated;
    }
    if (calculatedStatus === CalculatedProcessStatus.Hired) {
        return ComputedProcessStep.Hired;
    }
    if (process.substatus === ProcessSubstatus.Pending) {
        if (process.status === ProcessStatus.Orientation) {
            return ComputedProcessStep.PendingOrientationDate;
        }
        return ComputedProcessStep.InterviewRequested;
    }
    if (process.substatus === ProcessSubstatus.Scheduled) {
        return ComputedProcessStep.ScheduledEvent;
    }
    if (process.substatus === ProcessSubstatus.Done) {
        return ComputedProcessStep.PostEvent;
    }
    Logger.error(
        `Failed to compute process step for process ${process.id} ` +
            `(status: ${process.status}, substatus: ${process.substatus})`,
    );
    return ComputedProcessStep.PostEvent;
}

export const eventableProcessStatus: EventableProcessStatus[] = [
    ProcessStatus.PhoneInterview,
    ProcessStatus.InPersonInterview,
    ProcessStatus.Orientation,
];

export function isProcessEventRelated(process: ProcessApiModel): boolean {
    return eventableProcessStatus.includes(process.status as EventableProcessStatus);
}

export function canScheduleEventForProcess(process: ProcessApiModel): boolean {
    return (
        (process.substatus === ProcessSubstatus.Pending ||
            process.substatus === ProcessSubstatus.Scheduled) &&
        [ProcessStatus.PhoneInterview, ProcessStatus.InPersonInterview].includes(
            process.status,
        )
    );
}

export function getEventTypeForProcess(process: ProcessApiModel): EventType | null {
    return processStatusToEventTypeMap[process.status] ?? null;
}

export function getActionItemType(
    process: Process,
): CurrentProcessActionItemType | null {
    if (eventableProcessStatuses.includes(process.status)) {
        if (process.substatus === ProcessSubstatus.Done) {
            return CurrentProcessActionItemType.RecruiterFeedbackRequired;
        }
        if (process.substatus === ProcessSubstatus.Scheduled) {
            return CurrentProcessActionItemType.EventScheduled;
        }
        if (process.substatus === ProcessSubstatus.Pending) {
            return CurrentProcessActionItemType.EventRequested;
        }
    }
    if (process.status === ProcessStatus.Paperwork) {
        return CurrentProcessActionItemType.PendingPaperwork;
    }
    return null;
}

export function hasCurrentActionItem(process: Process): boolean {
    return !!getActionItemType(process);
}

export function getProcessSection(status: ProcessStatus): ProcessSection {
    const calculatedStatus = getCalculatedProcessStatus(status);
    const statusToSectionMap: Record<CalculatedProcessStatus, ProcessSection> = {
        [CalculatedProcessStatus.New]: ProcessSection.New,
        [CalculatedProcessStatus.InPersonInterview]: ProcessSection.Interview,
        [CalculatedProcessStatus.PhoneInterview]: ProcessSection.Interview,
        [CalculatedProcessStatus.Paperwork]: ProcessSection.Offer,
        [CalculatedProcessStatus.Orientation]: ProcessSection.Offer,
        [CalculatedProcessStatus.Hired]: ProcessSection.Done,
        [CalculatedProcessStatus.Terminated]: ProcessSection.Done,
    };
    return statusToSectionMap[calculatedStatus];
}

export function getAlternativeAddress(process: Process) {
    const facility = getSelectedJobMatch(process.jobMatches)?.facility;
    if (!facility) {
        return null;
    }
    if (
        process.status === ProcessStatus.InPersonInterview &&
        facility.alternativeInterviewAddress
    ) {
        return facility.alternativeInterviewAddress;
    }
    if (
        process.status === ProcessStatus.Orientation &&
        facility.alternativeOrientationAddress
    ) {
        return facility.alternativeOrientationAddress;
    }
    return null;
}

export function getEventDefaultAddress(process: Process) {
    const facility = getSelectedJobMatch(process.jobMatches)?.facility;
    if (!facility) {
        return null;
    }
    if (
        ![ProcessStatus.InPersonInterview, ProcessStatus.Orientation].includes(
            process.status,
        )
    ) {
        return null;
    }
    return facility.address;
}

export function canRescheduleProcessEvent(process: Process) {
    if (
        ![ProcessStatus.InPersonInterview, ProcessStatus.PhoneInterview].includes(
            process.status,
        )
    ) {
        return false;
    }
    if (process.substatus !== ProcessSubstatus.Scheduled) {
        return false;
    }
    if (process.relevantEvent?.status !== EventStatus.Scheduled) {
        return false;
    }
    if (new Date(process.relevantEvent.startAt) < new Date()) {
        return false;
    }
    return true;
}

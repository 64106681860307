import { AppDispatch } from '@/_helpers/store';
import apiClient from '@/lib/api';

import screeningSlice, {
    fetchScreeningEventThunk,
    fetchScreeningLinkThunk,
} from './screening';

export const fetchScreeningLink = () => (dispatch: AppDispatch) => {
    dispatch(fetchScreeningLinkThunk());
};

export const fetchScreeningEvent = () => (dispatch: AppDispatch) => {
    dispatch(fetchScreeningEventThunk());
};

export const syncNewScreeningEvent = (uri: string) => async (dispatch: AppDispatch) => {
    const response = await apiClient.POST('/api/v1/screenings/sync', {
        body: { uri },
    });
    dispatch(screeningSlice.actions.updateScreeningEvent(response.data!));
};

export const syncNewRescheduledScreeningEvent =
    (screening_id: string) => async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/screenings/{screening_id}/sync-reschedule',
            { params: { path: { screening_id } } },
        );
        dispatch(screeningSlice.actions.updateScreeningEvent(response.data!));
    };

export const cancelScreeningEvent =
    ({ id, reason }: { id: string; reason: string }) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/screenings/{screening_id}/cancelation',
            {
                params: {
                    path: {
                        screening_id: id,
                    },
                },
                body: { reason },
            },
        );
        dispatch(screeningSlice.actions.updateScreeningEventIfCurrent(response.data!));
    };

export const resetScreeningData = () => (dispatch: AppDispatch) => {
    dispatch(screeningSlice.actions.reset());
};

export const resetScreeningLink = () => (dispatch: AppDispatch) => {
    dispatch(screeningSlice.actions.resetLink());
};

import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

function EmptyView({ text, icon }: { text: string; icon: ReactNode }) {
    return (
        <div
            className={cn(
                'p-8 pt-12 h-full w-full flex flex-col items-center justify-center gap-6',
            )}
        >
            {icon}
            <span className={cn('text-13 text-neutral300')}>{text}</span>
        </div>
    );
}

export default EmptyView;

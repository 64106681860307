import { ArrowLeft, Logo } from '@/components/svgs';
import { cn } from '@/lib/utils';

import { useRegisterFormContext } from './RegisterContext';
import Stepper from './Stepper';

function RegistrationHeader() {
    const { customGoBackAction, defaultGoBack, preventGoBack, stepIndex } =
        useRegisterFormContext();

    const showGoBackButton = stepIndex > 0 && !preventGoBack;
    return (
        <div className={'relative flex flex-col w-full items-stretch gap-8 py-4 px-6'}>
            <Stepper />
            <div className={cn('flex justify-between items-center')}>
                {showGoBackButton ? (
                    <div
                        className={cn(
                            'flex items-center justify-center w-12 h-12 bg-accent300 rounded-full cursor-pointer',
                        )}
                        onClick={customGoBackAction ?? defaultGoBack}
                    >
                        <ArrowLeft />
                    </div>
                ) : (
                    <div className='h-12' />
                )}
                <Logo />
            </div>
        </div>
    );
}

export default RegistrationHeader;

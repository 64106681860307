import config from '@/config';
import { getSessionId } from '@/lib/utils';
import * as Sentry from '@sentry/react';
import { ReactNode, useEffect } from 'react';

import { Env } from '../lib/types';
import ErrorFallbackView from './ErrorFallbackView';

function SentryController({ children }: { children: ReactNode }) {
    useEffect(() => {
        if (config.ENV === Env.Dev && !config.DEV_TEST_SENTRY_ON_DEV) {
            return;
        }
        const isInitialized = Sentry.getClient() !== undefined;
        if (isInitialized) {
            return;
        }
        Sentry.init({
            dsn: config.SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                }),
            ],
            tracesSampleRate: config.ENV === Env.Dev ? 1.0 : 0.1,
            replaysSessionSampleRate: config.ENV === Env.Dev ? 0 : 0.5,
            replaysOnErrorSampleRate: config.ENV === Env.Dev ? 0 : 1.0,
            tracePropagationTargets: [
                'localhost',
                `https://${config.PROD_URL}`,
                `https://${config.STAGING_URL}`,
            ],
            environment: config.ENV,
        });
        Sentry.setTag('session', getSessionId());
    }, []);

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallbackView}>
            {children}
        </Sentry.ErrorBoundary>
    );
}

export default SentryController;

import Logger from '@/_helpers/logger';
import { CurrentUserId } from '@/_selectors';
import { useSelector } from 'react-redux';

function useCandidateId(): string {
    const candidate_id = useSelector(CurrentUserId);
    if (!candidate_id) {
        Logger.error('called hook [useCandidateId] before logged in');
    }
    return candidate_id!;
}

export default useCandidateId;

import { genericToasts } from '../common';

const strings = {
    header: {
        title: 'How can we help?',
        subtitle:
            'Provide a detailed explanation, and our team will get back to you as soon as possible.',
    },
    textboxPlaceholder: 'Add a description here',
    submitButton: 'Submit',
    successTitle: "We're on it!",
    successSubtitle: "We'll be in touch soon.",
    submit: {
        ...genericToasts.failure,
    },
};

export default strings;

import { CandidateRegisterPayload } from '@/lib/types';
import { formattedPhoneToInternationalNumber } from '@/lib/utils';
import { useMemo } from 'react';

import { useRegisterFormContext } from './RegisterContext';
import { getAdditionalAttributes } from './utils';

export default function useRegisterData() {
    const {
        phoneNumber,
        jobTypes,
        profession,
        shifts,
        careSettings,
        hasCar,
        hasDrivingLicense,
        yearsExp,
        fullname,
        zipcode,
        email,
        utmData,
    } = useRegisterFormContext();
    const formattedPhoneNumber = formattedPhoneToInternationalNumber(phoneNumber);

    const registerData = useMemo(
        (): CandidateRegisterPayload => ({
            jobTypes,
            profession,
            shifts,
            careSettings,
            yearsOfExperience: yearsExp,
            phone: formattedPhoneNumber,
            name: fullname,
            zipcode,
            email,
            utm: utmData,
            ...getAdditionalAttributes({
                profession,
                hasCar,
                hasDrivingLicense,
            }),
        }),
        [
            jobTypes,
            profession,
            shifts,
            careSettings,
            hasCar,
            hasDrivingLicense,
            yearsExp,
            formattedPhoneNumber,
            fullname,
            zipcode,
            email,
            utmData,
        ],
    );

    return registerData;
}

import { ProcessStatus } from '@/lib/api/v1';
import { format } from 'date-fns';

import {
    facilityTypeToString,
    orgAdvantageToString,
    orgBenefitToString,
} from '../common';

const strings = {
    offerProcessesTitle: 'Offer phase',
    interviewProcessesTitle: 'Interview phase',
    matchingFacilities: 'New matches',
    noNewFacilities: "There are no matches available. \nWe'll keep searching.",
    processCard: {
        facilityDistance: {
            func: (durationInSeconds: number) =>
                `${Math.round(durationInSeconds / 60)} min`,
            example: [60 * 12.7],
        },
        candidateInterested: {
            label: "I'm interested",
            failureTitle: 'Status update was unsuccessful',
        },
        aboutOrg: 'About:',
        whyWorkHere: 'Why work here:',
        benefits: 'Potential benefits:',
        orgBenefitToString: { ...orgBenefitToString },
        orgAdvantageToString: { ...orgAdvantageToString },
        facilityTypeToString: { ...facilityTypeToString },
        genericAdvantages: {
            advantage1: 'Competitive salary and benefits',
            advantage2: 'Flexible schedule',
        },
    },
    statuses: {
        phoneInterview: 'Phone Interview',
        inPersonInterview: 'In-Person Interview',
        paperwork: 'Offer (Docs & Screening)',
        orientation: 'Orientation',
        terminated: 'Declined',
        hired: 'Hired ✌️',
    },
    statusActionItem: {
        pendingEvent: {
            func: (startAt: Date) => format(startAt, 'eee MMM d, h:mm a'),
            example: [new Date('2024-10-22T21:09:57')],
        },
        pendingRecruiterFeedback: 'Pending recruiter response',
        pendingInterviewScheduling: 'Pending your action',
        pendingOrientationDate: "We'll set your orientation date",
        pendingPaperwork: 'Pending application docs and background checks',
        alternativeLocationNote: {
            label: 'Note:',
            content: {
                func: (status: ProcessStatus) => {
                    const eventString =
                        status === ProcessStatus.Orientation
                            ? 'orientation'
                            : 'interview';
                    return (
                        `The ${eventString} will be at an alternate facility. ` +
                        `Please note the address listed under the ${eventString} date and time.`
                    );
                },

                examples: [
                    [ProcessStatus.InPersonInterview],
                    [ProcessStatus.Orientation],
                ],
            },
        },
    },
    subStatuses: {
        pendingRecruiterFeedback: 'Pending recruiter response',
        interviewRequestPending: 'Pending your response',
        orientationRequestPending: 'Pending orientation date',
    },
    actions: {
        acceptInterview: 'Accept interview',
        declineInterview: 'Decline interview',
        terminate: 'Terminate recruitment process',
    },
    eventActions: {
        rescheduleInterview: 'Reschedule interview',
        cancelInterview: 'Cancel interview',
    },
};

export default strings;

import { CalendarIcon, XIcon } from '@/components/svgs';
import { PopoverMenu } from '@/components/ui';
import useOpenDialog from '@/dialogs/useOpenDialog';
import useProcessAvailability from '@/fetchers/useProcessAvailability';
import {
    DialogName,
    Process,
    ProcessEvent,
    ScheduleInterviewDialogVariant,
} from '@/lib/types';
import { cn } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessEventDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { statusPage as statusPageStrings } from '@/strings';

const strings = statusPageStrings.upcomingEvents;

function ProcessEventActions({
    process,
    event,
    canRescheduleEvent,
    canCancelEvent,
}: {
    process: Process;
    event: ProcessEvent;
    canCancelEvent: boolean;
    canRescheduleEvent: boolean;
}) {
    const { id: processId } = process;
    useProcessAvailability(process);
    const { openDialog: openScheduleInterviewDialog } = useOpenDialog(
        DialogName.ScheduleInterview,
    );
    const { openDialog: openCancelInterviewDialog } = useOpenDialog(
        DialogName.CancelInterview,
    );

    const onReschedule = () => {
        trackEvent(
            MixpanelEvent.UpcomingEventActionsClickRescheduleInterview,
            extractProcessEventDataForMixpanel(process, event),
        );
        openScheduleInterviewDialog({
            processId,
            variant: ScheduleInterviewDialogVariant.Reschedule,
        });
    };

    return (
        <PopoverMenu
            items={[
                ...(canRescheduleEvent
                    ? [
                          {
                              id: 'reschedule',
                              content: {
                                  label: strings.eventActions.rescheduleInterview,
                                  Icon: CalendarIcon,
                              },
                              onClick: onReschedule,
                          },
                      ]
                    : []),
                ...(canCancelEvent
                    ? [
                          {
                              id: 'cancel',
                              content: {
                                  label: strings.eventActions.cancelInterview,
                                  Icon: XIcon,
                                  iconProps: {
                                      className: cn('h-5'),
                                      strokeWidth: 0.5,
                                  },
                              },
                              onClick: () => {
                                  trackEvent(
                                      MixpanelEvent.UpcomingEventActionsClickCancelInterview,
                                      extractProcessEventDataForMixpanel(process, event),
                                  );
                                  openCancelInterviewDialog({
                                      processId,
                                  });
                              },
                          },
                      ]
                    : []),
            ]}
        />
    );
}

export default ProcessEventActions;

import { DivAttributes } from '@/lib/base';
import React, { ReactNode } from 'react';

import {
    DialogContentProps,
    DialogHeaderProps,
    DialogOverlayProps,
    DialogPortalProps,
    DialogProps,
    DialogTriggerProps,
} from './base';

export enum DialogAnimationType {
    SLIDE = 'slide',
    FADE = 'fade',
}

export interface ContentProps {
    customHeader?: ReactNode;
    headerProps?: DialogHeaderProps;
    bodyProps?: React.HTMLAttributes<HTMLDivElement>;
    title?: ReactNode;
    titleProps?: React.HTMLAttributes<HTMLDivElement>;
    children: ReactNode;
}

export interface ContentViewProps extends ContentProps {
    headerAnchorProps?: DivAttributes;
    handleClose: () => void;
}

export interface DialogViewProps extends ContentViewProps {
    open: boolean;
    animation?: DialogAnimationType;
    triggerProps?: DialogTriggerProps;
    contentProps?: DialogContentProps;
    portalProps?: DialogPortalProps;
    overlayProps?: DialogOverlayProps;
    dialogProps?: DialogProps;
    contentWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
    CustomWrapper?: React.FC<{ children: ReactNode }>;
}

export interface CustomDialogProps
    extends Omit<DialogViewProps, 'handleClose' | 'open'> {
    open?: boolean;
    setOpen?: (bool: boolean) => void;
    trigger?: ReactNode;
    alwaysExpanded?: boolean;
}

export type ContentWithFooterProps = {
    footer?: ReactNode;
    footerProps?: React.HTMLAttributes<HTMLDivElement>;
    bodyProps?: React.HTMLAttributes<HTMLDivElement>;
    children?: ReactNode;
    isInnerScroll?: boolean;
    onScrollToBottomCallback?: () => void;
    scrollToBottomDistanceConfig?: number;
};

import { EventType } from '@/lib/api/v1';

const eventTypeToName: Record<EventType, string> = {
    [EventType.PhoneInterview]: 'Phone interview',
    [EventType.InPersonInterview]: 'In-person interview',
    [EventType.Orientation]: 'Orientation',
};

const eventTypeToPreposition: Record<EventType, string> = {
    [EventType.PhoneInterview]: 'with',
    [EventType.InPersonInterview]: 'at',
    [EventType.Orientation]: 'at',
};

export { eventTypeToName, eventTypeToPreposition };

import { DIALOG_ANIMATION_LENGTH } from '@/_reducers/dialogs';
import { useCallback, useEffect, useState } from 'react';

import { useDialogContext } from './context';

const ScrollListenerRegistry = new Set<HTMLElement>();

export function useIsScrollOnTopTracker() {
    const [isScrollTop, setIsScrollTop] = useState(true);
    const { scrollRef, open, isExpandedToTop } = useDialogContext();

    const updateIsScrollTop = useCallback(() => {
        if (scrollRef.current) {
            setIsScrollTop(scrollRef.current.scrollTop <= 0);
        }
    }, [scrollRef, setIsScrollTop]);

    const registerOnScroll = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', updateIsScrollTop);
        }
    }, [scrollRef, updateIsScrollTop]);

    const removeOnScroll = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.removeEventListener('scroll', updateIsScrollTop);
        }
    }, [scrollRef, updateIsScrollTop]);

    useEffect(() => {
        const scrollElement = scrollRef.current;
        if (!scrollElement) return;
        ScrollListenerRegistry.add(scrollElement);
        if (isExpandedToTop) {
            registerOnScroll();
        } else {
            removeOnScroll();
        }
    }, [scrollRef, isExpandedToTop, registerOnScroll, removeOnScroll]);

    useEffect(() => {
        const scrollElement = scrollRef.current;
        if (!open) {
            if (scrollElement && ScrollListenerRegistry.has(scrollElement)) {
                ScrollListenerRegistry.delete(scrollElement);
            }
            const timeout = setTimeout(() => {
                setIsScrollTop(true);
            }, DIALOG_ANIMATION_LENGTH);
            return () => clearTimeout(timeout);
        }
    }, [scrollRef, open, setIsScrollTop]);

    return {
        isScrollTop,
    };
}

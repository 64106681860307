import { useCallback, useState } from 'react';

function useLoadingAction<T>(fn: () => Promise<T>) {
    const [isLoading, setIsLoading] = useState(false);
    const action = useCallback(async () => {
        setIsLoading(true);
        return await fn().finally(() => setIsLoading(false));
    }, [fn, setIsLoading]);
    return { action, isLoading, setIsLoading };
}

export default useLoadingAction;

import { cn } from '@/lib/utils';

interface Props {
    title: string;
    subtitle: string;
}

export function HistoryItemContent({ title, subtitle }: Props) {
    return (
        <div>
            <div className={cn('text-13-23 font-semibold')}>{title}</div>
            <div
                className={cn(
                    'text-12 font-medium mt-1 text-neutral300 whitespace-pre-line',
                )}
            >
                {subtitle}
            </div>
        </div>
    );
}

export default HistoryItemContent;

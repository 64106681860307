import Logger from '@/_helpers/logger';
import { ApiError } from '@/lib/types';

export function serializeFormDataBody<T extends object>(body: T): FormData {
    const fd = new FormData();
    for (const [k, v] of Object.entries(body)) {
        fd.append(k, v);
    }
    return fd;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractApiError(errorObj: any): ApiError {
    try {
        const resObject = {
            detail: errorObj?.detail,
            type: errorObj?.type,
            extra_data: errorObj?.extra_data,
        };
        if (!resObject.detail) {
            throw new Error('Cannot parse "detail" message from error object');
        }
        return resObject;
    } catch (e) {
        Logger.error(
            `Failed to parse error message from server. errorObject: ${JSON.stringify(errorObj)}  |  jsError: ${e}`,
        );
        return {
            detail: 'Could not parse error message',
        };
    }
}

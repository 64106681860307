import useExternalState from '@/hooks/useExternalState';
import { stringToDigitsOnly } from '@/lib/utils';
import { useCallback, useRef } from 'react';

function getDigitsPosition(newTextValue: string, cursorPosition: number) {
    let digitCount = 0;
    for (let i = 0; i < cursorPosition; i++) {
        if (/\d/.test(newTextValue[i])) {
            digitCount++;
        }
    }
    return digitCount;
}

function getCursorPosition(newFormattedTextValue: string, digitsPosition: number) {
    let digitsCount = 0;
    for (let i = 0; i < newFormattedTextValue.length; i++) {
        if (/\d/.test(newFormattedTextValue[i])) {
            digitsCount++;
            if (digitsCount > digitsPosition) {
                return i;
            }
        }
    }
    return newFormattedTextValue.length;
}

function getNewCursorPosition({
    newTextValue,
    cursorPosition,
    newTextValueFormatted,
    hasChange,
}: {
    newTextValue: string;
    newTextValueFormatted: string;
    hasChange: boolean;
    cursorPosition: number;
}) {
    const digitsPos = getDigitsPosition(newTextValue, cursorPosition);
    const formattedPosition = getCursorPosition(newTextValueFormatted, digitsPos);
    if (!hasChange) {
        const prevChar = newTextValueFormatted[cursorPosition - 1];
        if (!/\d/.test(prevChar) && cursorPosition > 0) {
            // Handle remove of a non-digit-char (move cursor to the end of prev digit-char)
            const prevDigitsPos = getDigitsPosition(newTextValue, cursorPosition - 1);
            return getCursorPosition(newTextValueFormatted, prevDigitsPos - 1) + 1;
        }
        return cursorPosition;
    }

    return (
        cursorPosition +
        (newTextValueFormatted.slice(0, formattedPosition).length -
            newTextValue.slice(0, cursorPosition).length)
    );
}

function updateCursorPosition({
    inputElement,
    ...extraProps
}: {
    inputElement: HTMLInputElement | null;
    newTextValue: string;
    newTextValueFormatted: string;
    hasChange: boolean;
}) {
    if (!inputElement) return;
    const cursorPosition = inputElement.selectionStart;
    if (cursorPosition === null) return;
    const newCursorPosition = getNewCursorPosition({
        cursorPosition,
        ...extraProps,
    });
    requestAnimationFrame(() => {
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    });
}

function useDigitsMaskInputVal({
    value: externalValue,
    setValue: externalSetValue,
    formatDigitsToTextVal,
    inputRef: externalInputRef,
}: {
    formatDigitsToTextVal: (value: string) => string;
    value?: string;
    setValue?: (value: string) => void;
    inputRef?: React.RefObject<HTMLInputElement>;
}) {
    const [textValue, setTextValue] = useExternalState(externalValue, externalSetValue);
    const internalInputRef = useRef<HTMLInputElement>(null);
    const inputRef = externalInputRef ?? internalInputRef;

    const digitsValue = stringToDigitsOnly(textValue);

    const updateTextValue = useCallback(
        (newTextValue: string) => {
            const newDigitsValue = stringToDigitsOnly(newTextValue);
            const newTextValueFormatted = formatDigitsToTextVal(newDigitsValue);
            setTextValue(newTextValueFormatted);
            updateCursorPosition({
                inputElement: inputRef.current,
                newTextValue,
                newTextValueFormatted,
                hasChange: digitsValue !== newDigitsValue,
            });
        },
        [digitsValue, setTextValue, inputRef, formatDigitsToTextVal],
    );

    return {
        textValue, // formatted text value
        setTextValue: updateTextValue, // handle new input value
        digitsValue,
        inputRef,
    };
}

export default useDigitsMaskInputVal;

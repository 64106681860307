import { serializeFetcherState } from '@/lib/utils';
import { mapValues } from 'lodash-es';
import { createSelector } from 'reselect';

import { ScreeningState } from './base';

export const ScreeningData = createSelector(ScreeningState, (s) => s.data);

export const ScreeningFetchersData = createSelector(ScreeningState, (s) =>
    mapValues(s.fetchers, serializeFetcherState),
);

export const ScreeningLinkFetcherData = createSelector(
    ScreeningFetchersData,
    (fetchers) => fetchers.link,
);

export const ScreeningEventFetcherData = createSelector(
    ScreeningFetchersData,
    (fetchers) => fetchers.screeningEvent,
);

export const CurrentScreeningLink = createSelector(ScreeningData, (data) => data.link);
export const CurrentScreeningEvent = createSelector(
    ScreeningData,
    (data) => data.screening,
);

import { ProcessStatus } from '@/lib/api/v1';
import { InterviewRelatedProcess, ScheduleInterviewDialogVariant } from '@/lib/types';
import { createSelector } from 'reselect';

import {
    CancelInterviewDialogBaseData,
    ProcessDetailsDialogBaseData,
    ScheduleInterviewDialogBaseData,
    TerminateProcessDialogBaseData,
} from '../generation_1';
import { AllProcesses } from '../generation_2';

export const ProcessDetailsDialogData = createSelector(
    ProcessDetailsDialogBaseData,
    AllProcesses,
    (baseData, processesById) => {
        const { processId } = baseData;
        return { process: processId ? processesById[processId] : undefined };
    },
);

export const TerminateProcessDialogData = createSelector(
    TerminateProcessDialogBaseData,
    AllProcesses,
    (baseData, processesById) => {
        const { processId, variant } = baseData;
        return { variant, process: processId ? processesById[processId] : undefined };
    },
);

export const CancelInterviewDialogData = createSelector(
    CancelInterviewDialogBaseData,
    AllProcesses,
    (baseData, processesById) => {
        const { processId } = baseData;
        return { process: processId ? processesById[processId] : undefined };
    },
);

export const ScheduleInterviewDialogData = createSelector(
    ScheduleInterviewDialogBaseData,
    AllProcesses,
    (baseData, processesById) => {
        const { processId, variant } = baseData;
        const process = processId ? processesById[processId] : undefined;
        if (
            !process ||
            ![ProcessStatus.InPersonInterview, ProcessStatus.PhoneInterview].includes(
                process.status,
            )
        ) {
            return {};
        }
        if (
            variant === ScheduleInterviewDialogVariant.Reschedule &&
            !process.relevantEvent
        ) {
            return {};
        }
        return { process: process as InterviewRelatedProcess, variant };
    },
);

import { EventType, ProcessStatus } from '@/lib/api/v1';
import { DayString } from '@/lib/base';
import { format } from 'date-fns';
import { pick } from 'lodash-es';

import { eventTypeToName, genericToasts, processStatusToString } from '../common';
import talkToUsStrings from './talkToUs';

const strings = {
    header: {
        selectDayTitle: {
            ...pick(processStatusToString, [
                ProcessStatus.PhoneInterview,
                ProcessStatus.InPersonInterview,
            ]),
        },
        selectTimeSlotTitle: {
            func: (day: DayString) => format(new Date(day), 'MMMM d'),
            example: ['2024-10-28'],
        },
        subtitle: {
            phone_interview: 'Choose a date and time for a 15-minute call',
            in_person_interview: 'Choose a date and time for an in-person interview',
        },
        reschedule: {
            selectDayTitle: 'Reschedule interview',
            subtitle: {
                phone_interview: 'Choose a new time and date for the phone interview',
                in_person_interview:
                    'Choose a new time and date for the in-person interview',
            },
        },
        locationNote: {
            alternateLocationLabel: 'Note',
            content: 'Your interview will be held at the following location:',
        },
    },
    talkToUs: {
        header: { ...talkToUsStrings.header },
        successTitle: talkToUsStrings.successTitle,
        successSubtitle: talkToUsStrings.successSubtitle,
    },
    dateSelection: {
        noGoodMatch: {
            link: 'Contact us',
            description: 'Not finding a time that works for you?',
        },
        monthTitle: {
            func: (date: Date) => format(date, 'MMMM yyyy'),
            example: [new Date('2024-10-30')],
        },
    },
    timeSlotSelection: {
        submitButton: {
            func: (eventType: EventType) =>
                `Schedule ${eventTypeToName[eventType].toLowerCase()}`,
            examples: [[EventType.PhoneInterview], [EventType.InPersonInterview]],
        },
        successTitle: 'Interview successfully scheduled',
        reschedule: {
            submitButton: {
                func: (eventType: EventType) =>
                    `Rechedule ${eventTypeToName[eventType].toLowerCase()}`,
                example: [EventType.PhoneInterview],
            },
            successTitle: 'Interview successfully rescheduled',
        },

        successSubtitle:
            "We'll send you an SMS reminder ahead of your interview. Good luck!",
    },
    submit: {
        ...genericToasts.failure,
    },
};

export default strings;

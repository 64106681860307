import { EventType } from '@/lib/api/v1';

import { eventTypeToName, eventTypeToPreposition, genericToasts } from '../common';

const strings = {
    header: {
        title: 'Status',
    },
    tabs: {
        upcomingEvents: 'Upcoming events',
        currentProcesses: 'Current processes',
    },
    upcomingEvents: {
        emptyLabel: 'No upcoming events yet',
        eventTitle: {
            func: (eventType: EventType, facilityName: string) =>
                `${eventTypeToName[eventType]} ${eventTypeToPreposition[eventType]} ${facilityName}`,
            examples: [
                [EventType.PhoneInterview, 'RegalCare at Lowell'],
                [EventType.InPersonInterview, 'RegalCare at Lowell'],
                [EventType.Orientation, 'RegalCare at Lowell'],
            ],
        },
        eventActions: {
            rescheduleInterview: 'Reschedule interview',
            cancelInterview: 'Cancel interview',
            requestChangeOrientation: 'Have a problem? Talk to us',
        },
        failureToast: { ...genericToasts.failure },
    },
    processes: {
        emptyLabel: 'No current processes yet',
    },
};

export default strings;

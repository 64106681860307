import { cn } from '@/lib/utils';

import { NavItemMobileProps } from './types';

const TRANSITION_CLASS = 'transition-colors duration-200 ease-in-out';

function NavItemMobile({ selected, Icon, action }: NavItemMobileProps) {
    return (
        <div
            className={cn('relative flex-grow p-4 bg-white', TRANSITION_CLASS, {
                'hover:bg-accent100 cursor-pointer opacity-40': !selected,
                'border-b-[3px] border-black ': selected,
            })}
            onClick={action}
        >
            <div
                className={cn(
                    'relative w-full flex items-center justify-center rounded-xl px-6 py-4',
                )}
            >
                <Icon className={cn('stroke-black fill-black h-7 w-7')} />
            </div>
        </div>
    );
}

export default NavItemMobile;

import { CandidateCareSetting, CandidateJobType, CandidateShift } from '@/lib/api/v1';

const jobTypeStrings: Record<CandidateJobType, string> = {
    [CandidateJobType.FullTime]: 'Full time',
    [CandidateJobType.PartTime]: 'Part time',
    [CandidateJobType.PerDiem]: 'Per diem',
};

const shiftStrings: Record<CandidateShift, string> = {
    [CandidateShift.Day]: 'Day',
    [CandidateShift.Evening]: 'Evening',
    [CandidateShift.Night]: 'Night',
};

const careSettingsStrings: Record<CandidateCareSetting, string> = {
    [CandidateCareSetting.AcuteCareInpatient]: 'Acute care / Inpatient',
    [CandidateCareSetting.AmbulatoryOutpatient]: 'Ambulatory / Outpatient',
    [CandidateCareSetting.HomeHealth]: 'Home health',
    [CandidateCareSetting.LongTermAcuteCare]: 'Long-term acute care',
    [CandidateCareSetting.SkilledNursing]: 'Skilled nursing',
    [CandidateCareSetting.Telehealth]: 'Telehealth',
};

export { jobTypeStrings, shiftStrings, careSettingsStrings };

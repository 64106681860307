import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

interface ZoomChangeData {
    prevZoom: number;
    currentZoom: number;
}

function useMapZoomEndListener(onZoomEnd: (params: ZoomChangeData) => void) {
    const map = useMap();
    const latestZoom = useRef(map.getZoom());

    useEffect(() => {
        const handleZoomEnd = () => {
            const currentZoom = map.getZoom();
            onZoomEnd({ prevZoom: latestZoom.current, currentZoom });
            latestZoom.current = currentZoom;
        };
        map.on('zoomend', handleZoomEnd);
        return () => {
            map.off('zoomend', handleZoomEnd);
        };
    }, [map, onZoomEnd]);
}

function MapZoomEndListener() {
    const onZoomEnd = ({ prevZoom, currentZoom }: ZoomChangeData) => {
        if (prevZoom < currentZoom) {
            trackEvent(MixpanelEvent.ProcessCardZoomInMap, {
                prev_zoom_level: prevZoom,
                new_zoom_level: currentZoom,
            });
        } else if (prevZoom > currentZoom) {
            trackEvent(MixpanelEvent.ProcessCardZoomOutMap, {
                prev_zoom_level: prevZoom,
                new_zoom_level: currentZoom,
            });
        }
    };
    useMapZoomEndListener(onZoomEnd);
    return null;
}

export default MapZoomEndListener;

export function multiValueToStringVal<T extends string>(multiValue: T[]) {
    return [...multiValue].sort().join(', ');
}

export function toSelectValue<T extends string>(value: T | T[]) {
    if (Array.isArray(value)) {
        return multiValueToStringVal(value);
    }
    return value;
}

export function toggleOptionValue<T extends string>(
    value: T,
    state: T[],
    setState: (value: T[]) => void,
) {
    if (!state.includes(value)) {
        setState([...state, value]);
    } else {
        setState(state.filter((v) => v !== value));
    }
}

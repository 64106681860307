import { Switch } from '@/components/ui/switch';
import useUpdateCandidateInterested from '@/hooks/useUpdateCandidateInterested';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { processes as processesStrings } from '@/strings';

const strings = processesStrings.processCard;

function NewProcessActions({
    process,
    contextComponentName,
}: {
    process: Process;
    contextComponentName: string;
}) {
    const { interested, toggleInterested, loading } =
        useUpdateCandidateInterested(process);
    const updateIsInterested = () => {
        trackEvent(MixpanelEvent.ProcessCardUpdateImInterested, {
            ...extractProcessDataForMixpanel(process),
            via: contextComponentName,
            is_interested: !interested,
        });
        toggleInterested();
    };
    return (
        <div className={cn('flex w-full items-center gap-4')}>
            <Switch
                checked={interested}
                onCheckedChange={updateIsInterested}
                onClick={(e) => e.stopPropagation()}
                loading={loading}
            />
            <div className={cn('text-14 font-medium')}>
                {strings.candidateInterested.label}
            </div>
        </div>
    );
}

export default NewProcessActions;

import { cn } from '@/lib/utils';
import { homepage as strings } from '@/strings';

function PostScreeningMsg() {
    return (
        <div className={cn('flex items-center justify-center py-12')}>
            <div
                className={cn(
                    'relative w-full h-full flex flex-col items-center justify-center px-8 pt-4 pb-2',
                )}
            >
                <div className={cn('text-center text-24 leading-[32px] font-bold')}>
                    {strings.postScreening.title}
                </div>
                <div className={cn('text-center text-15 font-medium mt-3')}>
                    {strings.postScreening.subtitle}
                </div>
            </div>
        </div>
    );
}

export default PostScreeningMsg;

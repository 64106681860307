import { MixpanelEvent, trackEvent } from '@/mixpanel/events';

import { RECAPTCHA_MODAL_CANCEL_TIMEOUT } from './consts';

enum RecaptchaState {
    Init = 'init',
    Shown = 'shown',
    Canceled = 'canceled',
    Failed = 'failed',
    Success = 'success',
}

function getRecaptchaStateManager() {
    let state = RecaptchaState.Init;
    return {
        shown() {
            if (state === RecaptchaState.Shown) {
                return;
            }
            trackEvent(MixpanelEvent.ShowRecaptchaModal);
            state = RecaptchaState.Shown;
        },
        hidden(callback?: () => void) {
            if (state !== RecaptchaState.Shown) {
                return;
            }
            trackEvent(MixpanelEvent.HideRecaptchaModal);
            setTimeout(() => {
                if (state === RecaptchaState.Shown) {
                    trackEvent(MixpanelEvent.CanceledRecaptcha);
                    state = RecaptchaState.Canceled;
                    callback && callback();
                }
            }, RECAPTCHA_MODAL_CANCEL_TIMEOUT);
        },
        verified() {
            trackEvent(MixpanelEvent.SucceedRecaptcha);
            state = RecaptchaState.Success;
        },
        failed() {
            trackEvent(MixpanelEvent.FailedRecaptcha);
            state = RecaptchaState.Failed;
        },
    };
}

const RecaptchaStateManager = getRecaptchaStateManager();
export default RecaptchaStateManager;

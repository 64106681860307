import { useEffect } from 'react';

const useClearQueryParamOnUnmount = (paramName: string) => {
    useEffect(() => {
        return () => {
            const updatedParams = new URLSearchParams(window.location.search);
            if (updatedParams.has(paramName)) {
                updatedParams.delete(paramName);

                const newUrl = `${window.location.pathname}${updatedParams.keys.length > 0 ? '?' : ''}${updatedParams.toString()}`;
                window.history.replaceState(null, '', newUrl);
            }
        };
    }, [paramName]);
};

export default useClearQueryParamOnUnmount;

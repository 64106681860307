import { Button, TextInput } from '@/components/ui';
import { sortEnumList } from '@/lib/base';
import { Profession, professionTypes } from '@/lib/types';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';
import React, { useEffect, useState } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

const professionsOrder = [
    Profession.RN,
    Profession.LPN,
    Profession.NP,
    Profession.CNA,
    Profession.HHA,
    Profession.Other,
];

const getInitialSelection = (profession: string): Profession | null => {
    if (!profession) {
        return null;
    }
    if (professionTypes.includes(profession as Profession)) {
        return profession as Profession;
    }
    return Profession.Other;
};

function ProfessionStep() {
    const { profession, setProfession } = useRegisterFormContext();
    const [profSelection, setProfSelection] = useState<Profession | null>(
        getInitialSelection(profession),
    );
    const [customProfession, setCustomProfession] = useState(
        profSelection === Profession.Other ? profession : '',
    );
    const customProfInputRef = React.useRef<HTMLInputElement>(null);

    const updateCustomProfession = (value: string) => {
        setCustomProfession(value);
        setProfession(value);
    };

    const updateProfOpition = (value: Profession | null) => {
        setProfSelection(value);
        if (value === null) {
            setProfession('');
        } else if (value === Profession.Other) {
            setCustomProfession('');
            setProfession('');
        } else {
            setProfession(value);
        }
    };

    const toggleOption = (value: Profession) => {
        if (value === profSelection) {
            updateProfOpition(null);
        } else {
            updateProfOpition(value);
        }
    };
    const isCutomProfession = profSelection === Profession.Other;

    useEffect(() => {
        const customProfInput = customProfInputRef.current;
        if (isCutomProfession && customProfInput) {
            customProfInput.focus();
        }
    }, [isCutomProfession]);
    return (
        <RegistrationStepLayout canGoNext={!!profession}>
            <div className={cn('flex flex-col items-center gap-8')}>
                <StepTitle title={strings.profession.title} />
                <div
                    className={cn(
                        'w-full max-w-[300px] flex flex-col items-stretch gap-6',
                    )}
                >
                    {sortEnumList(professionTypes, professionsOrder).map((p) =>
                        isCutomProfession && p === Profession.Other ? (
                            <TextInput
                                key={p}
                                ref={customProfInputRef}
                                placeholder={
                                    strings.profession.customProfessionPlaceholder
                                }
                                className={cn({ 'bg-accent400': !!customProfession })}
                                inputClassName={cn({
                                    'bg-accent400': !!customProfession,
                                })}
                                value={customProfession}
                                setValue={updateCustomProfession}
                            />
                        ) : (
                            <Button
                                key={p}
                                variant={p === profSelection ? 'default' : 'secondary'}
                                className={cn('w-full')}
                                size={'lg'}
                                onClick={() => toggleOption(p)}
                            >
                                {strings.professionButtons[p]}
                            </Button>
                        ),
                    )}
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default ProfessionStep;

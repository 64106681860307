import { EventRelatedProcess } from '@/lib/types';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';

export function getMixpanelEventTrackers(process: EventRelatedProcess) {
    const processData = extractProcessDataForMixpanel(process);
    return {
        trackGoToTermination: () => {
            trackEvent(MixpanelEvent.CancelInterviewGoToTermination, {
                ...processData,
            });
        },
        trackGoToCancelationForm: () => {
            trackEvent(MixpanelEvent.CancelInterviewGoToCancelationForm, {
                ...processData,
            });
        },
        trackSubmitCancelation: (reason?: string) => {
            trackEvent(MixpanelEvent.CancelInterviewSubmitForm, {
                ...processData,
                reason: reason ?? '',
            });
        },
    };
}

import { CurrentUserDisplayName } from '@/_selectors';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as strings } from '@/strings';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SearchRadar from './SearchRadar';

const DEFAULT_LAT = 42.3;
const DEFAULT_LNG = -71.43;

function SearchingPage() {
    const { firstName } = useSelector(CurrentUserDisplayName);
    const {
        data: { lat, lng },
    } = useCurrentCandidate();
    useEffect(() => {
        trackEvent(MixpanelEvent.HomePageViewSearchRadar);
    }, []);
    return (
        <div className={cn('flex items-center justify-center py-8')}>
            <div
                className={cn(
                    'relative w-full h-full flex flex-col items-center justify-center px-8',
                )}
            >
                <div className={cn('text-center text-24 leading-[1.5] font-bold')}>
                    {strings.search.title.func(firstName)}
                </div>
                <div className={cn('text-center text-15 font-medium mt-3')}>
                    {strings.search.subtitle}
                </div>
                <div className={cn('relative mt-8 py-4 px-[40px]')}>
                    <SearchRadar lat={lat ?? DEFAULT_LAT} lng={lng ?? DEFAULT_LNG} />
                </div>
            </div>
        </div>
    );
}

export default SearchingPage;

import { DivAttributes } from '@/lib/base';
import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

function AppHeaderLayout({ children, ...props }: DivAttributes) {
    return (
        <div
            {...props}
            className={cn(
                'relative w-full min-h-[66px] h-[66px] border-b border-neutral100',
                'flex items-center justify-between px-8',
                props?.className,
            )}
        >
            {children}
        </div>
    );
}

interface AppPageProps {
    children: ReactNode;
    header?: ReactNode;
    headerProps?: DivAttributes;
    footer?: ReactNode;
    rootDivProps?: DivAttributes;
    contentDivProps?: DivAttributes;
}

function AppPageLayout({
    header,
    headerProps,
    footer,
    children,
    contentDivProps,
    rootDivProps,
}: AppPageProps) {
    return (
        <div
            {...rootDivProps}
            className={cn(
                'relative w-full h-full flex flex-col overflow-hidden',
                rootDivProps?.className,
            )}
        >
            {header && <AppHeaderLayout {...headerProps}>{header}</AppHeaderLayout>}
            <div
                {...contentDivProps}
                className={cn(
                    'relative w-full h-full flex-grow overflow-auto z-10',
                    contentDivProps?.className,
                )}
            >
                <div className={cn('relative w-full h-full')}>{children}</div>
            </div>
            {footer ?? null}
        </div>
    );
}

export default AppPageLayout;

import { CandidateTerminationReason } from '@/lib/api/v1';
import { FetcherState, ProcessApiModel } from '@/lib/types';

export type FetchProcessesFunc = () => Promise<ProcessApiModel[]>;

export enum FetcherName {
    All = 'all',
}

export interface ProcessesState {
    data: Record<string, ProcessApiModel>;
    fetchers: Record<FetcherName, FetcherState>;
}

export interface PostTerminateProcess {
    processId: string;
    reason: CandidateTerminationReason;
    additionalText?: string;
}

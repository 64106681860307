import { Process, ProcessSection } from '@/lib/types';
import {
    cn,
    getProcessSection,
    sortInterviewProcesses,
    sortOfferProcesses,
} from '@/lib/utils';
import ProcessCard from '@/processes/ProcessCard/ProcessCard';
import { processes as strings } from '@/strings';

function ProcessesSectionView({
    processes,
    title,
    sortFunc,
}: {
    processes: Process[];
    title: string;
    sortFunc: (a: Process, b: Process) => number;
}) {
    return (
        <div className={cn('max-w-full flex flex-col items-start gap-6')}>
            <div className={cn('w-[362px] max-w-full font-bold text-18')}>{title}</div>
            <div
                className={cn('relative max-w-full flex flex-wrap gap-6 items-stretch')}
            >
                {processes.sort(sortFunc).map((p) => (
                    <ProcessCard key={p.id} process={p} isVertical />
                ))}
            </div>
        </div>
    );
}

function ActiveProcessesView({ processes }: { processes: Process[] }) {
    const offerProcesses = processes.filter(
        (p) => getProcessSection(p.status) === ProcessSection.Offer,
    );
    const interviewProcesses = processes.filter(
        (p) => getProcessSection(p.status) === ProcessSection.Interview,
    );
    return (
        <div className={cn('px-8 w-full flex flex-col items-start gap-10 max-w-full')}>
            {offerProcesses.length > 0 && (
                <ProcessesSectionView
                    processes={offerProcesses}
                    title={strings.offerProcessesTitle}
                    sortFunc={sortOfferProcesses}
                />
            )}
            {interviewProcesses.length > 0 && (
                <ProcessesSectionView
                    processes={interviewProcesses}
                    title={strings.interviewProcessesTitle}
                    sortFunc={sortInterviewProcesses}
                />
            )}
        </div>
    );
}

export default ActiveProcessesView;

import { IsDevModeOn } from '@/_selectors';
import { HeartLogo } from '@/components/svgs';
import useGoTo from '@/hooks/useGoTo';
import useQueryParamsAndClear from '@/hooks/useQueryParamsAndClear';
import { QueryParam } from '@/lib/types';
import {
    INTERNATIONAL_PREFIX,
    cn,
    formatPhoneNumberToInputVal,
    formattedPhoneToInternationalNumber,
    stringToDigitsOnly,
} from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useTrackPageView from '@/mixpanel/useTrackPageView';
import useLogin from '@/phoneSignIn/useLogin';
import { login as strings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CodeVerificationSection from './CodeVerificationSection';
import LoginBackgroundImage from './LoginBackgroundImage';
import SignInSection from './SignInSection';
import { Step } from './types';

function LoginPage() {
    useTrackPageView('Login');
    const isDevMode = useSelector(IsDevModeOn);
    const [step, setStep] = useState(Step.Phone);

    const { goToRegister } = useGoTo();
    const { phoneNumber, setPhoneNumber, sendPhoneCode, submitConfirmationCode } =
        useLogin();
    const getParamsAndClear = useQueryParamsAndClear([QueryParam.SIGN_IN_PHONE_NUMBER]);

    const onSendPhoneCode = useCallback(
        async (phone: string) => {
            await sendPhoneCode(phone).then(() => {
                trackEvent(MixpanelEvent.LoginEnterCodeVerificationStep);
                setStep(Step.Code);
            });
        },
        [sendPhoneCode],
    );
    const resendCode = useCallback(async () => {
        const internationalPhoneNumber =
            formattedPhoneToInternationalNumber(phoneNumber);
        const phoneNumberToSend = isDevMode ? phoneNumber : internationalPhoneNumber;
        await sendPhoneCode(phoneNumberToSend);
    }, [sendPhoneCode, phoneNumber, isDevMode]);
    const goBackToPhoneInput = useCallback(() => {
        trackEvent(MixpanelEvent.LoginGoBack);
        setStep(Step.Phone);
    }, []);

    useEffect(() => {
        if (isDevMode) {
            setPhoneNumber('');
        }
    }, [isDevMode, setPhoneNumber]);

    useEffect(() => {
        const { phonenumber: phoneNumberFromQuery } = getParamsAndClear();
        if (phoneNumberFromQuery) {
            setPhoneNumber(
                formatPhoneNumberToInputVal(stringToDigitsOnly(phoneNumberFromQuery)),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={cn('relative flex justify-center h-full w-full')}>
            <div
                className={cn(
                    'relative flex flex-col items-stretch h-full w-full max-w-[600px]',
                )}
            >
                <div
                    className={cn(
                        'py-6 px-10 w-full flex items-center justify-between bg-white',
                    )}
                >
                    <HeartLogo className={cn('h-7')} />
                    <div>
                        {step === Step.Phone && (
                            <div
                                className={cn(
                                    'text-13 leading-7 font-bold cursor-pointer',
                                )}
                                onClick={() => {
                                    trackEvent(MixpanelEvent.LoginGoToRegistration);
                                    goToRegister();
                                }}
                            >
                                {strings.linkToSignUp}
                            </div>
                        )}
                    </div>
                </div>
                <div className={cn('relative flex-grow w-full')}>
                    <div className={cn('absolute h-full w-full overflow-hidden')}>
                        <div
                            className={cn(
                                'relative  flex flex-col items-center h-full w-full overflow-auto',
                            )}
                        >
                            <div
                                className={cn(
                                    'flex-grow flex-shrink w-full min-h-36 basis-1/2 overflow-hidden relative',
                                )}
                            >
                                <LoginBackgroundImage
                                    goBack={
                                        step === Step.Code
                                            ? goBackToPhoneInput
                                            : undefined
                                    }
                                />
                            </div>
                            <div
                                className={cn(
                                    'relative flex-shrink w-full basis-1/2 bg-white',
                                )}
                            >
                                <div
                                    className={cn(
                                        'relative w-full h-full flex flex-col items-center p-10 min-w-fit',
                                    )}
                                >
                                    {step === Step.Phone && (
                                        <SignInSection
                                            onSubmit={onSendPhoneCode}
                                            phone={phoneNumber}
                                            setPhone={setPhoneNumber}
                                        />
                                    )}
                                    {step === Step.Code && (
                                        <CodeVerificationSection
                                            phone={phoneNumber}
                                            resendCode={resendCode}
                                            submit={submitConfirmationCode}
                                            prefix={
                                                isDevMode ? '' : INTERNATIONAL_PREFIX
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;

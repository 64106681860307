import { useAppDispatch } from '@/_helpers/store';
import { setDialogOpen } from '@/_reducers/dialogs';
import { ProcessDetailsDialogData } from '@/_selectors';
import { IsProcessDetailsDialogOpen } from '@/_selectors/generation_1/dialogs';
import { DialogName } from '@/lib/types';
import { useSelector } from 'react-redux';

import ProcessDetailsDialog from './ProcessDetailsDialog';

function ProcessDetailsDialogProvider() {
    const dispatch = useAppDispatch();
    const shouldBeOpen = useSelector(IsProcessDetailsDialogOpen);
    const { process } = useSelector(ProcessDetailsDialogData);
    const hasData = !!process;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        dispatch(setDialogOpen(DialogName.ProcessDetails, open));
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            {hasData && (
                <ProcessDetailsDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                />
            )}
        </>
    );
}

export default ProcessDetailsDialogProvider;

import { cn } from '@/lib/utils';

import { useRegisterFormContext } from './RegisterContext';

function Stepper() {
    const { stepIndex, totalSteps } = useRegisterFormContext();
    return (
        <div className={cn('w-full flex items-center gap-2 flex-nowrap')}>
            {Array.from({ length: totalSteps }).map((_, i) => (
                <div
                    key={i}
                    className={cn('h-[2px] flex-grow bg-accent500 rounded-full', {
                        'bg-primary': stepIndex >= i,
                    })}
                />
            ))}
        </div>
    );
}

export default Stepper;

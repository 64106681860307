import { LocationIcon } from '@/components/svgs';
import { DialogHeaderAnchor } from '@/components/ui';
import { DayString, SetStateFunc } from '@/lib/base';
import { InterviewRelatedProcess } from '@/lib/types';
import { cn, getAlternativeAddress, getEventDefaultAddress } from '@/lib/utils';
import { dialogs as dialogStrings } from '@/strings';

import DialogTitleHeader, { SUBTITLE_TEXT_CLS } from '../common/header';
import { DialogStep } from './types';

const strings = dialogStrings.scheduleInterview;

interface Props {
    process: InterviewRelatedProcess;
    isReschedule: boolean;
    trackGoBack: () => void;
    step: DialogStep;
    setStep: SetStateFunc<DialogStep>;
    selectedDay: DayString | null;
    setSelectedDay: SetStateFunc<DayString | null>;
}

function ScheduleInterviewDialogHeader({
    process,
    isReschedule,
    trackGoBack,
    step,
    setStep,
    selectedDay,
    setSelectedDay,
}: Props) {
    const { status } = process;

    const alternativeAddress = getAlternativeAddress(process);
    const defaultAddress = getEventDefaultAddress(process);
    const eventAddress = alternativeAddress ?? defaultAddress;
    const showAddress = !!eventAddress && !isReschedule;
    const hasAlternativeAddress = !!alternativeAddress;

    const headerVariantStrings = isReschedule
        ? strings.header.reschedule
        : strings.header;

    const isDateSelectionStep =
        step === DialogStep.TimeSelection && selectedDay === null;

    return (
        <>
            {step === DialogStep.TimeSelection && (
                <DialogTitleHeader
                    title={
                        isDateSelectionStep
                            ? isReschedule
                                ? strings.header.reschedule.selectDayTitle
                                : strings.header.selectDayTitle[status]
                            : selectedDay
                              ? strings.header.selectTimeSlotTitle.func(selectedDay)
                              : ''
                    }
                    subtitle={`${headerVariantStrings.subtitle[status]}${showAddress ? '.' : ''}`}
                    goBack={
                        !isDateSelectionStep
                            ? () => {
                                  trackGoBack();
                                  setSelectedDay(null);
                              }
                            : undefined
                    }
                    additionalContent={
                        showAddress ? (
                            <div>
                                <div className={cn(SUBTITLE_TEXT_CLS)}>
                                    {hasAlternativeAddress && (
                                        <>
                                            <span className={cn('underline')}>
                                                {
                                                    strings.header.locationNote
                                                        .alternateLocationLabel
                                                }
                                            </span>
                                            <span>: </span>
                                        </>
                                    )}
                                    {strings.header.locationNote.content}
                                </div>
                                <div className={'flex items-center gap-2 mt-4'}>
                                    <LocationIcon className={cn('w-[16px] h-[16px]')} />
                                    <div className={cn('text-14 font-semibold')}>
                                        {eventAddress}
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                />
            )}
            {step === DialogStep.TalkToUs && (
                <DialogTitleHeader
                    title={strings.talkToUs.header.title}
                    subtitle={strings.talkToUs.header.subtitle}
                    goBack={() => {
                        trackGoBack();
                        setStep(DialogStep.TimeSelection);
                    }}
                />
            )}
            {step === DialogStep.TalkToUsSuccess && <DialogHeaderAnchor />}
            {step === DialogStep.ScheduleSuccess && <DialogHeaderAnchor />}
        </>
    );
}

export default ScheduleInterviewDialogHeader;

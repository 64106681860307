const strings = {
    title: {
        func: (firstName: string) =>
            `Welcome ${firstName}! Let's get you started on your job search`,
        example: ['Candy'],
    },
    subtitle: {
        part1: 'Set a 10-minute intro call with a Carefam representative. Call us at',
    },
    skipButton: "I'll skip for now",
};

export default strings;

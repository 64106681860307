import Logger from '@/_helpers/logger';

const getLocalStorageGracefully = (): Storage | null => {
    if (typeof window === 'undefined') {
        return null;
    }
    const TEST_LOCAL_STORAGE_ITEM = 'carefam_local_storage_test';
    try {
        localStorage.setItem(TEST_LOCAL_STORAGE_ITEM, '1');
        localStorage.removeItem(TEST_LOCAL_STORAGE_ITEM);
        return localStorage;
    } catch (e) {
        Logger.warn(`Can't access localStorage: ${e}`);
        return null;
    }
};

export const localStorageGraceful = getLocalStorageGracefully();

import { ClockIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { processes as strings } from '@/strings';

import ProcessStatusDisplay from './ProcessStatusDisplay';

function ScheduledEventActions({ process }: { process: Process }) {
    const { relevantEvent } = process;
    const eventStartTime = relevantEvent ? new Date(relevantEvent.startAt) : undefined;
    return (
        <ProcessStatusDisplay
            process={process}
            substatusView={
                <div className={cn('flex items-center gap-2')}>
                    {eventStartTime && (
                        <>
                            <ClockIcon />
                            <span className={cn('text-11 font-semibold')}>
                                {strings.statusActionItem.pendingEvent.func(
                                    eventStartTime,
                                )}
                            </span>
                        </>
                    )}
                </div>
            }
        />
    );
}

export default ScheduledEventActions;

import * as Sentry from '@sentry/react';

const createLogger = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = (error: any, contextMessage?: string) => {
        console.error(error);
        if (contextMessage) {
            Sentry.setContext('errorContext', { message: contextMessage });
        }
        Sentry.captureException(typeof error === 'string' ? new Error(error) : error);
        Sentry.setContext('errorContext', null);
    };

    const warn = (warning: string) => {
        console.warn(warning);
        Sentry.captureMessage(warning, 'warning');
    };

    return { error, warn };
};

const Logger = createLogger();
export default Logger;

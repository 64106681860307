import { HeartbeatIcon, HomeIcon, ProfileCircleIcon } from '@/components/svgs';
import useGoTo from '@/hooks/useGoTo';
import { AppPageId } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';

import { NavItemConfig } from './types';

function useNavItemsConfig() {
    const { goToHome, goToProfilePage, goToStatusPage } = useGoTo();

    const items: NavItemConfig[] = [
        {
            id: AppPageId.Home,
            action: () => {
                trackEvent(MixpanelEvent.NavigateHomePage);
                goToHome();
            },
            Icon: HomeIcon,
        },
        {
            id: AppPageId.Status,
            action: () => {
                trackEvent(MixpanelEvent.NavigateStatusPage);
                goToStatusPage();
            },
            Icon: () => <HeartbeatIcon className={cn('w-7 h-7')} />,
        },
        {
            id: AppPageId.Profile,
            action: () => {
                trackEvent(MixpanelEvent.NavigateProfilePage);
                goToProfilePage();
            },
            Icon: () => <ProfileCircleIcon className={cn('w-7 h-7')} />,
        },
    ];
    return items;
}

export default useNavItemsConfig;

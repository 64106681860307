import { genericToasts } from '../common';

const strings = {
    header: {
        title: 'Cancel interview',
        subtitle: 'Why would you like to cancel this interview?',
    },
    buttons: {
        cancel: "The time doesn't work for me",
        terminate: "I'm not interested in this position",
    },
    cancelation: {
        header: {
            title: 'Cancel interview',
            subtitle: "Feel free to share the reason you're cancelling (optional)",
        },
        reasonPlaceholder: 'Enter your reason here.',
        submitButton: 'Cancel interview',
        successTitle: 'The interview was canceled',
        successSubtitle:
            "We'll be in touch to discuss moving forward for this position.",
        submit: {
            ...genericToasts.failure,
        },
    },
};

export default strings;

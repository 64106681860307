import useCurrentPageId from '@/hooks/useCurrentPageId';
import { cn } from '@/lib/utils';

import NavItemMobile from './navItem';
import useNavItemsConfig from './useNavItemsConfig';

function NavItemsMenuMobile() {
    const items = useNavItemsConfig();
    const selectedPage = useCurrentPageId();

    return (
        <div className={cn('relative w-full h-full flex items-stretch')}>
            {items.map((item) => (
                <NavItemMobile
                    key={item.id}
                    action={item.action}
                    selected={selectedPage === item.id}
                    Icon={item.Icon}
                />
            ))}
        </div>
    );
}

export default NavItemsMenuMobile;

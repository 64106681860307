import { serializeFetcherState } from '@/lib/utils';
import { createSelector } from 'reselect';

import { CandidateSliceState } from './base';

export const CurrentCandidate = createSelector(CandidateSliceState, (s) => s.data);

export const CurrentCandidateFetcherData = createSelector(CandidateSliceState, (s) =>
    serializeFetcherState(s.fetcher),
);

import { Dispatch, HTMLAttributes, SetStateAction } from 'react';

export type DeepPartialTillLeaf<T, L> = {
    [K in keyof T]?: T[K] extends L ? T[K] : DeepPartialTillLeaf<T[K], L>;
};

export type PartialWithRequired<T, K extends keyof T> = Partial<T> &
    Required<Pick<T, K>>;

export type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type DivAttributes = React.HTMLAttributes<HTMLDivElement>;
export type SVGIconComponent = React.FC<React.SVGProps<SVGSVGElement>>;

export enum CookieNames {
    authToken = 'creafamTalentFirebaseToken',
}

export type DateIsoString = { __brand: 'DateIsoString' } & string;
export type DayString = { __brand: 'DayString' } & string;
export type TimeString = { __brand: 'TimeString' } & string;
export type NumberString = { __brand: 'NumberString' } & string;

export type DefaultDivProps = HTMLAttributes<HTMLDivElement>;

export type SimpleFunc = () => void | Promise<void>;
export type SimpleFuncOrNull = SimpleFunc | null;

export type SetStateFunc<S> = Dispatch<SetStateAction<S>>;

import { useState } from 'react';

function useStateWithChangeCallback<T>(
    callback?: (value: T) => void,
    options: { defaultValue?: T } = {},
) {
    const { defaultValue } = options;
    const [state, setState] = useState<T>(defaultValue as T);
    const handleChange = (newVal: T) => {
        setState(newVal);
        callback && callback(newVal);
    };

    return [state as T, handleChange] as const;
}

export default useStateWithChangeCallback;

import { RootState } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { Candidate, FetchModelStatus } from '@/lib/types';
import { generateEmptyFetcherState } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CandidateSliceState } from './types';

export const fetchCandidateThunk = createAsyncThunk<
    Candidate,
    { candidateId: string },
    { state: RootState }
>('candidate/fetch', async ({ candidateId }, { rejectWithValue }) => {
    try {
        const response = await apiClient.GET('/api/v1/candidates/{candidate_id}', {
            params: { path: { candidate_id: candidateId } },
        });
        return response.data!;
    } catch (error) {
        return rejectWithValue(extractErrorMsg(error));
    }
});

const initialState = {
    data: {},
    fetcher: generateEmptyFetcherState(),
} as CandidateSliceState;

const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Candidate>) => {
            state.data = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCandidateThunk.pending, (state) => {
                state.fetcher.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchCandidateThunk.fulfilled, (state, action) => {
                state.fetcher.status = FetchModelStatus.SUCCESS;
                state.fetcher.initiated = true;
                state.data = action.payload;
            })
            .addCase(fetchCandidateThunk.rejected, (state, action) => {
                state.fetcher.status = FetchModelStatus.FAILURE;
                state.fetcher.error = action.error.message;
            });
    },
});

export default candidateSlice;

import { Button } from '@/components/ui';
import useOpenDialog from '@/dialogs/useOpenDialog';
import useProcessAvailability from '@/fetchers/useProcessAvailability';
import {
    DialogName,
    Process,
    ScheduleInterviewDialogVariant,
    TerminationDialogVariant,
} from '@/lib/types';
import { cn } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { processes as strings } from '@/strings';

import ProcessStatusBadge from '../ProcessStatusBadge';

function InterviewRequestActions({
    process,
    contextComponentName,
    canSchedule,
    canTerminate,
    withProcessBadge = false,
}: {
    process: Process;
    contextComponentName: string;
    canTerminate: boolean;
    canSchedule: boolean;
    withProcessBadge?: boolean;
}) {
    useProcessAvailability(process);
    const { openDialog: acceptInterview } = useOpenDialog(DialogName.ScheduleInterview);
    const { openDialog: openTerminationDialog } = useOpenDialog(
        DialogName.TerminateProcess,
    );
    return (
        <div
            className={cn('relative max-w-full flex flex-col items-stretch gap-4', {
                '-mt-3': withProcessBadge,
            })}
        >
            {withProcessBadge && (
                <div className={cn('ml-auto')}>
                    <ProcessStatusBadge process={process} />
                </div>
            )}
            <div className={cn('relative flex max-w-full flex-col items-stretch gap-4')}>
                {canSchedule && (
                    <Button
                        onClick={() => {
                            trackEvent(MixpanelEvent.ProcessCardClickAcceptInterview, {
                                ...extractProcessDataForMixpanel(process),
                                via: contextComponentName,
                            });
                            acceptInterview({
                                processId: process.id,
                                variant: ScheduleInterviewDialogVariant.AcceptInterview,
                            });
                        }}
                        size={'md'}
                        className={cn('w-[400px] max-w-full')}
                    >
                        {strings.actions.acceptInterview}
                    </Button>
                )}
                {canTerminate && (
                    <Button
                        variant={'outlinedAction'}
                        size={'md'}
                        className={cn('w-[400px] max-w-full')}
                        onClick={() => {
                            trackEvent(MixpanelEvent.ProcessCardClickDeclineInterview, {
                                ...extractProcessDataForMixpanel(process),
                                via: contextComponentName,
                            });
                            openTerminationDialog({
                                processId: process.id,
                                variant: TerminationDialogVariant.DeclineInterview,
                            });
                        }}
                    >
                        {strings.actions.declineInterview}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default InterviewRequestActions;

import { clearImpersonatedUser, setImpersonatedUser } from '@/_reducers/auth';
import { ImpersonationControllerSelector } from '@/_selectors';
import config from '@/config';
import useResetUserData from '@/hooks/useResetUserData';
import { AuthUser } from '@/lib/types';
import { cn, localStorageGraceful } from '@/lib/utils';
import { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';

import ImpersonationDialog from './ImpersonationDialog';
import ImpersonationIndication from './ImpersonationIndication';

interface Props {
    children: ReactNode;
    clearImpersonatedUser: () => void;
    setImpersonatedUser: (user: AuthUser) => void;
    impersonatedUser: AuthUser | null;
    isOperator: boolean;
}

function ImpersonationController({
    children,
    clearImpersonatedUser,
    setImpersonatedUser,
    isOperator,
    impersonatedUser,
}: Props) {
    const { reset: resetCandidateData } = useResetUserData();

    useEffect(() => {
        if (!isOperator) {
            clearImpersonatedUser();
            return;
        }
        if (
            localStorageGraceful &&
            localStorageGraceful['oparetorData_impersonatedCandidate']
        ) {
            setImpersonatedUser(
                JSON.parse(localStorageGraceful['oparetorData_impersonatedCandidate']),
            );
        }
    }, [setImpersonatedUser, isOperator, clearImpersonatedUser]);
    const mustImpersonate = isOperator && !impersonatedUser;

    useEffect(() => {
        if (mustImpersonate) {
            resetCandidateData();
        }
    }, [mustImpersonate, resetCandidateData]);

    return (
        <>
            {!mustImpersonate && (
                <div className={cn('relative flex flex-col h-full overflow-hidden')}>
                    {impersonatedUser && (
                        <ImpersonationIndication
                            impersonatedUser={impersonatedUser}
                            exitImpersonation={clearImpersonatedUser}
                            env={config.ENV}
                        />
                    )}
                    <div className={cn('flex-grow relative overflow-hidden')}>
                        <div className={cn('relative w-full h-full')}>{children}</div>
                    </div>
                </div>
            )}
            {mustImpersonate && <ImpersonationDialog open={!impersonatedUser} />}
        </>
    );
}

const mapDispatchToProps = {
    clearImpersonatedUser,
    setImpersonatedUser,
};

export default connect(
    ImpersonationControllerSelector,
    mapDispatchToProps,
)(ImpersonationController);

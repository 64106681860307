import { AppDispatch } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { EventType, InitiatingSide } from '@/lib/api/v1';
import { DateIsoString } from '@/lib/base';
import pRetry from 'p-retry';

import processesSlice, { saveProcesses } from './processes';
import { FetcherName, PostTerminateProcess } from './types';

const fetchProcesses = async () => {
    const response = await apiClient.GET('/api/v1/processes', {
        params: {
            query: {
                limit: 50,
            },
        },
    });
    return response.data!.items;
};

const fetchSingleProcess = async (processId: string) => {
    const response = await apiClient.GET('/api/v1/processes/{process_id}', {
        params: {
            path: { process_id: processId },
        },
    });
    return response.data!;
};

export const fetchProcess =
    (processId: string, retry = false) =>
    async (dispatch: AppDispatch) => {
        const apiCall = async () => {
            const process = await fetchSingleProcess(processId);
            dispatch(processesSlice.actions.updateProcess(process));
        };
        await pRetry(apiCall, {
            retries: retry ? 2 : 0,
            factor: 2,
            minTimeout: 1000,
            maxRetryTime: 8000,
        });
    };

export const fetchAllProcesses = () => (dispatch: AppDispatch) =>
    dispatch(
        saveProcesses({
            fetcherId: FetcherName.All,
            fetchFunction: () => fetchProcesses(),
        }),
    );

export const resetProcesses = () => (dispatch: AppDispatch) => {
    dispatch(processesSlice.actions.reset());
};

export const updateCandidateInterested =
    ({ processId, value }: { processId: string; value: boolean }) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.PATCH(
            '/api/v1/processes/{process_id}/candidate-interest',
            { params: { path: { process_id: processId } }, body: { interested: value } },
        );
        dispatch(processesSlice.actions.updateProcess(response.data!));
    };

export const scheduleEventForProcess =
    ({
        processId,
        startAt,
        eventType,
    }: {
        processId: string;
        startAt: DateIsoString;
        eventType: EventType;
    }) =>
    async (dispatch: AppDispatch) => {
        await apiClient.POST('/api/v1/candidate-events', {
            body: { processId, startAt, eventType },
        });
        await dispatch(fetchProcess(processId));
    };

export const recheduleProcessEvent =
    ({ eventId, startAt }: { eventId: string; startAt: DateIsoString }) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.PATCH('/api/v1/candidate-events/{event_id}', {
            params: { path: { event_id: eventId } },
            body: { startAt },
        });
        await dispatch(fetchProcess(response.data!.processId));
    };

export const terminateProcess =
    ({ processId, reason, additionalText }: PostTerminateProcess) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/processes/{process_id}/termination',
            {
                params: {
                    path: { process_id: processId },
                },
                body: {
                    terminatedBy: InitiatingSide.Candidate,
                    reasonType: reason,
                    reason: additionalText,
                },
            },
        );
        dispatch(processesSlice.actions.updateProcess(response.data!));
    };

export const cancelInterviewEvent =
    ({ eventId, reason }: { eventId: string; reason?: string }) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/candidate-events/{event_id}/cancel',
            {
                params: {
                    path: { event_id: eventId },
                },
                body: {
                    reason,
                },
            },
        );
        await dispatch(fetchProcess(response.data!.processId));
    };

import { ProcessHistory } from './apiModels';

export type ProcessHistoryWithParsedAction = ProcessHistory & {
    actionName: string;
};

export enum CustomHistoryAction {
    Revive = 'Revive',
}

export enum CurrentProcessActionItemType {
    EventRequested = 'pendingEvent',
    EventScheduled = 'eventScheduled',
    RecruiterFeedbackRequired = 'recruiterFeedbackRequired',
    PendingPaperwork = 'pendingPaperwork',
}

import { AppPageId, QueryParam } from '@/lib/types';
import { getRouteForPageId } from '@/lib/utils';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

function useGoTo() {
    const navigate = useNavigate();
    const getSearchWithParams = (params: Partial<Record<QueryParam, string>>) => {
        const searchParams = new URLSearchParams(window.location.search);
        Object.entries(params).forEach(([key, value]) => {
            searchParams.set(key, value);
        });
        return searchParams.toString();
    };

    const goToHome = ({ phoneNumber }: { phoneNumber?: string } = {}) =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Home),
            search: getSearchWithParams({
                ...(phoneNumber ? { phonenumber: phoneNumber } : {}),
            }),
        });
    const goToProfilePage = () =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Profile),
            search: getSearchWithParams({}),
        });
    const goToStatusPage = () =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Status),
            search: getSearchWithParams({}),
        });
    const goToRegister = (stepIndex?: number) => {
        return navigate({
            pathname: generatePath(getRouteForPageId(AppPageId.Register), {
                step: stepIndex ?? 0,
            }),
            search: getSearchWithParams({}),
        });
    };
    const goToOnboardingScheduleIntroCall = () => {
        return navigate({
            pathname: getRouteForPageId(AppPageId.OnboardingScheduleIntroCall),
            search: getSearchWithParams({}),
        });
    };

    return useMemo(
        () => ({
            goToHome,
            goToRegister,
            goToProfilePage,
            goToStatusPage,
            goToOnboardingScheduleIntroCall,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate],
    );
}

export default useGoTo;

import { Env } from './lib/types';

export default {
    API_BASE_URL: import.meta.env.VITE_API_URL ?? '',
    FIREBASE_API_KEY: import.meta.env.VITE_PUBLIC_FIREBSE_API_KEY,
    FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_PUBLIC_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJ_ID,
    FIREBASE_MSG_SENDER_ID: import.meta.env.VITE_FIREBASE_MSG_SENDER_ID,
    FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
    ENV: import.meta.env.VITE_ENV ?? Env.Dev,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
    DEV_TEST_SENTRY_ON_DEV: import.meta.env.VITE_TEST_SENTRY_ON_DEV,
    DEV_TEST_MIXPANEL_ON_DEV: import.meta.env.VITE_TEST_MIXPANEL_ON_DEV,
    DEV_PREVENT_LOG_MIXPANEL_ON_DEV: import.meta.env.VITE_PREVENT_LOG_MIXPANEL_ON_DEV,
    DEV_FIREBASE_DEBUG_MODE: import.meta.env.VITE_FIREBASE_DEBUG_MODE ?? false,
    DEFAULT_TOKEN_TTL_DAYS: 365,
    PROD_URL: 'talent.carefam.com',
    STAGING_URL: 'talent-staging.carefam.com',
    RECAPTCHA_CONTAINER_ID: 'recaptcha-container-base',
};

import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

interface Props {
    date: ReactNode;
    header: ReactNode;
    description: ReactNode;
    menu: ReactNode;
    rootProps?: DefaultDivProps;
}

function EventLayout({ date, header, description, menu, rootProps }: Props) {
    return (
        <div
            {...rootProps}
            className={cn(
                'p-6 rounded-2xl shadow-elevation-100 bg-white',
                rootProps?.className,
            )}
        >
            <div className={cn('flex flex-nowrap items-center gap-4')}>
                <div
                    className={cn(
                        'relative flex flex-nowrap flex-grow items-center gap-5',
                    )}
                >
                    <div
                        className={cn(
                            'relative h-28 w-28 min-w-28 rounded-xl overflow-hidden',
                        )}
                    >
                        {date}
                    </div>
                    <div className={cn('flex-grow')}>
                        {header}
                        {description}
                    </div>
                </div>
                <div>{menu}</div>
            </div>
        </div>
    );
}

export default EventLayout;

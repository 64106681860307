import { IsLoggedIn } from '@/_selectors';
import useCurrentPageId, { useIsCurrentPageIdAnonymous } from '@/hooks/useCurrentPageId';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useMixpanelContext } from './MixpanelController';
import { MixpanelEvent, trackEvent } from './events';

const useTrackPageView = (pageName?: string) => {
    const location = useLocation();
    const pageId = useCurrentPageId();
    const isCurrentPageAnonymous = useIsCurrentPageIdAnonymous();
    const isLoggedIn = useSelector(IsLoggedIn);
    const pageDisplayName = pageName ?? pageId;
    const { initiated } = useMixpanelContext();

    const { pathname, search } = location;

    useEffect(() => {
        if (initiated) {
            if (!pageName && !isCurrentPageAnonymous && !isLoggedIn) {
                return;
            }
            trackEvent(MixpanelEvent.VisitPage, {
                page: pageDisplayName ?? '',
                path: pathname,
                search: search,
            });
        }
    }, [
        pathname,
        search,
        isCurrentPageAnonymous,
        initiated,
        pageDisplayName,
        isLoggedIn,
        pageName,
    ]);
};

export default useTrackPageView;

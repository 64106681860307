import useQueryParamsAndClear from '@/hooks/useQueryParamsAndClear';
import { CandidateCareSetting, CandidateJobType, CandidateShift } from '@/lib/api/v1';
import { SimpleFuncOrNull } from '@/lib/base';
import { OnboardingQuestion, OnboardingQuestionWithAnswer } from '@/lib/types';
import { localStorageGraceful } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useLogin from '@/phoneSignIn/useLogin';
import { isEmpty } from 'lodash-es';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { MAIN_KEY, getStoredState } from './storedState';
import {
    FormAdditionalDataKey,
    FormDataKey,
    FormDataValue,
    RegisterFormState,
} from './types';
import useCurrentStep from './useCurrentStep';
import useSkipPostRegistrationStep from './useSkipPostRegistrationStep';
import {
    debouncedTrackUpdateAnswerEvent,
    getOrderedFormSteps,
    isOnboardingQuestion,
    shouldSkipTrackEvent,
} from './utils';
import { utmParams } from './utmParams';

const RegisterFormContext = createContext({} as RegisterFormState);

function toggleSelect<T>(val: T, state: T[], setState: (vals: T[]) => void) {
    if (!state.includes(val)) {
        setState([...state, val]);
    } else {
        setState(state.filter((v) => v !== val));
    }
}

function useStoredState<T extends FormDataKey>(
    key: T,
    defaultVal: FormDataValue<T>,
): [FormDataValue<T>, (val: FormDataValue<T>) => void] {
    const [val, setVal] = useState<FormDataValue<T>>(() => {
        const storedState = getStoredState();
        return storedState[key] !== undefined ? storedState[key] : defaultVal;
    });

    const updateVal = useCallback(
        (newVal: FormDataValue<T>) => {
            if (localStorageGraceful) {
                const storedState = getStoredState();
                const updatedState = { ...storedState, [key]: newVal };
                localStorageGraceful.setItem(MAIN_KEY, JSON.stringify(updatedState));
            }
            setVal(newVal);
            if (isOnboardingQuestion(key)) {
                const payload = {
                    question: key,
                    answer: newVal,
                } as OnboardingQuestionWithAnswer;
                if (shouldSkipTrackEvent(payload)) {
                    return;
                }
                debouncedTrackUpdateAnswerEvent(payload);
            }
        },
        [key],
    );

    return [val, updateVal];
}

function RegisterFormContextProvider({ children }: { children: ReactNode }) {
    const [longTermInterest, setLongTermInterest] = useStoredState(
        OnboardingQuestion.LongTermInterest,
        null,
    );
    const [prof, setProf] = useStoredState(OnboardingQuestion.Profession, '');
    const [jobTypes, setJobTypes] = useStoredState(OnboardingQuestion.JobTypes, []);
    const [shifts, setShifts] = useStoredState(OnboardingQuestion.Shifts, []);
    const [careSettings, setCareSettings] = useStoredState(
        OnboardingQuestion.CareSettings,
        [],
    );
    const [hasCar, setHasCar] = useStoredState(OnboardingQuestion.HasCar, null);
    const [hasDrivingLicense, setHasDrivingLicense] = useStoredState(
        OnboardingQuestion.HasDrivingLicense,
        null,
    );
    const [yearsExp, setYearsExp] = useStoredState(OnboardingQuestion.YearsExp, null);
    const [zipcode, setZipcode] = useStoredState(OnboardingQuestion.ZipCode, '');
    const [zipcodeLocation, setZipcodeLocation] = useState('');
    const [fullname, setFullname] = useStoredState(OnboardingQuestion.Name, '');
    const [email, setEmail] = useStoredState(OnboardingQuestion.Email, '');
    const [phoneNumber, setPhoneNumber] = useStoredState(OnboardingQuestion.Phone, '');
    const [salary, setSalary] = useStoredState(OnboardingQuestion.Salary, null);
    const [recentJobCompany, setRecentJobCompany] = useStoredState(
        OnboardingQuestion.RecentJobCompany,
        '',
    );
    const [recentJobTitle, setRecentJobTitle] = useStoredState(
        OnboardingQuestion.RecentJobTitle,
        '',
    );
    const [recentJobStart, setRecentJobStart] = useStoredState(
        OnboardingQuestion.RecentJobStart,
        '',
    );
    const [recentJobEnd, setRecentJobEnd] = useStoredState(
        OnboardingQuestion.RecentJobEnd,
        '',
    );
    const [recentJobIsCurrent, setRecentJobIsCurrent] = useStoredState(
        OnboardingQuestion.RecentJobIsCurrent,
        false,
    );
    const [cvFile, setCvFile] = useState<File | null>(null);

    const [utmData, setUtmData] = useStoredState(FormAdditionalDataKey.UtmData, {});
    const [isReregistration, setIsReregistration] = useState(false);

    const [registeredPhone, setRegisteredPhone] = useState<string | null>(null);
    const [preventGoBack, setPreventGoBack] = useState(false);
    const [customGoNextAction, setCustomGoNextAction] = useState<SimpleFuncOrNull>(null);
    const [customGoBackAction, setCustomGoBackAction] = useState<SimpleFuncOrNull>(null);
    const getParamsAndClear = useQueryParamsAndClear(utmParams);

    const toggleJobType = (jt: CandidateJobType) =>
        toggleSelect(jt, jobTypes, setJobTypes);
    const toggleShift = (s: CandidateShift) => toggleSelect(s, shifts, setShifts);
    const toggleCareSetting = (cs: CandidateCareSetting) =>
        toggleSelect(cs, careSettings, setCareSettings);

    const steps = useMemo(() => getOrderedFormSteps(prof), [prof]);
    const { stepIndex, navigateBack, navigateNext } = useCurrentStep({ steps });
    const currentStep = steps[stepIndex];
    const totalSteps = steps.length;

    const {
        sendPhoneCode,
        submitConfirmationCode,
        hasSentCode: hasSentConfirmationCode,
    } = useLogin({
        phoneNumber,
        setPhoneNumber,
        isRegistration: true,
    });

    useEffect(() => {
        const utmParams = getParamsAndClear();
        if (!isEmpty(utmParams)) {
            setUtmData(utmParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        trackEvent(MixpanelEvent.RegistrationStartStep, {
            current_step: currentStep,
            current_step_index: stepIndex,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepIndex]);

    const currentStepData = useMemo(
        () => ({
            current_step: currentStep,
            current_step_index: stepIndex,
        }),
        [currentStep, stepIndex],
    );

    const defaultGoNext = useCallback(() => {
        trackEvent(MixpanelEvent.RegistrationFinishStep, currentStepData);
        navigateNext();
    }, [currentStepData, navigateNext]);

    const defaultGoBack = useCallback(() => {
        trackEvent(MixpanelEvent.RegistrationClickPrevStep, currentStepData);
        navigateBack();
    }, [navigateBack, currentStepData]);

    useSkipPostRegistrationStep({
        currentStep,
        currentStepIndex: stepIndex,
        defaultGoNext,
    });

    return (
        <RegisterFormContext.Provider
            value={{
                totalSteps,
                currentStep,
                currentStepIndex: stepIndex,
                stepIndex,
                longTermInterest,
                setLongTermInterest,
                profession: prof,
                setProfession: setProf,
                shifts,
                toggleShift,
                careSettings,
                toggleCareSetting,
                hasDrivingLicense,
                setHasDrivingLicense,
                hasCar,
                setHasCar,
                jobTypes,
                toggleJobType,
                utmData,
                zipcode,
                setZipcode,
                zipcodeLocation,
                setZipcodeLocation,
                fullname,
                setFullname,
                email,
                setEmail,
                phoneNumber,
                setPhoneNumber,
                yearsExp,
                setYearsExp,
                salary,
                setSalary,
                recentJobCompany,
                setRecentJobCompany,
                recentJobTitle,
                setRecentJobTitle,
                recentJobStart,
                setRecentJobStart,
                recentJobEnd,
                setRecentJobEnd,
                recentJobIsCurrent,
                setRecentJobIsCurrent,
                cvFile,
                setCvFile,
                registeredPhone,
                setRegisteredPhone,
                isReregistration,
                setIsReregistration,
                sendPhoneCode,
                submitConfirmationCode,
                hasSentConfirmationCode,
                preventGoBack,
                setPreventGoBack,
                defaultGoNext,
                defaultGoBack,
                customGoNextAction,
                setCustomGoNextAction: (fn: SimpleFuncOrNull) =>
                    setCustomGoNextAction(() => fn),
                customGoBackAction,
                setCustomGoBackAction: (fn: SimpleFuncOrNull) =>
                    setCustomGoBackAction(() => fn),
            }}
        >
            {children}
        </RegisterFormContext.Provider>
    );
}

export const useRegisterFormContext = () => useContext(RegisterFormContext);

export default RegisterFormContextProvider;

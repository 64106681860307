import { serializeFetcherState } from '@/lib/utils';
import { mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { ProcessHistoryStateSelector } from './base';

export const ProcessesHistoryByProcessId = createSelector(
    ProcessHistoryStateSelector,
    (state) =>
        mapValues(state, (state) => ({
            history: state.history,
            fetcher: serializeFetcherState(state.fetcher),
        })),
);

import { FacilityIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import ProcessCard from '@/processes/ProcessCard/ProcessCard';
import { processes as strings } from '@/strings';

function NewProcessesView({ processes }: { processes: Process[] }) {
    const hasNewProcesses = processes.length > 0;
    return (
        <>
            <div className={cn('w-full flex justify-start px-8 absolute')}>
                <div
                    className={cn('w-full font-bold text-18', {
                        'w-[362px] max-w-full': processes.length === 1,
                    })}
                >
                    {strings.matchingFacilities}
                </div>
            </div>
            <div
                className={cn(
                    'relative overflow-auto w-full h-full pt-16 pb-24 hidden-scrollbar',
                )}
            >
                <div className={cn('flex flex-nowrap gap-8 items-stretch')}>
                    {hasNewProcesses && (
                        <>
                            <div className='w-0' />
                            {processes.map((p) => (
                                <ProcessCard
                                    key={p.id}
                                    process={p}
                                    isVertical={false}
                                    rootProps={{
                                        className: cn('flex-grow max-w-[362px]'),
                                    }}
                                />
                            ))}
                            <div className='min-w-1 opacity-0' />
                        </>
                    )}
                    {!hasNewProcesses && (
                        <div className={cn('px-10 w-full')}>
                            <div
                                className={cn(
                                    'rounded-2xl border border-neutral200 px-10 py-16',
                                    'flex flex-col items-center gap-6',
                                )}
                            >
                                <FacilityIcon
                                    className={cn(
                                        'h-20 w-20 stroke-neutral200 fill fill-neutral200',
                                    )}
                                />
                                <div
                                    className={cn(
                                        'text-13-23 font-medium text-center whitespace-pre-line',
                                    )}
                                >
                                    {strings.noNewFacilities}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default NewProcessesView;

import { cn } from '@/lib/utils';

import FooterMenu from './appMenu';
import {
    useIsCurrentPageIdAnonymous,
    useShouldShowFooterMenu,
} from './hooks/useCurrentPageId';
import ImpersonationController from './impersonation/ImpersonationController';
import MainRoutes from './routes/mainRoutes';

function AppLayout() {
    const isCurrentPageAnonymous = useIsCurrentPageIdAnonymous();
    const shouldShowFooterMenu = useShouldShowFooterMenu();

    return (
        <div className={cn('relative w-full h-full  bg-white')}>
            {isCurrentPageAnonymous && (
                <ImpersonationController>
                    <MainRoutes />
                </ImpersonationController>
            )}
            {!isCurrentPageAnonymous && (
                <>
                    <div
                        className={cn('relative w-full h-full overflow-hidden', {
                            'pb-[66px]': shouldShowFooterMenu,
                        })}
                    >
                        <div className={cn('relative w-full h-full  bg-white')}>
                            <ImpersonationController>
                                <MainRoutes />
                            </ImpersonationController>
                        </div>
                    </div>
                    {shouldShowFooterMenu && <FooterMenu />}
                </>
            )}
        </div>
    );
}
export default AppLayout;

import { LogoutIcon, PrivacyIcon, SupportIcon } from '@/components/svgs';
import { CustomPopover, PopoverMenu } from '@/components/ui';
import HamburgerButton from '@/components/ui/button/hamburgerButton';
import useOpenDialog from '@/dialogs/useOpenDialog';
import { signOutFirebase } from '@/firebase';
import { DialogName, SupportTriggerSection } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { headerMenu as strings } from '@/strings';

function openLinkInNewWindow(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer');
}

const PRIVACY_POLICY_URL = 'https://www.carefam.com/privacy-policy';

export default function UserMenu() {
    const { openDialog: openSupportDialog } = useOpenDialog(DialogName.Support);
    const onSignout = () => {
        trackEvent(MixpanelEvent.SignOut);
        signOutFirebase();
    };
    return (
        <CustomPopover
            onOpenCallback={() => {
                trackEvent(MixpanelEvent.OpenUserMenu);
            }}
            trigger={<HamburgerButton />}
        >
            <PopoverMenu
                items={[
                    {
                        id: 'support',
                        content: {
                            label: strings.menu.support,
                            Icon: SupportIcon,
                        },
                        onClick: () => {
                            trackEvent(MixpanelEvent.UserMenuClickSupport);
                            openSupportDialog({
                                triggerSection: SupportTriggerSection.SupportButton,
                            });
                        },
                    },
                    {
                        id: 'privacy',
                        content: {
                            label: strings.menu.privacyPolicy,
                            Icon: PrivacyIcon,
                        },
                        onClick: () => {
                            trackEvent(MixpanelEvent.UserMenuClickPrivacyPolicy);
                            openLinkInNewWindow(PRIVACY_POLICY_URL);
                        },
                        dontClose: true,
                    },
                    {
                        id: 'logout',
                        content: {
                            label: strings.menu.logout,
                            Icon: LogoutIcon,
                        },
                        onClick: onSignout,
                    },
                ]}
            />
        </CustomPopover>
    );
}

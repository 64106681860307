import { useDialogContext } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';

import SelectOptionView from './SelectOptionView';
import { SelectContentProps } from './types';

function SelectContent<T extends string>({
    options,
    onChange,
    selectionCheck,
}: SelectContentProps<T>) {
    const { isExpandedToTop } = useDialogContext();
    return (
        <div className={cn({ 'overflow-auto': isExpandedToTop })}>
            {options.map((opt) => (
                <SelectOptionView
                    key={opt.id}
                    value={opt.value}
                    label={opt.label}
                    isSelected={selectionCheck(opt.value)}
                    onClick={onChange}
                />
            ))}
        </div>
    );
}

export default SelectContent;

import { CheckboxWithLabel, EditableTextField } from '@/components/ui';
import { cn, stringToDigitsOnly } from '@/lib/utils';
import { profile as strings } from '@/strings';
import { Controller } from 'react-hook-form';

import { SalaryInputProps, SalaryObject } from './types';
import { hasSalaryChange } from './utils';
import { validateSalary } from './validators';

function SalaryInput({
    control,
    state,
    originalState,
    setValue,
    error,
    trackInputEdit,
}: SalaryInputProps) {
    const indicateError = !!error?.message;
    const hasChange = hasSalaryChange(state, originalState);

    return (
        <Controller
            control={control}
            name='hourlySalary'
            rules={{
                validate: (value: SalaryObject) => {
                    return validateSalary(value, originalState);
                },
            }}
            render={() => (
                <EditableTextField
                    originalValue={originalState.value}
                    type='tel'
                    value={state.flexible ? '' : state.value}
                    placeholder={
                        state.flexible ? strings.hourlySalary.flexibleSalaryLabel : ''
                    }
                    setValue={(v) =>
                        setValue('hourlySalary', {
                            value: stringToDigitsOnly(v),
                            flexible: stringToDigitsOnly(v) === '',
                        })
                    }
                    error={error?.message}
                    label={strings.hourlySalary.label}
                    hasChangeOverride={hasChange}
                    extraInputs={
                        <CheckboxWithLabel
                            rootProps={{ className: cn('pt-1') }}
                            label={strings.hourlySalary.flexibleSalaryLabel}
                            hasError={indicateError}
                            checked={state.flexible}
                            onCheckedChange={(v) =>
                                setValue('hourlySalary', { ...state, flexible: !!v })
                            }
                        />
                    }
                    onEditStateChange={(bool) => trackInputEdit(bool)}
                    suffixElement={
                        state.flexible ? null : (
                            <p className={cn('text-14 whitespace-nowrap')}>
                                {strings.hourlySalary.inputSuffix}
                            </p>
                        )
                    }
                    prefixElement={
                        state.flexible ? null : <div className={cn('pr-[2px]')}>$</div>
                    }
                />
            )}
        />
    );
}

export default SalaryInput;

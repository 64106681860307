import { Logo } from '@/components/svgs';
import { ScreeningFlowState, useScreeningState } from '@/introCall';
import { cn } from '@/lib/utils';
import { headerMenu as strings } from '@/strings';
import UserMenu from '@/userMenu/UserMenu';

import { HomePageStatus } from './types';
import useHomePageStatus from './useHomePageState';

const getStatusText = (status: HomePageStatus, screeningState: ScreeningFlowState) => {
    switch (status) {
        case HomePageStatus.SearchingFacilities:
            return strings.status.searching;
        case HomePageStatus.NoProcesses:
            return strings.status.noProcesses;
        case HomePageStatus.NoMoreProcesses:
            return strings.status.noProcesses;
        case HomePageStatus.Active:
            switch (screeningState) {
                case ScreeningFlowState.Pending:
                    return strings.status.pendingActivation;
                default:
                    return '';
            }
        default:
            return '';
    }
};

export default function HomepageHeader() {
    const status = useHomePageStatus();
    const screeningState = useScreeningState();
    return (
        <div className={cn('w-full flex items-center justify-between ')}>
            <Logo className={cn('h-6')} />
            <div className={cn('text-12 font-semibold')}>
                {getStatusText(status, screeningState)}
            </div>
            <UserMenu />
        </div>
    );
}

import { PageLoader } from '@/components/ui';
import useProcesses from '@/fetchers/useProcesses';
import { ProcessSection } from '@/lib/types';
import { cn, getProcessSection, valuesOf } from '@/lib/utils';
import ActiveProcessesView from '@/processes/ActiveProcessesView';

import NewProcessesView from './NewProcessesView';

function ProcessesView() {
    const { isReady, data: processes } = useProcesses();
    const newProcesses = valuesOf(processes).filter(
        (p) => getProcessSection(p.status) === ProcessSection.New,
    );
    const activeProcesses = valuesOf(processes).filter((p) =>
        [ProcessSection.Interview, ProcessSection.Offer].includes(
            getProcessSection(p.status),
        ),
    );
    return (
        <>
            {!isReady && <PageLoader className={cn('h-40')} />}
            {isReady && (
                <>
                    {activeProcesses.length > 0 && (
                        <div className={cn('pt-10')}>
                            <ActiveProcessesView processes={activeProcesses} />
                        </div>
                    )}
                    <div className={cn('pt-14')}>
                        <NewProcessesView processes={newProcesses} />
                    </div>
                </>
            )}
        </>
    );
}

export default ProcessesView;

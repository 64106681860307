import { BrowserRouter as Router } from 'react-router-dom';

import AppLayout from './AppLayout';
import AuthController from './auth/AuthController';
import { Toaster } from './components/ui';
import DevModeProvider from './devMode/DevModeProvider';
import DialogsProvider from './dialogs/dialogsProvider';
import MixpanelController from './mixpanel/MixpanelController';
import MixpanelPageTracker from './mixpanel/MixpanelPageTracker';

function App() {
    return (
        <Router>
            <DevModeProvider />
            <MixpanelController>
                <MixpanelPageTracker />
                <DialogsProvider>
                    <AuthController>
                        <AppLayout />
                    </AuthController>
                </DialogsProvider>
            </MixpanelController>
            <Toaster />
        </Router>
    );
}
export default App;

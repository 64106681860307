import { XIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

import { DialogHeaderAnchor } from './dialogContent';

function EmptyDialogHeader({ handleClose }: { handleClose: () => void }) {
    return (
        <div
            className={cn(
                'relative flex items-center justify-center py-6 px-4 border-b border-neutral100',
            )}
        >
            <DialogHeaderAnchor className={cn('p-0')} />
            <div className={cn('absolute top-0 right-4 h-full flex items-center ')}>
                <div
                    className={cn(
                        'h-8 w-8 flex items-center justify-center cursor-pointer',
                    )}
                    onClick={handleClose}
                >
                    <XIcon className={cn('w-4 h-4')} />
                </div>
            </div>
        </div>
    );
}
export default EmptyDialogHeader;

import { CurrentAuthUser } from '@/_selectors';
import { INTERNATIONAL_PREFIX, cn, formatInternationalPhoneNumber } from '@/lib/utils';
import { registration as strings } from '@/strings';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';

function RegisterSuccess() {
    const authUser = useSelector(CurrentAuthUser);
    const phone = authUser?.phoneNumber || '';
    const { setPreventGoBack } = useRegisterFormContext();

    useEffect(() => {
        setPreventGoBack(true);
        return () => {
            setPreventGoBack(false);
        };
    }, [setPreventGoBack]);
    const formattedPhone = phone.startsWith('+1')
        ? formatInternationalPhoneNumber({
              phone: phone.slice(2),
              prefix: INTERNATIONAL_PREFIX,
          })
        : phone;

    return (
        <RegistrationStepLayout canGoNext>
            <div className={cn('flex flex-col items-center')}>
                <div className={cn('w-full max-w-[300px]')}>
                    <StepTitle
                        title={strings.registerSuccess.title}
                        subtitle={strings.registerSuccess.subtitle.func(formattedPhone)}
                    />
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default RegisterSuccess;

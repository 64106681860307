import { Dialog, DialogHeaderAnchor } from '@/components/ui';
import { InAppSupportType } from '@/lib/api/v1';
import { SupportTriggerSection } from '@/lib/types';
import { dialogs as dialogStrings } from '@/strings';
import { useEffect, useState } from 'react';

import SuccessContent from '../common/SuccessContent';
import DialogTitleHeader from '../common/header';
import { BaseDialogPropsCommon } from '../types';
import TalkToUsContent from './TalkToUsContent';

const strings = dialogStrings.talkToUs;

const triggerSectionToSectionName: Record<SupportTriggerSection, string> = {
    [SupportTriggerSection.SupportButton]: '"Talk To Us" Dialog',
    [SupportTriggerSection.OrientationIssues]: 'Issues with scheduled orientation',
};

enum DialogStep {
    Form = 'form',
    Success = 'success',
}

interface Props extends BaseDialogPropsCommon {
    triggerSection: SupportTriggerSection;
}

function SupportDialog({ isOpen, setOpen, triggerSection }: Props) {
    const [step, setStep] = useState(DialogStep.Form);

    useEffect(() => {
        return () => {
            if (!isOpen) {
                setStep(DialogStep.Form);
            }
        };
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            customHeader={
                <>
                    {step === DialogStep.Form && (
                        <DialogTitleHeader
                            title={strings.header.title}
                            subtitle={strings.header.subtitle}
                            rootProps={{ className: 'border-none' }}
                        />
                    )}
                    {step === DialogStep.Success && <DialogHeaderAnchor />}
                </>
            }
        >
            <>
                {step === DialogStep.Form && (
                    <TalkToUsContent
                        sectionName={triggerSectionToSectionName[triggerSection]}
                        submitCallback={() => setStep(DialogStep.Success)}
                        supportType={
                            triggerSection === SupportTriggerSection.OrientationIssues
                                ? InAppSupportType.IssuesWithScheduledOrientation
                                : undefined
                        }
                    />
                )}
                {step === DialogStep.Success && (
                    <SuccessContent
                        title={strings.successTitle}
                        subtitle={strings.successSubtitle}
                    />
                )}
            </>
        </Dialog>
    );
}

export default SupportDialog;

import {
    careSettingsStrings,
    genericToasts,
    jobTypeStrings,
    shiftStrings,
} from '../common';

const strings = {
    careSettings: {
        label: 'Care settings',
        values: { ...careSettingsStrings },
    },
    email: {
        label: 'Email',
    },
    jobTypes: {
        label: 'Job types',
        values: { ...jobTypeStrings },
    },
    shifts: {
        label: 'Shifts',
        values: { ...shiftStrings },
    },
    yearsOfExp: {
        label: 'Years of experience',
        inputSuffix: 'Years',
    },
    hourlySalary: {
        label: 'Desired hourly salary',
        inputSuffix: '/ Hr',
        flexibleSalaryLabel: 'Flexible salary',
    },
    hasCar: { label: 'Owns a car' },
    hasDrivingLicense: { label: 'Driving license' },
    name: { label: 'Name' },
    submit: {
        label: 'Save changes',
    },
    errors: {
        requiredField: 'Required',
        invalidNumber: 'Invalid number',
        invalidEmail: 'Invalid email address',
        invalidFullname: 'Please provide full name',
        yearsExperienceOutOfRange: 'Exceeds limit',
        SalaryOutOfRange: 'Exceeds limit',
        SalaryZero: 'Salary must be more than 0',
    },
    failureToast: {
        ...genericToasts.failure,
    },
};

export default strings;

import { Process } from '@/lib/types';
import { processes as strings } from '@/strings';

import ProcessStatusDisplay from './ProcessStatusDisplay';

function PostEventActions({ process }: { process: Process }) {
    return (
        <ProcessStatusDisplay
            substatusView={strings.subStatuses.pendingRecruiterFeedback}
            process={process}
        />
    );
}

export default PostEventActions;

import { AppDispatch } from '@/_helpers/store';
import { DialogName } from '@/lib/types';

import { DIALOG_ANIMATION_LENGTH } from './consts';
import dialogsSlice from './dialogsSlice';
import { DialogNameWithData, SetDialogDataPayload } from './types';

export const openDialog = (dialog: DialogName) => setDialogOpen(dialog, true);
export const closeDialog = (dialog: DialogName) => setDialogOpen(dialog, false);

export const openDialogDismissOthers =
    (...[dialog, data]: DialogNameWithData) =>
    (dispatch: AppDispatch) => {
        dispatch(closeAllDialogs());
        setTimeout(() => {
            if (data) {
                dispatch(
                    dialogsSlice.actions.setDialogData({
                        dialog,
                        data,
                    } as SetDialogDataPayload),
                );
            }
            dispatch(setDialogOpen(dialog, true));
        }, DIALOG_ANIMATION_LENGTH);
    };

export const reAnimateDialog =
    (dialog: DialogName, callback?: () => void) => (dispatch: AppDispatch) => {
        dispatch(closeDialog(dialog));
        setTimeout(() => {
            dispatch(openDialog(dialog));
            callback && callback();
        }, DIALOG_ANIMATION_LENGTH);
    };

export const closeAllDialogs = () => (dispatch: AppDispatch) => {
    dispatch(dialogsSlice.actions.closeAll());
};
export const setDialogOpen = (dialog: DialogName, open: boolean) => {
    return (dispatch: AppDispatch) => {
        dispatch(dialogsSlice.actions.setOpen({ dialog, open }));
    };
};

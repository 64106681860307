import { ClockIcon, LocationIcon } from '@/components/svgs';
import { ProcessStatus } from '@/lib/api/v1';
import { CurrentProcessActionItemType, Process } from '@/lib/types';
import { cn, getAlternativeAddress } from '@/lib/utils';
import ProcessStatusBadge from '@/processes/ProcessStatusBadge';
import { processes as processesStrings } from '@/strings';

const strings = processesStrings.statusActionItem;

function StatusActionItemRow({
    process,
    actionItemType,
}: {
    actionItemType: CurrentProcessActionItemType;
    process: Process;
}) {
    const alternativeAddress = getAlternativeAddress(process);
    const showAlternativeAddress =
        !!alternativeAddress &&
        actionItemType === CurrentProcessActionItemType.EventScheduled;
    return (
        <div className={cn('relative w-full px-10')}>
            <div className={cn('relative w-full max-w-[550px] mx-auto')}>
                <div
                    className={cn(
                        'relative w-full p-5 rounded-xl border border-neutral100',
                        'flex items-center justify-between gap-4',
                    )}
                >
                    <div className={cn('flex flex-col items-start gap-4')}>
                        <div className={cn('flex items-center gap-2')}>
                            {actionItemType ===
                                CurrentProcessActionItemType.EventScheduled && (
                                <ClockIcon
                                    className={cn('shrink-0', {
                                        'w-4 h-4': showAlternativeAddress,
                                    })}
                                />
                            )}
                            <div
                                className={cn('text-12 font-semibold', {
                                    'text-11-14 font-medium': showAlternativeAddress,
                                })}
                            >
                                {actionItemType ===
                                    CurrentProcessActionItemType.EventScheduled &&
                                    process.relevantEvent &&
                                    strings.pendingEvent.func(
                                        new Date(process.relevantEvent.startAt),
                                    )}
                                {actionItemType ===
                                    CurrentProcessActionItemType.PendingPaperwork &&
                                    strings.pendingPaperwork}
                                {actionItemType ===
                                    CurrentProcessActionItemType.RecruiterFeedbackRequired &&
                                    strings.pendingRecruiterFeedback}
                                {actionItemType ===
                                    CurrentProcessActionItemType.EventRequested &&
                                    (process.status === ProcessStatus.Orientation
                                        ? strings.pendingOrientationDate
                                        : strings.pendingInterviewScheduling)}
                            </div>
                        </div>
                        {showAlternativeAddress && (
                            <div className={cn('flex items-center gap-1')}>
                                <LocationIcon className={cn('w-4 h-4 shrink-0')} />
                                <div className={cn('text-11-14 font-medium')}>
                                    {alternativeAddress}
                                </div>
                            </div>
                        )}
                    </div>
                    <ProcessStatusBadge process={process} />
                </div>
                {showAlternativeAddress && (
                    <div className={cn('text-red200 font-medium text-12 mt-4')}>
                        <span className={cn('font-bold')}>
                            {strings.alternativeLocationNote.label}
                        </span>{' '}
                        <span>
                            {strings.alternativeLocationNote.content.func(
                                process.status,
                            )}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
export default StatusActionItemRow;

import { CheckboxWithLabel, DateInput, InnerDateType, TextInput } from '@/components/ui';
import { OnboardingQuestion } from '@/lib/types';
import { cn } from '@/lib/utils';
import { registration as registrationStrings } from '@/strings';

import { debouncedTrackUpdateAnswerEvent } from '../../utils';

const strings = registrationStrings.resume.lastExperience;

function LastWorkPlaceForm({
    company,
    setCompany,
    role,
    setRole,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateError,
    setDateError,
    isCurrentJob,
    setIsCurrentJob,
}: {
    company: string;
    setCompany: (val: string) => void;
    role: string;
    setRole: (val: string) => void;
    startDate: Date | null;
    setStartDate: (val: Date | null) => void;
    endDate: Date | null;
    setEndDate: (val: Date | null) => void;
    dateError: string;
    setDateError: (val: string) => void;
    isCurrentJob: boolean;
    setIsCurrentJob: (val: boolean) => void;
}) {
    return (
        <div className={cn('w-full flex flex-col gap-10 pb-4')}>
            <TextInput
                label={strings.companyLabel}
                placeholder={strings.companyPlaceholder}
                value={company}
                setValue={setCompany}
            />
            <TextInput
                label={strings.roleLabel}
                placeholder={strings.rolePlaceholder}
                value={role}
                setValue={setRole}
            />
            <DateInput
                value={startDate}
                setValue={setStartDate}
                label={strings.startDateLabel}
                endDate={{
                    value: endDate,
                    setValue: setEndDate,
                    label: strings.endDateLabel,
                }}
                error={dateError}
                setError={setDateError}
                withEndDate={!isCurrentJob}
                trackTextChange={(type, value) =>
                    debouncedTrackUpdateAnswerEvent({
                        question:
                            type === InnerDateType.Start
                                ? OnboardingQuestion.RecentJobStart
                                : OnboardingQuestion.RecentJobEnd,
                        answer: value,
                    })
                }
            />
            <div className={cn('inline-block')}>
                <CheckboxWithLabel
                    checked={isCurrentJob}
                    onCheckedChange={setIsCurrentJob}
                    label={strings.isCurrentJobLabel}
                    rootProps={{ className: cn('gap-3') }}
                    labelProps={{ className: cn('text-15 font-normal') }}
                />
            </div>
        </div>
    );
}

export default LastWorkPlaceForm;

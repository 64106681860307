import { IsLoggedIn } from '@/_selectors';
import useGoTo from '@/hooks/useGoTo';
import { OnboardingFormStep } from '@/lib/types';
import { formattedPhoneToInternationalNumber } from '@/lib/utils';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useRegisterFormContext } from '../RegisterContext';
import CodeVerificationForm from './registration/CodeVerificationForm';
import RegisterSuccess from './registration/RegisterSuccess';

function CodeVerificationStep() {
    const {
        phoneNumber,
        sendPhoneCode,
        submitConfirmationCode,
        hasSentConfirmationCode,
        currentStep,
        currentStepIndex,
    } = useRegisterFormContext();
    const isLoggedIn = useSelector(IsLoggedIn);
    const { goToRegister } = useGoTo();

    const resendCode = useCallback(async () => {
        const internationalPhoneNumber =
            formattedPhoneToInternationalNumber(phoneNumber);
        await sendPhoneCode(internationalPhoneNumber);
    }, [sendPhoneCode, phoneNumber]);

    useEffect(() => {
        if (
            !hasSentConfirmationCode &&
            !isLoggedIn &&
            currentStep === OnboardingFormStep.CodeVerify
        ) {
            goToRegister(currentStepIndex - 1);
        }
    }, [
        hasSentConfirmationCode,
        isLoggedIn,
        currentStep,
        currentStepIndex,
        goToRegister,
    ]);

    return (
        <>
            {isLoggedIn && <RegisterSuccess />}
            {!isLoggedIn && (
                <CodeVerificationForm
                    submit={submitConfirmationCode}
                    resendCode={resendCode}
                    phone={phoneNumber}
                />
            )}
        </>
    );
}

export default CodeVerificationStep;

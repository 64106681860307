import Loader from '@/components/lotties/Loader';
import { VIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as React from 'react';

const Switch = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
        loading?: boolean;
    }
>(({ className, loading, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={cn(
            'peer inline-flex h-10 w-20 shrink-0 cursor-pointer items-center rounded-full',
            'transition-colors focus-visible:outline-none focus-visible:ring-0',
            'disabled:cursor-not-allowed disabled:opacity-50',
            'data-[state=checked]:bg-primary data-[state=unchecked]:bg-neutral200',
            { 'disabled:opacity-100': loading },
            className,
        )}
        {...props}
        disabled={props.disabled || loading}
        ref={ref}
    >
        <SwitchPrimitives.Thumb
            className={cn(
                'pointer-events-none block h-8 w-8 rounded-full bg-white ring-0',
                'group transition-all',
                'data-[state=checked]:bg-white data-[state=unchecked]:bg-neutral300',
                'data-[state=checked]:translate-x-11 data-[state=unchecked]:translate-x-1',
                'flex items-center justify-center',
            )}
        >
            <Loader
                className={cn(
                    'h-5 w-5',
                    'transition-opacity duration-300',
                    'data-[state=hidden]:opacity-0 data-[state=shown]:opacity-100',
                )}
                variant={props.checked ? 'primary' : 'default'}
                data-state={loading ? 'shown' : 'hidden'}
            />
            <VIcon
                className={cn(
                    'absolute stroke-primary fill-primary w-auto h-2',
                    'transition-opacity duration-300',
                    'data-[state=unchecked]:opacity-0 data-[state=checked]:opacity-100',
                )}
                data-state={props.checked && !loading ? 'checked' : 'unchecked'}
            />
        </SwitchPrimitives.Thumb>
    </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };

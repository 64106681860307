import { anonymousPageIds } from '@/lib/types';
import { routesMap } from '@/lib/utils';
import { LoginPage } from '@/pages';
import { Route, Routes } from 'react-router-dom';

import { anonymousPageIdToView } from './pages';

function AnonymousRoutes() {
    return (
        <Routes>
            {anonymousPageIds.map((pageId) => {
                const path = routesMap[pageId];
                return (
                    <Route
                        key={path}
                        path={path}
                        element={anonymousPageIdToView[pageId]}
                    />
                );
            })}
            <Route path='*' element={<LoginPage />} />
        </Routes>
    );
}

export default AnonymousRoutes;

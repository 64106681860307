import Select from './select';
import { MultiSelectProps } from './types';
import { multiValueToStringVal, toggleOptionValue } from './utils';

function MultiSelect<T extends string>({
    value: arrayValue,
    onChange,
    ...props
}: MultiSelectProps<T>) {
    const isSelectedFunc = (val: T) => arrayValue.includes(val);
    const toggleChange = (val: T) => toggleOptionValue(val, arrayValue, onChange);

    return (
        <Select
            value={multiValueToStringVal(arrayValue)}
            onChange={toggleChange}
            selectionCheck={isSelectedFunc}
            {...props}
            closeAfterChange={false}
        />
    );
}

export default MultiSelect;

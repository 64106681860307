import { cn } from '@/lib/utils';

interface Props {
    dayInMonth: number;
    isAvailable: boolean;
    onClick: () => void;
}

function CalendarDay({ dayInMonth, isAvailable, onClick }: Props) {
    return (
        <div
            onClick={isAvailable ? onClick : undefined}
            className={cn('rounded-full w-14 h-14 flex items-center justify-center', {
                'bg-primary cursor-pointer': isAvailable,
            })}
        >
            <div className={cn('text-14 font-medium', { 'text-white': isAvailable })}>
                {dayInMonth}
            </div>
        </div>
    );
}

export default CalendarDay;

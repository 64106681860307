import { composeEventHandlers } from '@radix-ui/primitive';
import { KeyboardEvent } from 'react';

import { entriesOf } from './typescript';

type KeyHandlerFunction<T> = (event: KeyboardEvent<T>) => void;

export enum KeyboardKey {
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    Enter = 'Enter',
    Escape = 'Escape',
    Space = ' ',
    Backspace = 'Backspace',
    Tab = 'Tab',
    Shift = 'Shift',
    Control = 'Control',
    Alt = 'Alt',
    Meta = 'Meta',
}

export function handleKeyDown<T extends HTMLElement>(
    event: KeyboardEvent<T>,
    keys: KeyboardKey,
    action: KeyHandlerFunction<T>,
): void {
    if (event.key === keys) {
        action(event);
    }
}

const generateKeyHandler = <T extends HTMLElement>(key: KeyboardKey) => {
    return (action: KeyHandlerFunction<T>) => {
        return {
            onKeyDown: (e: KeyboardEvent<T>) => handleKeyDown(e, key, action),
        };
    };
};

export const generateKeyHandlers = <T extends HTMLElement>(
    keyActions: Partial<Record<KeyboardKey, KeyHandlerFunction<T>>>,
) => {
    const handlers = entriesOf(keyActions).map(
        ([key, action]) =>
            (e: KeyboardEvent<T>) =>
                handleKeyDown(e, key, action),
    );
    return {
        onKeyDown: composeEventHandlers(...handlers),
    };
};

export const onEnterHandler = generateKeyHandler(KeyboardKey.Enter);
export const onArrowUpHandler = generateKeyHandler(KeyboardKey.ArrowUp);
export const onArrowDownHandler = generateKeyHandler(KeyboardKey.ArrowDown);

import { CurrentAuthUser, ImpersonatedUser } from '@/_selectors';
import { SentryContext } from '@/lib/types';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function useSetSentryAuthData() {
    const impersonatedUser = useSelector(ImpersonatedUser);
    const currentAuthUser = useSelector(CurrentAuthUser);

    const { id, name, phoneNumber } = currentAuthUser ?? {
        id: undefined,
        name: undefined,
        phoneNumber: undefined,
    };

    useEffect(() => {
        if (id) {
            Sentry.setUser({
                id: id,
                phoneNumber,
                ...(name ? { name } : {}),
            });
        }
    }, [id, name, phoneNumber]);

    useEffect(() => {
        if (impersonatedUser) {
            Sentry.setContext(SentryContext.ImpersonatedUser, impersonatedUser);
            Sentry.setTag('impersonated_user', impersonatedUser.phoneNumber);
        }
    }, [impersonatedUser]);
}

export default useSetSentryAuthData;

import { CurrentAuthUser } from '@/_selectors';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MixpanelEvent, trackEvent } from './events';
import { isMixpanelOn } from './utils';

function useIdentifyMixpanelUser() {
    const user = useSelector(CurrentAuthUser);
    const { name: nameOfCurrentUser, phoneNumber } = user ?? {};

    useEffect(() => {
        if (!isMixpanelOn() || !phoneNumber) {
            return;
        }
        trackEvent(MixpanelEvent.Login, { phoneNumber });
        mixpanel.identify(phoneNumber);
        mixpanel.people.set_once({ $phone: phoneNumber });
        trackEvent(MixpanelEvent.Login, { phoneNumber });
    }, [phoneNumber]);

    useEffect(() => {
        if (!isMixpanelOn() || !nameOfCurrentUser) {
            return;
        }
        mixpanel.people.set({ $name: nameOfCurrentUser });
    }, [nameOfCurrentUser]);
}

export default useIdentifyMixpanelUser;

import Logger from '@/_helpers/logger';
import { AppDispatch, GetRootState } from '@/_helpers/store';
import { CurrentUserId } from '@/_selectors';
import apiClient from '@/lib/api';
import { AppStateUpdatePayload } from '@/lib/types';

import appStateSlice, { fetchCandidateAppStateThunk } from './appState';

export const updateCandidateAppState =
    (params: AppStateUpdatePayload) =>
    async (dispatch: AppDispatch, getState: GetRootState) => {
        const candidateId = CurrentUserId(getState());
        if (!candidateId) {
            Logger.error('Tried to update candidate app state without a candidate ID');
            return;
        }
        const response = await apiClient.PATCH(
            '/api/v1/candidate-app-state/{candidate_id}',
            {
                params: {
                    path: { candidate_id: candidateId },
                },
                body: { ...params },
            },
        );
        dispatch(appStateSlice.actions.update(response.data!));
    };
export const fetchCandidateAppState =
    () => (dispatch: AppDispatch, getState: GetRootState) => {
        const candidateId = CurrentUserId(getState());
        if (!candidateId) {
            Logger.error('Tried to fetch candidate app state without a candidate ID');
            return;
        }
        dispatch(fetchCandidateAppStateThunk({ candidateId }));
    };

export const resetCandidateAppState = () => (dispatch: AppDispatch) => {
    dispatch(appStateSlice.actions.reset());
};

export enum CalculatedProcessStatus {
    New = 'new',
    PhoneInterview = 'phoneInterview',
    InPersonInterview = 'inPersonInterview',
    Paperwork = 'paperwork',
    Orientation = 'orientation',
    Terminated = 'terminated',
    Hired = 'hired',
}

export enum ComputedProcessStep {
    New = 'new',
    InterviewRequested = 'interviewRequested',
    PendingOrientationDate = 'pendingOrientationDate',
    ScheduledEvent = 'scheduledEvent',
    PostEvent = 'postEvent',
    Paperwork = 'paperwork',
    Terminated = 'terminated',
    Hired = 'hired',
}

export enum ProcessSection {
    Offer = 'offer',
    Interview = 'interview',
    New = 'new',
    Done = 'done',
}

import { SuccessLottie } from '@/components/lotties';
import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

export function SuccessContent({
    title,
    subtitle,
    customIcon,
}: {
    title: string;
    subtitle: string;
    customIcon?: ReactNode;
}) {
    return (
        <div
            className={cn(
                'px-8 py-24 h-full w-full flex flex-col gap-10 items-center justify-center',
            )}
        >
            {customIcon}
            {!customIcon && <SuccessLottie className={cn('w-[200px] h-fit')} />}
            <div className={cn('flex flex-col text-center gap-4')}>
                <div className={cn('text-20 font-bold')}>{title}</div>
                <div className={cn('text-15 font-medium')}>{subtitle}</div>
            </div>
        </div>
    );
}

export default SuccessContent;

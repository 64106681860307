import config from '@/config';
import { Env } from '@/lib/types';

export function isMixpanelOn() {
    if (config.ENV === Env.Dev && !config.DEV_TEST_MIXPANEL_ON_DEV) {
        return false;
    }
    return true;
}

export function sendEventToLog() {
    if (config.ENV === Env.Dev && !config.DEV_PREVENT_LOG_MIXPANEL_ON_DEV) {
        return true;
    }
    return false;
}

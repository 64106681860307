import Logger from '@/_helpers/logger';
import useSessionSetting from '@/hooks/useSessionSetting';
import apiClient from '@/lib/api';
import { useCallback } from 'react';

import useRegisterData from '../../useRegisterData';

function useReRegisterCandidate() {
    const { setVal: setRegisteredCandidate } = useSessionSetting(
        'reregisteredCandidate',
    );
    const { setVal: setIsRegistering } = useSessionSetting('isReregistering');
    const registerData = useRegisterData();

    const reregister = useCallback(async () => {
        setIsRegistering(true);
        try {
            const response = await apiClient.POST('/api/v1/candidates/reregistration', {
                body: registerData,
            });
            setRegisteredCandidate(response.data!);
        } catch (e) {
            Logger.error(e, 'Failed to re-register');
        } finally {
            setIsRegistering(false);
        }
    }, [registerData, setIsRegistering, setRegisteredCandidate]);

    return reregister;
}

export default useReRegisterCandidate;

import { DialogContentWithFooter, LoadingButton } from '@/components/ui';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { dialogs as dialogStrings } from '@/strings';
import { useState } from 'react';

const strings = dialogStrings.cancelInterview;

interface Props {
    cancelEvent: (reason?: string) => Promise<void>;
}

function CancelationForm({ cancelEvent }: Props) {
    const [reason, setReason] = useState('');
    const submitCancel = async () => await cancelEvent(reason);
    return (
        <DialogContentWithFooter
            footer={
                <div className={cn('flex justify-center')}>
                    <LoadingButton
                        className={cn('w-full max-w-[400px]')}
                        variant={'danger'}
                        onClick={submitCancel}
                    >
                        {strings.cancelation.submitButton}
                    </LoadingButton>
                </div>
            }
            bodyProps={{ className: cn('p-0') }}
        >
            <div className={cn('relative w-full')}>
                <div className={cn('p-8 pt-0')}>
                    <Textarea
                        className={cn('h-[150px] resize-none')}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder={strings.cancelation.reasonPlaceholder}
                        tabIndex={-1}
                    />
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default CancelationForm;

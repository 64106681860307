import { CustomPopover, FalafelButton } from '@/components/ui';
import EventLayout from '@/events/EventLayout';
import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';
import { dateToDayInMonthShort, dateToShortMonthName } from '@/strings/common';
import { ReactNode } from 'react';

import EventDetailsView from './EventDetailsView';

interface Props {
    title: string;
    startAt: Date;
    endAt: Date;
    popoverMenu: ReactNode;
    address?: string;
    rootProps?: DefaultDivProps;
    onMenuOpen?: () => void;
}

function EventView({
    title,
    startAt,
    endAt,
    popoverMenu,
    rootProps,
    onMenuOpen,
    address,
}: Props) {
    return (
        <EventLayout
            date={
                <div
                    className={cn(
                        'h-full w-full bg-accent300 flex flex-col items-center justify-center gap-2',
                    )}
                >
                    <div className={cn('text-26 leading-[1] font-medium')}>
                        {dateToDayInMonthShort(startAt)}
                    </div>
                    <div className={cn('text-11 leading-[1] font-semibold')}>
                        {dateToShortMonthName(startAt)}
                    </div>
                </div>
            }
            header={<div className={cn('text-14 font-bold')}>{title}</div>}
            description={
                <EventDetailsView startAt={startAt} endAt={endAt} address={address} />
            }
            menu={
                popoverMenu ? (
                    <CustomPopover
                        onOpenCallback={onMenuOpen}
                        trigger={<FalafelButton />}
                    >
                        {popoverMenu}
                    </CustomPopover>
                ) : null
            }
            rootProps={rootProps}
        />
    );
}

export default EventView;

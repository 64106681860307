import { Dialog } from '@/components/ui/dialog';
import { DialogHeaderAnchor } from '@/components/ui/dialog/';
import useSetOpen from '@/hooks/useSetOpen';
import { cn } from '@/lib/utils';

import SelectContentView from './SelectContentView';
import { SelectBase, SelectTrigger, SelectValue } from './base';
import { SelectProps } from './types';
import { toSelectValue } from './utils';

function Select<T extends string>({
    options,
    value,
    open: externalOpen,
    onOpenChange: externalSetOpen,
    onChange,
    placeholder,
    triggerProps,
    selectionCheck,
    closeAfterChange = true,
    ...baseProps
}: SelectProps<T>) {
    const { handleClose, open, setOpen } = useSetOpen(externalOpen, externalSetOpen);

    const isSelectedFunc = selectionCheck ? selectionCheck : (val: T) => val === value;
    const selectedOption = !Array.isArray(value)
        ? options.find((opt) => isSelectedFunc(opt.value))
        : undefined;

    const handleChange = (val: T) => {
        onChange(val);
        if (closeAfterChange) {
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            setOpen={setOpen}
            triggerProps={{ asChild: true }}
            customHeader={<DialogHeaderAnchor />}
            trigger={
                <div>
                    <SelectBase value={toSelectValue(value)} {...baseProps}>
                        <SelectTrigger
                            {...triggerProps}
                            className={cn(
                                'w-[400px] max-w-full',
                                triggerProps?.className,
                            )}
                        >
                            {selectedOption ? (
                                <div>{selectedOption.label}</div>
                            ) : (
                                <SelectValue placeholder={placeholder} />
                            )}
                        </SelectTrigger>
                    </SelectBase>
                </div>
            }
        >
            <SelectContentView
                options={options}
                onChange={handleChange}
                selectionCheck={isSelectedFunc}
            />
        </Dialog>
    );
}

export default Select;

import { cn } from '@/lib/utils';

import ProcessesView from './ProcessesView';
import ScreeningSection from './screeningEvent/ScreeningSection';

function ActiveCandidateView() {
    return (
        <div className={cn('w-full h-full flex flex-col items-stretch')}>
            <ScreeningSection />
            <div className={cn('relative flex-grow w-full')}>
                <ProcessesView />
            </div>
        </div>
    );
}

export default ActiveCandidateView;

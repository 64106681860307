import { CalendarIcon, TerminateIcon, XIcon } from '@/components/svgs';
import {
    CustomPopover,
    DialogHeaderAnchor,
    HorizontalFalafelButton,
    PopoverMenu,
} from '@/components/ui';
import { useDialogContext, useIsScrollOnTopTracker } from '@/components/ui/dialog';
import { ProcessAction } from '@/lib/api/v1';
import {
    DialogName,
    Process,
    ScheduleInterviewDialogVariant,
    TerminationDialogVariant,
} from '@/lib/types';
import { canRescheduleProcessEvent, cn } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    extractProcessEventDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { processes as strings } from '@/strings';

import useOpenDialog from '../useOpenDialog';
import { FULL_HEADER_HEIGHT } from './consts';

export function ProcessHeader({ process }: { process: Process }) {
    const canTerminate = process.actions.includes(ProcessAction.TerminateProcess);
    const canCancelEvent = process.actions.includes(ProcessAction.CancelEvent);
    const canReschedule = canRescheduleProcessEvent(process);
    const { openDialog: openTerminationDialog } = useOpenDialog(
        DialogName.TerminateProcess,
    );
    const { openDialog: openScheduleInterviewDialog } = useOpenDialog(
        DialogName.ScheduleInterview,
    );
    const { openDialog: openCancelInterviewDialog } = useOpenDialog(
        DialogName.CancelInterview,
    );

    const { isExpandedToTop } = useDialogContext();
    const { isScrollTop } = useIsScrollOnTopTracker();
    return (
        <div className={cn('cursor-grab')}>
            <div
                className={cn('relative pb-6 z-10')}
                style={{
                    backgroundColor: process.organization.brandColor ?? undefined,
                }}
            >
                <DialogHeaderAnchor anchorProps={{ className: cn('bg-white') }} />
            </div>
            <div
                className={cn({
                    'absolute w-full': isExpandedToTop,
                })}
            >
                <div
                    className={cn('relative px-3')}
                    style={{
                        backgroundColor: process.organization.brandColor ?? undefined,
                        height: FULL_HEADER_HEIGHT,
                    }}
                >
                    <div className={cn('relative w-full h-full')}>
                        <div
                            className={cn('absolute bottom-0 w-full pb-14 px-3 z-0', {
                                'z-10': isExpandedToTop && isScrollTop,
                            })}
                            style={{
                                backgroundColor:
                                    process.organization.brandColor ?? undefined,
                            }}
                        >
                            <div className={cn('relative flex justify-center')}>
                                <div
                                    className={cn(
                                        'h-[100px] w-[100px] rounded-full overflow-hidden flex items-center justify-center bg-white pointer-events-none select-none',
                                    )}
                                >
                                    <img
                                        src={process.organization.logoUrl ?? undefined}
                                        alt='icon'
                                        className={cn('max-h-24 max-w-24 h-auto w-auto')}
                                    />
                                </div>
                                {(canTerminate || canCancelEvent || canReschedule) && (
                                    <CustomPopover
                                        trigger={
                                            <HorizontalFalafelButton
                                                className={cn('absolute top-0 right-6')}
                                            />
                                        }
                                        onOpenCallback={() => {
                                            trackEvent(
                                                MixpanelEvent.ProcessCardOpenActionsMenu,
                                            );
                                        }}
                                    >
                                        <PopoverMenu
                                            items={[
                                                ...(canReschedule
                                                    ? [
                                                          {
                                                              id: 'reschedule',
                                                              content: {
                                                                  label: strings
                                                                      .eventActions
                                                                      .rescheduleInterview,
                                                                  Icon: CalendarIcon,
                                                              },
                                                              onClick: () => {
                                                                  if (
                                                                      !process.relevantEvent
                                                                  )
                                                                      return;
                                                                  trackEvent(
                                                                      MixpanelEvent.UpcomingEventActionsClickRescheduleInterview,
                                                                      extractProcessEventDataForMixpanel(
                                                                          process,
                                                                          process.relevantEvent,
                                                                      ),
                                                                  );
                                                                  openScheduleInterviewDialog(
                                                                      {
                                                                          processId:
                                                                              process.id,
                                                                          variant:
                                                                              ScheduleInterviewDialogVariant.Reschedule,
                                                                      },
                                                                  );
                                                              },
                                                          },
                                                      ]
                                                    : []),
                                                ...(canCancelEvent
                                                    ? [
                                                          {
                                                              id: 'cancel',
                                                              content: {
                                                                  label: strings
                                                                      .eventActions
                                                                      .cancelInterview,
                                                                  Icon: XIcon,
                                                                  iconProps: {
                                                                      className:
                                                                          cn('h-5'),
                                                                      strokeWidth: 0.5,
                                                                  },
                                                              },
                                                              onClick: () => {
                                                                  if (
                                                                      !process.relevantEvent
                                                                  )
                                                                      return;
                                                                  trackEvent(
                                                                      MixpanelEvent.UpcomingEventActionsClickCancelInterview,
                                                                      extractProcessEventDataForMixpanel(
                                                                          process,
                                                                          process.relevantEvent,
                                                                      ),
                                                                  );
                                                                  openCancelInterviewDialog(
                                                                      {
                                                                          processId:
                                                                              process.id,
                                                                      },
                                                                  );
                                                              },
                                                          },
                                                      ]
                                                    : []),
                                                ...(canTerminate
                                                    ? [
                                                          {
                                                              id: 'terminate',
                                                              content: {
                                                                  label: strings.actions
                                                                      .terminate,
                                                                  Icon: TerminateIcon,
                                                              },
                                                              onClick: () => {
                                                                  trackEvent(
                                                                      MixpanelEvent.ProcessActionsMenuClickTermination,
                                                                      {
                                                                          ...extractProcessDataForMixpanel(
                                                                              process,
                                                                          ),
                                                                      },
                                                                  );
                                                                  openTerminationDialog({
                                                                      processId:
                                                                          process.id,
                                                                      variant:
                                                                          TerminationDialogVariant.Termination,
                                                                  });
                                                              },
                                                          },
                                                      ]
                                                    : []),
                                            ]}
                                        />
                                    </CustomPopover>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessHeader;

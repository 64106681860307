import useLoadingAction from '@/hooks/useLoadingAction';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import RecaptchaStateManager from './RecaptchaManager';
import { getRecaptchaContainer, isRecaptchaVisible } from './utils';

function useRecaptchaVisibilityObserver({
    container,
    onVisibilityChange,
}: {
    container: Element | null;
    onVisibilityChange: (val: boolean) => void;
}) {
    const isOn = !!container;
    const [visible, setVisible] = useState(isOn ? isRecaptchaVisible(container) : false);
    const debouncedUpdateVisibility = useMemo(
        () =>
            debounce((newVal: boolean) => {
                if (visible !== newVal) {
                    setVisible(newVal);
                    onVisibilityChange(newVal);
                }
            }),
        [visible, onVisibilityChange, setVisible],
    );

    useEffect(() => {
        if (!isOn) {
            return;
        }
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    debouncedUpdateVisibility(isRecaptchaVisible(container));
                }
            }
        });

        observer.observe(container, {
            attributes: true,
            childList: true,
            subtree: true,
        });
        return () => {
            observer.disconnect();
            debouncedUpdateVisibility.cancel();
        };
    }, [container, isOn, debouncedUpdateVisibility]);

    useEffect(() => {
        if (isOn) {
            trackEvent(MixpanelEvent.AttachedRecaptchaToDom);
        }
    }, [isOn]);
}

function useRecaptchaObserver(onVisibilityChange: (val: boolean) => void) {
    const [recaptchaContainer, setRecaptchaContainer] = useState<Element | null>(null);
    useRecaptchaVisibilityObserver({
        onVisibilityChange,
        container: recaptchaContainer,
    });

    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const container = getRecaptchaContainer();
                    if (container) {
                        setRecaptchaContainer(container);
                        observer.disconnect();
                    }
                }
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        return () => {
            observer.disconnect();
        };
    }, []);
}

function useActionWithRecaptchaObserver<T>(fn: () => Promise<T>) {
    const { isLoading, setIsLoading, action } = useLoadingAction(fn);
    const onVisibilityChange = (visible: boolean) => {
        if (visible) {
            RecaptchaStateManager.shown();
        } else {
            RecaptchaStateManager.hidden(() => setIsLoading(false));
        }
    };
    useRecaptchaObserver(onVisibilityChange);

    return {
        action,
        isLoading,
    };
}

export default useActionWithRecaptchaObserver;

import {
    DialogName,
    SupportTriggerSection,
    TerminationDialogVariant,
} from '@/lib/types/dialogs';
import { createSelector } from 'reselect';

import { DialogsState } from './base';

export const DialogsOpenState = createSelector(DialogsState, (dialogs) => dialogs.open);

export const DialogsDataState = createSelector(DialogsState, (dialogs) => dialogs.data);

export const IsProcessDetailsDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.ProcessDetails],
);

export const ProcessDetailsDialogBaseData = createSelector(
    DialogsDataState,
    (dataState) => {
        const { processId } = dataState[DialogName.ProcessDetails] ?? {};
        return { processId };
    },
);

export const IsScheduleInterviewDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.ScheduleInterview],
);

export const ScheduleInterviewDialogBaseData = createSelector(
    DialogsDataState,
    (dataState) => {
        const { processId, variant } = dataState[DialogName.ScheduleInterview] ?? {};
        return { processId, variant };
    },
);

export const IsSupportDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.Support],
);

export const SupportDialogBaseData = createSelector(DialogsDataState, (dataState) => {
    const { triggerSection = SupportTriggerSection.SupportButton } =
        dataState[DialogName.Support] ?? {};
    return { triggerSection };
});

export const IsCancelInterviewDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.CancelInterview],
);

export const CancelInterviewDialogBaseData = createSelector(
    DialogsDataState,
    (dataState) => {
        const { processId } = dataState[DialogName.CancelInterview] ?? {};
        return { processId };
    },
);

export const IsTerminateProcessDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.TerminateProcess],
);

export const TerminateProcessDialogBaseData = createSelector(
    DialogsDataState,
    (dataState) => {
        const { processId, variant = TerminationDialogVariant.Termination } =
            dataState[DialogName.TerminateProcess] ?? {};
        return { processId, variant };
    },
);

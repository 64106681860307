import { Dialog } from '@/components/ui';
import { Process } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useEffect } from 'react';

import { BaseDialogPropsCommon } from '../types';
import ProcessDetailsDialogContent from './content';
import ProcessHeader from './header';

interface Props extends BaseDialogPropsCommon {
    process: Process;
}

function ProcessDetailsDialog({ process, isOpen, setOpen, handleClose }: Props) {
    useEffect(() => {
        if (!isOpen) {
            trackEvent(MixpanelEvent.CloseProcessCard);
        }
    }, [isOpen]);
    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            customHeader={<ProcessHeader process={process} />}
        >
            <ProcessDetailsDialogContent process={process} handleClose={handleClose} />
        </Dialog>
    );
}

export default ProcessDetailsDialog;

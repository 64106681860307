import { HamburgerIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

function HamburgerButton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            {...props}
            className={cn(
                'hover:bg-neutral100 cursor-pointer p-[10px] rounded-[4px]',
                'flex items-center justify-center',
                className,
            )}
        >
            <HamburgerIcon />
        </div>
    );
}

export default HamburgerButton;

import { TextInput } from '@/components/ui';
import { cn, isValidFullName } from '@/lib/utils';
import { registration as strings } from '@/strings';
import { useState } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function NameStep() {
    const { fullname, setFullname } = useRegisterFormContext();
    const [error, setError] = useState('');

    const updateName = (val: string) => {
        setFullname(val);
        setError('');
    };

    const verifier = () => {
        if (!isValidFullName(fullname)) {
            setError(strings.fullname.error);
            return false;
        }
        return true;
    };
    return (
        <RegistrationStepLayout canGoNext={!!fullname} verifier={verifier}>
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('w-full max-w-[350px] flex flex-col gap-8')}>
                    <StepTitle
                        title={strings.fullname.title}
                        subtitle={strings.fullname.subtitle}
                    />
                    <TextInput
                        value={fullname}
                        setValue={updateName}
                        placeholder={strings.fullname.placeholder}
                        error={error}
                        clearError={() => setError('')}
                        autoComplete='name'
                    />
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default NameStep;

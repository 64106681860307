import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';

function Dot({ ...props }: DefaultDivProps) {
    return (
        <div
            {...props}
            className={cn('w-[4px] h-[4px] rounded-full bg-white', props?.className)}
        />
    );
}

function HorizontalFalafelButton({
    className,
    dotProps,
    ...props
}: DefaultDivProps & {
    dotProps?: DefaultDivProps;
}) {
    return (
        <div
            {...props}
            className={cn(
                'rounded-md p-3 flex gap-[4px]',
                'cursor-pointer hover:bg-black/10',
                className,
            )}
        >
            <Dot {...dotProps} />
            <Dot {...dotProps} />
            <Dot {...dotProps} />
        </div>
    );
}

export default HorizontalFalafelButton;

import EventView from '@/events/EventView';
import { ProcessAction, ProcessStatus } from '@/lib/api/v1';
import { ISOtoDate } from '@/lib/base';
import { Process, ProcessEvent } from '@/lib/types';
import {
    canRescheduleProcessEvent,
    cn,
    getAlternativeAddress,
    getProcessDisplayName,
} from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessEventDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { statusPage as statusPageStrings } from '@/strings';

import OrientationEventActions from './OrientationEventActions';
import ProcessEventActions from './ProcessEventActions';

const strings = statusPageStrings.upcomingEvents;

function ProcessEventView({
    event,
    process,
}: {
    event: ProcessEvent;
    process: Process;
}) {
    const facilityName = getProcessDisplayName(process) ?? '';
    const startAt = ISOtoDate(event.startAt);
    const endAt = ISOtoDate(event.endAt);
    const isOrientation = process.status === ProcessStatus.Orientation;

    const alternativeAddress = getAlternativeAddress(process);

    const canCancelEvent = process.actions.includes(ProcessAction.CancelEvent);
    const canReschedule = canRescheduleProcessEvent(process);
    return (
        <EventView
            rootProps={{ className: cn('shadow-elevation-200') }}
            startAt={startAt}
            endAt={endAt}
            address={alternativeAddress ?? undefined}
            title={strings.eventTitle.func(event.eventType, facilityName)}
            onMenuOpen={() => {
                trackEvent(
                    MixpanelEvent.UpcomingEventOpenActionsMenu,
                    extractProcessEventDataForMixpanel(process, event),
                );
            }}
            popoverMenu={
                isOrientation ? (
                    <OrientationEventActions process={process} event={event} />
                ) : canCancelEvent || canReschedule ? (
                    <ProcessEventActions
                        process={process}
                        event={event}
                        canCancelEvent={canCancelEvent}
                        canRescheduleEvent={canReschedule}
                    />
                ) : null
            }
        />
    );
}

export default ProcessEventView;

import { useAppDispatch } from '@/_helpers/store';
import { setDialogOpen } from '@/_reducers/dialogs';
import { terminateProcess } from '@/_reducers/processes';
import { IsTerminateProcessDialogOpen, TerminateProcessDialogData } from '@/_selectors';
import { DialogName } from '@/lib/types';
import { useSelector } from 'react-redux';

import TerminateProcessDialog from './TerminateProcessDialog';
import { TerminateFunc } from './types';

function TerminateProcessDialogProvider() {
    const dispatch = useAppDispatch();
    const shouldBeOpen = useSelector(IsTerminateProcessDialogOpen);
    const { process, variant } = useSelector(TerminateProcessDialogData);
    const hasData = !!process;

    const isOpen = hasData && shouldBeOpen;

    const handleClose = () => setOpen(false);

    const setOpen = (open: boolean) => {
        dispatch(setDialogOpen(DialogName.TerminateProcess, open));
    };

    const terminate: TerminateFunc = async (params) => {
        return await dispatch(terminateProcess({ ...params, processId: process!.id }));
    };

    return (
        <>
            {hasData && (
                <TerminateProcessDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    variant={variant}
                    terminate={terminate}
                />
            )}
        </>
    );
}

export default TerminateProcessDialogProvider;

import useDigitsMaskInputVal from '@/hooks/useDigitsMaskInputVal';
import { cn } from '@/lib/utils';
import { ChangeEvent, useEffect } from 'react';

import { BaseInputElement } from '../textInput';
import { MonthAndYearInputProps } from './types';

const DELIMITER = ' / ';
const PLACEHOLDER = `MM${DELIMITER}YYYY`;
const MAX_LENGTH = 6;

function formatDigitsToTextVal(digitsValue: string) {
    const length = digitsValue.length;
    if (length < 2) return digitsValue;
    return `${digitsValue.slice(0, 2)}${DELIMITER}${digitsValue.slice(2)}`;
}

function MonthAndYearInput({
    value,
    setValue,
    error,
    ...props
}: MonthAndYearInputProps) {
    const indicateError = !!error;
    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setTextValue(ev.target.value);
    };

    const { textValue, setTextValue, digitsValue, inputRef } = useDigitsMaskInputVal({
        value,
        setValue,
        formatDigitsToTextVal,
    });

    // Handle cases where pasted value exceeds the allowed length
    useEffect(() => {
        if (digitsValue.length > MAX_LENGTH) {
            const formattedDigits = digitsValue.slice(0, MAX_LENGTH);
            setTextValue(formatDigitsToTextVal(formattedDigits));
        }
    }, [digitsValue, setTextValue]);

    return (
        <div className={cn('relative px-6 flex h-full items-center text-16')}>
            <BaseInputElement
                ref={inputRef}
                value={textValue}
                onChange={handleChange}
                type='tel'
                maxLength={
                    digitsValue.length >= MAX_LENGTH ? digitsValue.length : undefined
                }
                {...props}
                className={cn(
                    'text-16 inline-block max-w-fit',
                    { 'text-red200': indicateError },
                    props?.className,
                )}
            />
            <div className='absolute whitespace-nowrap select-none pointer-events-none'>
                <span className='opacity-0'>{textValue}</span>
                <span className='text-neutral300 '>
                    {PLACEHOLDER.slice(textValue.length)}
                </span>
            </div>
        </div>
    );
}

export default MonthAndYearInput;

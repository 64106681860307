import { JwtPayload } from 'jwt-decode';

export interface AuthUser {
    phoneNumber: string;
    id: string;
    name?: string;
    role?: UserRole;
}

export enum UserRole {
    Operator = 'operator',
    Candidate = 'candidate',
}

export interface IdTokenJwtPayload extends JwtPayload {
    role: UserRole;
}

import { Process } from '@/lib/types';
import { getActionItemType } from '@/lib/utils';
import { processHistory as strings } from '@/strings';
import { getString } from '@/strings/common';

export function getActionItemStrings(process: Process): {
    title: string;
    subtitle: string;
} {
    const actionItemType = getActionItemType(process);
    if (!actionItemType) {
        return { title: '', subtitle: '' };
    }
    return {
        title: getString(process, strings.actionItemToStrings[actionItemType].title),
        subtitle: getString(
            process,
            strings.actionItemToStrings[actionItemType].subtitle,
        ),
    };
}

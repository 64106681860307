import { EventIcon } from '@/components/svgs';
import { ProcessWithEvent } from '@/lib/types';
import { cn } from '@/lib/utils';
import { statusPage as strings } from '@/strings';
import { sortBy } from 'lodash-es';

import EmptyView from './EmptyView';
import ProcessEventView from './ProcessEventView';

function UpcomingEventsView({
    processesWithUpcomingEvents,
}: {
    processesWithUpcomingEvents: ProcessWithEvent[];
}) {
    const hasData = processesWithUpcomingEvents.length > 0;
    const sortedProcesses = sortBy(
        processesWithUpcomingEvents,
        (p) => p.relevantEvent.startAt,
    );

    return (
        <div className={cn('relative h-full w-full overflow-auto')}>
            {!hasData && (
                <EmptyView
                    icon={
                        <EventIcon
                            className={cn('h-24 w-24 fill-neutral100 stroke-neutral100')}
                        />
                    }
                    text={strings.upcomingEvents.emptyLabel}
                />
            )}
            {hasData && (
                <div className={cn('p-8 pb-12 w-full flex justify-center')}>
                    <div className={cn('flex flex-col items-stretch gap-5')}>
                        {sortedProcesses.map((p) => (
                            <ProcessEventView
                                event={p.relevantEvent}
                                process={p}
                                key={p.relevantEvent.id}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpcomingEventsView;

import { DateIsoString, DayString } from '@/lib/base';

export enum DialogStep {
    TimeSelection = 'selectTimeSlot',
    TalkToUs = 'talkToUs',
    TalkToUsSuccess = 'talkToUsSuccess',
    ScheduleSuccess = 'scheduleEventSuccess',
}

export type ProcessEventSlot = {
    start: DateIsoString;
    end: DateIsoString;
};

export type EventSlotsByDay = Record<DayString, ProcessEventSlot[]>;

export type DayInCalendar = number | null;

export type WeekDates = [
    DayInCalendar,
    DayInCalendar,
    DayInCalendar,
    DayInCalendar,
    DayInCalendar,
    DayInCalendar,
    DayInCalendar,
];

import { useAppDispatch } from '@/_helpers/store';
import { fetchAvailabilityWindows } from '@/_reducers/processAvailabilityWindows';
import {
    AvailabilityWindowsDataByProcessId,
    AvailabilityWindowsFetcherDataByProcessId,
} from '@/_selectors';
import { getTimeSlots } from '@/dialogs/scheduleInterview/utils';
import { Process } from '@/lib/types';
import { canScheduleEventForProcess, generateEmptyFetcherState } from '@/lib/utils';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

function useProcessAvailability(process: Process) {
    const { id: processId } = process;
    const isScheduable = canScheduleEventForProcess(process);
    const availabilityFetchersByProcessId = useSelector(
        AvailabilityWindowsFetcherDataByProcessId,
    );
    const availabilityDataByProcessId = useSelector(AvailabilityWindowsDataByProcessId);
    const availabilityFetcher =
        availabilityFetchersByProcessId[processId] ?? generateEmptyFetcherState();

    const { initiated } = availabilityFetcher;
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (initiated || !isScheduable) {
            return;
        }
        dispatch(fetchAvailabilityWindows({ processId }));
    }, [initiated, isScheduable, processId, dispatch]);
    const availabilityData = availabilityDataByProcessId[processId];

    const eventSlots = useMemo(() => {
        if (!availabilityData) {
            return [];
        }
        const { windows, duration, gap } = availabilityData;
        return getTimeSlots({ windows, durationMins: duration, minutesGap: gap });
    }, [availabilityData]);

    return {
        eventSlots,
        ...availabilityFetcher,
    };
}

export default useProcessAvailability;

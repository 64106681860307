import { FalafelIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

function FalafelButton({
    iconProps,
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    iconProps?: React.SVGProps<SVGSVGElement>;
}) {
    return (
        <div
            {...props}
            className={cn(
                'hover:bg-neutral100 cursor-pointer h-8 w-8 rounded-[4px]',
                'flex items-center justify-center',
                className,
            )}
        >
            <FalafelIcon
                {...iconProps}
                className={cn('rotate-90', iconProps?.className)}
            />
        </div>
    );
}

export default FalafelButton;

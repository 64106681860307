import { reusableComponents as componentsStrings } from '@/strings';

import { DELIMITER, MAX_YEAR, MIN_YEAR } from './consts';

const strings = componentsStrings.dateInput;

export function parseDateString(date: string): {
    month: string;
    year: string;
    error: string | null;
} {
    let [month, year] = date.split(DELIMITER);
    if (!month || !year) {
        return {
            month,
            year,
            error: strings.errors.invalidFormat,
        };
    }
    month = month.replace(/\D/g, '');
    year = year.replace(/\D/g, '');
    if (month.length !== 2 || year.length !== 4) {
        return {
            month,
            year,
            error: strings.errors.invalidFormat,
        };
    }
    if (!(Number(month) >= 1 && Number(month) <= 12)) {
        return {
            month,
            year,
            error: strings.errors.invalidMonth,
        };
    }
    if (!(Number(year) >= MIN_YEAR && Number(year) <= MAX_YEAR)) {
        return {
            month,
            year,
            error: strings.errors.invalidYear.func(MIN_YEAR, MAX_YEAR),
        };
    }
    return {
        month,
        year,
        error: null,
    };
}

export function dateToTextValue(date: Date | null): string {
    if (!date || isNaN(date.getTime())) {
        return '';
    }
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().padStart(4, '0');
    return `${month}${DELIMITER}${year}`;
}

export function monthAndYearToDate(month: number, year: number): Date {
    return new Date(year, month - 1);
}

import { ScreeningEvent } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useEffect, useRef } from 'react';

function useTrackRescheduledScreening(screeningEvent: ScreeningEvent | null) {
    const latestScreeningEvent = useRef<ScreeningEvent | null>(screeningEvent);
    useEffect(() => {
        if (
            latestScreeningEvent.current &&
            screeningEvent &&
            screeningEvent.id !== latestScreeningEvent.current.id
        ) {
            trackEvent(MixpanelEvent.IntroCallSyncRescheduledMeeting, {
                prev_scheduled_time: latestScreeningEvent.current.startAt,
                new_scheduled_time: screeningEvent.startAt,
            });
        }
        latestScreeningEvent.current = screeningEvent;
    }, [screeningEvent]);
}

export default useTrackRescheduledScreening;

import { OrganizationAdvantageModel } from '@/lib/types';
import { cn, entriesOf } from '@/lib/utils';
import { processes as processesStrings } from '@/strings';

const strings = processesStrings.processCard;

function AdvantagesRow({ text }: { text: string }) {
    return (
        <div className={cn('flex items-center w-full gap-4')}>
            <div className={cn('w-5 h-5 flex items-center justify-center')}>
                <div className={cn(' w-1.5 h-1.5 bg-black rounded-full')} />
            </div>
            <div className={cn('text-14 font-medium')}>{text}</div>
        </div>
    );
}

export function AdvantagesSection({
    advantages,
}: {
    advantages: OrganizationAdvantageModel[];
}) {
    return (
        <div className={cn('w-full px-10 flex flex-col gap-2')}>
            <div className={cn('text-16 font-bold')}>{strings.whyWorkHere}</div>
            {entriesOf(strings.genericAdvantages).map(([key, text]) => (
                <AdvantagesRow key={key} text={text} />
            ))}
            {advantages.map((advantage) => (
                <AdvantagesRow
                    key={advantage.name}
                    text={strings.orgAdvantageToString[advantage.name]}
                />
            ))}
        </div>
    );
}

export default AdvantagesSection;

import { VIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
        hasError?: boolean;
    }
>(({ className, hasError, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
            'group peer h-[16px] w-[16px] shrink-0 rounded-[3px]',
            'border-[2px] border-primary disabled:border-neutral200',
            'disabled:cursor-not-allowed',
            'data-[state=checked]:bg-primary disabled:data-[state=checked]:bg-neutral200',
            'transition-colors ease-linear duration-150',
            { 'border-red200 data-[state=checked]:bg-red200': hasError },
            className,
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator
            forceMount={!props.disabled ? true : undefined}
            className={cn('flex items-center justify-center text-current')}
        >
            <VIcon className='stroke-white fill-white group-disabled:stroke-neutral300 group-disabled:fill-neutral300' />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export type CheckboxProps = React.ComponentProps<typeof Checkbox>;

export { Checkbox };

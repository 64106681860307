import { DivAttributes } from '@/lib/base';
import { cn } from '@/lib/utils';

function Delimiter({ ...props }: DivAttributes) {
    return (
        <div
            {...props}
            className={cn('w-full h-0 border-b border-neutral200', props?.className)}
        />
    );
}

export default Delimiter;

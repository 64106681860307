import { ArrowLeft } from '@/components/svgs';
import { cn } from '@/lib/utils';

const LoginBackgroundImage = ({ goBack }: { goBack?: () => void }) => {
    return (
        <>
            <img className={cn('h-full object-cover w-full')} src='/img/image260.png' />
            {goBack && (
                <div
                    className={cn(
                        'w-12 h-12 rounded-full bg-white',
                        'absolute top-8 left-8 cursor-pointer',
                        'flex items-center justify-center',
                    )}
                    onClick={goBack}
                >
                    <ArrowLeft className={cn('h-4')} />
                </div>
            )}
        </>
    );
};

export default LoginBackgroundImage;

import { ChatIcon } from '@/components/svgs';
import { PopoverMenu } from '@/components/ui';
import useOpenDialog from '@/dialogs/useOpenDialog';
import { DialogName, Process, ProcessEvent, SupportTriggerSection } from '@/lib/types';
import {
    MixpanelEvent,
    extractProcessEventDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { statusPage as statusPageStrings } from '@/strings';

const strings = statusPageStrings.upcomingEvents;

function OrientationEventActions({
    process,
    event,
}: {
    process: Process;
    event: ProcessEvent;
}) {
    const { openDialog: openSupportDialog } = useOpenDialog(DialogName.Support);
    const onTalkToUs = () => {
        trackEvent(
            MixpanelEvent.UpcomingEventActionsClickHelpForOrientation,
            extractProcessEventDataForMixpanel(process, event),
        );
        openSupportDialog({ triggerSection: SupportTriggerSection.OrientationIssues });
    };

    return (
        <PopoverMenu
            items={[
                {
                    id: 'talkToUs',
                    content: {
                        label: strings.eventActions.requestChangeOrientation,
                        Icon: ChatIcon,
                    },
                    onClick: onTalkToUs,
                },
            ]}
        />
    );
}

export default OrientationEventActions;

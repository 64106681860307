import { DayOfWeek } from '@/lib/api/v1';

import { dayOfWeekToDayNameShort, genericToasts, hourToShortString12 } from '../common';

const strings = {
    search: {
        title: {
            func: (firstName: string) => `Welcome ${firstName}!`,
            example: ['Candy'],
        },
        subtitle: "We're searching facilities near you that match you job preferences",
    },
    noProcesses: {
        title: "We couldn't find a matching facility",
        subtitle:
            "No matching facilities right now. We'll notify you when relevant positions open up.",
    },
    noMoreProcesses: {
        title: 'No facilities match your criteria right now.',
        subtitle: "We'll keep looking and notify you when something relevant opens up.",
    },
    scheduleIntroCall: {
        title: 'Start your job search',
        subtitle: {
            part1: {
                newCandidate:
                    'Next up, set a 10-minute intro call with a Carefam representative. Call us at',
                inProgressCandidate:
                    'Next, set a 10-minute intro call with a Carefam representative, which could speed up the hiring process by 200%! Call us at',
            },
            part2: { func: (phone: string) => phone, example: ['415-792-4961'] },
            part3: {
                func: (
                    startDay: DayOfWeek,
                    endDay: DayOfWeek,
                    startHour: number,
                    endHour: number,
                    timezone: string,
                ) =>
                    `${dayOfWeekToDayNameShort(startDay)}-${dayOfWeekToDayNameShort(endDay)}, ` +
                    `${hourToShortString12(startHour)}-${hourToShortString12(endHour)} ${timezone} or schedule below. ` +
                    "This isn't an interview, so no prep is needed 🙂",
                example: [0, 5, 10, 18, 'ET'],
            },
        },
        button: 'Schedule a call',
    },
    screeningEvent: {
        title: 'Carefam intro call',
        actions: {
            reschedule: 'Reschedule your call',
            cancel: 'Cancel your call',
        },
        cancelDialg: {
            title: 'Confirm you want to cancel this event',
            subtitle: 'Reason for canceling:',
            submit: 'Confirm',
            close: 'Go back',
            failureToast: { ...genericToasts.failure },
        },
    },
    postScreening: {
        title: 'Your next interview is coming up soon',
        subtitle:
            "Interview invitations will start coming in soon. We'll keep you posted by SMS.",
    },
};

export default strings;

import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useCurrentScreeningEvent from '@/fetchers/useCurrentScreeningEvent';
import { ScreeningStatus } from '@/lib/api/v1';

import { ScreeningFlowState } from './types';

function useScreeningState(): ScreeningFlowState {
    const { isReady, data: screeningEvent } = useCurrentScreeningEvent();
    const { isReady: isCandidateDataReady, data: currentCandidate } =
        useCurrentCandidate();

    const hasDoneScreening =
        currentCandidate.hadIntroConversation ||
        (screeningEvent &&
            screeningEvent.status === ScreeningStatus.Done &&
            !screeningEvent.noShow);

    if (!isCandidateDataReady) {
        return ScreeningFlowState.WaitingData;
    }
    if (hasDoneScreening) {
        return ScreeningFlowState.Done;
    }
    if (!isReady) {
        return ScreeningFlowState.WaitingData;
    }
    if (screeningEvent && screeningEvent.status === ScreeningStatus.Scheduled) {
        return ScreeningFlowState.Scheduled;
    }
    return ScreeningFlowState.Pending;
}

export default useScreeningState;

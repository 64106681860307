import { serializeFetcherState } from '@/lib/utils';
import { createSelector } from 'reselect';

import { OrganizationsState } from './base';

export const Organizations = createSelector(OrganizationsState, (s) => s.data);

export const OrganizationsFetcherData = createSelector(OrganizationsState, (s) =>
    serializeFetcherState(s.fetcher),
);

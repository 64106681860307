import { useCallback, useEffect, useRef } from 'react';

function useFetcherRefresh({
    refresh,
    initiated,
}: {
    refresh: () => void;
    initiated: boolean;
}) {
    const initiatedRef = useRef<boolean>(initiated);

    useEffect(() => {
        initiatedRef.current = initiated;
    }, [initiated]);

    return useCallback(() => {
        if (initiatedRef.current) {
            refresh();
        }
    }, [refresh]);
}

export default useFetcherRefresh;

import { useAppDispatch } from '@/_helpers/store';
import { fetchOrganizations } from '@/_reducers/organizations';
import { MissingOrganizationIds } from '@/_selectors';
import useMemoFlatObject from '@/hooks/useMemoFlatObject';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function useOrganizations() {
    const missingOrgIds = useSelector(MissingOrganizationIds);
    const memoizedMissingOrgIds = useMemoFlatObject(missingOrgIds);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (memoizedMissingOrgIds.length === 0) {
            return;
        }
        dispatch(fetchOrganizations({ ids: memoizedMissingOrgIds }));
    }, [memoizedMissingOrgIds, dispatch]);
    return {
        hasMissingOrgs: memoizedMissingOrgIds.length > 0,
    };
}

export default useOrganizations;

export enum Step {
    Phone = 'Phone',
    Code = 'Code',
}

export type SignInSectionProps = {
    phone: string;
    setPhone: (err: string) => void;
    onSubmit: (phone: string) => Promise<void>;
};

export type CodeVerificationSectionProps = {
    phone: string;
    prefix: string;
    submit: (code: string) => Promise<void>;
    resendCode: () => Promise<void>;
};

import useReachedScrollEnd from '@/hooks/useReachedScrollEnd';
import { cn } from '@/lib/utils';

import { useDialogContext } from './context';
import { ContentWithFooterProps } from './types';

function DialogContentWithFooter({
    bodyProps,
    footer,
    footerProps,
    children,
    onScrollToBottomCallback,
    scrollToBottomDistanceConfig,
    isInnerScroll = false,
}: ContentWithFooterProps) {
    const { scrollRef } = useDialogContext();
    useReachedScrollEnd({
        callback: onScrollToBottomCallback,
        distanceFromBottom: scrollToBottomDistanceConfig,
        containerRef: scrollRef,
    });
    return (
        <>
            <div
                {...bodyProps}
                ref={isInnerScroll ? undefined : scrollRef}
                className={cn(
                    'flex-grow w-full overflow-auto p-8',
                    'relative ',
                    bodyProps?.className,
                )}
            >
                {children}
            </div>
            {footer && (
                <div
                    {...footerProps}
                    className={cn(
                        'relative w-full max-w-full border-t border-neutral100',
                        'py-8 px-10',
                        footerProps?.className,
                    )}
                >
                    {footer}
                </div>
            )}
        </>
    );
}

export default DialogContentWithFooter;

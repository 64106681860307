import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

import ProcessStatusBadge from '../ProcessStatusBadge';

function ProcessStatusDisplay({
    process,
    substatusView,
}: {
    process: Process;
    substatusView: ReactNode;
}) {
    return (
        <div
            className={cn('flex w-full flex-nowrap items-center justify-between gap-3')}
        >
            <div className={cn('text-11 font-semibold')}>{substatusView}</div>
            <ProcessStatusBadge process={process} />
        </div>
    );
}

export default ProcessStatusDisplay;

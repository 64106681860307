import { useAppDispatch } from '@/_helpers/store';
import { syncNewRescheduledScreeningEvent } from '@/_reducers/screening';
import { CurrentScreeningEvent } from '@/_selectors';
import { CalendarIcon, XIcon } from '@/components/svgs';
import { PopoverMenu } from '@/components/ui';
import EventView from '@/events/EventView';
import {
    CalendlyWidget,
    CalendlyWidgetType,
    useTrackRescheduledScreening,
} from '@/introCall';
import { ISOtoDate } from '@/lib/base';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as strings } from '@/strings';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import CancelScreeningDialog from './cancelScreeningDialog/dialog';

function ScreeningEventView() {
    const dispatch = useAppDispatch();
    const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
    const [isCancelOpen, setIsCancelOpen] = useState(false);
    const screeningEvent = useSelector(CurrentScreeningEvent);
    useTrackRescheduledScreening(screeningEvent);
    const startAt: Date = screeningEvent
        ? ISOtoDate(screeningEvent.startAt)
        : new Date();
    const endAt: Date = screeningEvent ? ISOtoDate(screeningEvent.endAt) : new Date();

    const handleRescheduleOpenChange = (open: boolean) => {
        setIsRescheduleOpen(open);
        if (isRescheduleOpen && !open && screeningEvent) {
            dispatch(syncNewRescheduledScreeningEvent(screeningEvent.id));
        }
    };
    const isOngoing = screeningEvent
        ? new Date(screeningEvent.startAt) < new Date()
        : false;

    return (
        <>
            {screeningEvent && (
                <>
                    <div className={cn('relative w-full py-12 px-8 bg-accent300')}>
                        <EventView
                            endAt={endAt}
                            startAt={startAt}
                            title={strings.screeningEvent.title}
                            onMenuOpen={() => {
                                trackEvent(MixpanelEvent.IntroCallOpenActionsMenu);
                            }}
                            popoverMenu={
                                isOngoing ? undefined : (
                                    <PopoverMenu
                                        items={[
                                            {
                                                id: 'reschedule',
                                                content: {
                                                    label: strings.screeningEvent.actions
                                                        .reschedule,
                                                    Icon: CalendarIcon,
                                                },
                                                onClick: () => {
                                                    trackEvent(
                                                        MixpanelEvent.IntroCallClickReschedule,
                                                        {
                                                            current_scheduled_time:
                                                                screeningEvent.startAt,
                                                        },
                                                    );
                                                    handleRescheduleOpenChange(true);
                                                },
                                            },
                                            {
                                                id: 'cancel',
                                                content: {
                                                    label: strings.screeningEvent.actions
                                                        .cancel,
                                                    Icon: XIcon,
                                                    iconProps: {
                                                        className: cn('h-5'),
                                                        strokeWidth: 0.5,
                                                    },
                                                },
                                                onClick: () => {
                                                    trackEvent(
                                                        MixpanelEvent.IntroCallClickCancel,
                                                        {
                                                            current_scheduled_time:
                                                                screeningEvent.startAt,
                                                        },
                                                    );
                                                    setIsCancelOpen(true);
                                                },
                                            },
                                        ]}
                                    />
                                )
                            }
                        />
                    </div>
                    {isRescheduleOpen && (
                        <CalendlyWidget
                            open={isRescheduleOpen}
                            setOpen={handleRescheduleOpenChange}
                            link={screeningEvent.rescheduleUrl}
                            type={CalendlyWidgetType.Reschedule}
                        />
                    )}
                    {isCancelOpen && (
                        <CancelScreeningDialog
                            open={isCancelOpen}
                            setOpen={setIsCancelOpen}
                            id={screeningEvent.id}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default ScreeningEventView;

import { DefaultDivProps } from '@/lib/base';
import { ReactNode } from 'react';

import { SelectOption } from '../select';
import { TextInputProps } from '../textInput';

export enum FieldClearErrorsPlan {
    ChangeOnly = 'ChangeOnly',
    All = 'All',
}

export enum EditableFieldType {
    Text = 'text',
    Selection = 'selection',
}

export type EditableFieldBaseProps = DefaultDivProps & {
    error?: string;
    setError?: (e: string) => void;
    label?: string;
    rootProps?: DefaultDivProps;
    clearErrorsPlan?: FieldClearErrorsPlan;
    errorElemProps?: React.HTMLAttributes<HTMLParagraphElement>;
    labelProps?: DefaultDivProps;
    bottomElement?: ReactNode;
    prefixElement?: ReactNode;
};
export type EditableFieldProps = EditableFieldBaseProps & {
    editElement: ReactNode;
    displayValue: string;
    onEdit: boolean;
    setOnEdit: (edit: boolean) => void;
    handleFocus?: () => void;
    showError?: boolean;
    showOnEdit?: boolean;
};

export type EditableTextFieldProps = EditableFieldBaseProps & {
    value: string;
    originalValue: string;
    setValue: (value: string) => void;
    onEditStateChange?: (onEdit: boolean) => void;
    extraInputs?: ReactNode;
    extraInputsContainerProps?: DefaultDivProps;
    hasChangeOverride?: boolean;
} & Pick<TextInputProps, 'prefixElement' | 'suffixElement' | 'type' | 'placeholder'>;

export type EditableSelectFieldProps<T extends string> = EditableFieldBaseProps & {
    value: T;
    originalValue: string;
    setValue: (value: T) => void;
    options: SelectOption<T>[];
    onEditStateChange?: (onEdit: boolean) => void;
};

export type EditableMultiSelectFieldProps<T extends string> = EditableFieldBaseProps & {
    value: T[];
    originalValue: T[];
    setValue: (value: T[]) => void;
    options: SelectOption<T>[];
    transformator?: (value: T[]) => string;
    onEditStateChange?: (onEdit: boolean) => void;
};

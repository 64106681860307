import { fetchScreeningEvent } from '@/_reducers/screening';
import { CurrentScreeningEvent, ScreeningEventFetcherData } from '@/_selectors';

import useFetcherBase from './useFetcherBase';

function useCurrentScreeningEvent() {
    return useFetcherBase({
        fetchFunc: fetchScreeningEvent,
        dataSelector: CurrentScreeningEvent,
        fetcherSelector: ScreeningEventFetcherData,
    });
}

export default useCurrentScreeningEvent;

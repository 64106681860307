import { fetchCandidateAppState } from '@/_reducers/appState';
import { AppStateData, AppStateFetcherData } from '@/_selectors/generation_1/appState';

import useFetcherBase from './useFetcherBase';

function useCandidateAppState() {
    return useFetcherBase({
        fetchFunc: fetchCandidateAppState,
        dataSelector: AppStateData,
        fetcherSelector: AppStateFetcherData,
    });
}

export default useCandidateAppState;

import { cn } from '@/lib/utils';

import RegistrationFooter from './RegistrationFooter';
import { RegistrationStepLayoutProps } from './types';

function RegistrationStepLayout({
    canGoNext = true,
    hideFooter = false,
    children,
    ...footerProps
}: RegistrationStepLayoutProps) {
    const showRegistrationFooter = !hideFooter;
    return (
        <div className={cn('relative flex w-full h-full flex-col items-center')}>
            <div
                className={cn(
                    'relative flex-grow w-full h-full px-12 py-8 overflow-auto flex justify-center',
                )}
            >
                <div className={cn('w-full max-w-[600px] h-fit flex justify-center ')}>
                    {children}
                </div>
            </div>
            {showRegistrationFooter && (
                <RegistrationFooter disabled={!canGoNext} {...footerProps} />
            )}
        </div>
    );
}

export default RegistrationStepLayout;

import { cn } from '@/lib/utils';

function ScreeningSectionFooter({
    withDelimiter = false,
    hasOngoingProcesses,
}: {
    withDelimiter?: boolean;
    hasOngoingProcesses: boolean;
}) {
    return (
        <div className={cn('relative w-full flex flex-col px-8')}>
            <div
                className={cn('w-full h-0', {
                    'border-t border-neutral200': withDelimiter,
                })}
            />
            {hasOngoingProcesses && <div className={cn('h-2')} />}
        </div>
    );
}

export default ScreeningSectionFooter;

import { AppDispatch, GetRootState, RootState } from '@/_helpers/store';
import { AsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export enum FetchModelStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'succeeded',
    FAILURE = 'failed',
}

export interface FetcherState {
    initiated: boolean;
    status: FetchModelStatus;
    error?: string;
}

export interface FetcherStateData {
    initiated: boolean;
    isError: boolean;
    isLoading: boolean;
    status: FetchModelStatus;
    error?: string;
}

type NoArgAsyncThunk = AsyncThunk<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    undefined,
    { state: RootState; dispatch: AppDispatch }
>;
type NoArgCustomAsyncThunk = () => (
    dispatch: AppDispatch,
    getState: GetRootState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<PayloadAction<any>> | void;

export type FetcherFunc = NoArgCustomAsyncThunk | NoArgAsyncThunk;

import { Checkbox } from '@/components/ui';
import { cn } from '@/lib/utils';

import { Button } from './base';
import { CheckboxButtonProps } from './types';

function CheckboxButton({
    label,
    checked,
    className,
    ...buttonProps
}: CheckboxButtonProps) {
    return (
        <Button
            className={cn('p-6 justify-start', { 'bg-accent200': checked }, className)}
            variant={'outlinedAction'}
            size={'lg'}
            {...buttonProps}
        >
            <div className={cn('flex items-center gap-4')}>
                <Checkbox checked={checked} asChild>
                    <div />
                </Checkbox>
                <div>{label}</div>
            </div>
        </Button>
    );
}

export default CheckboxButton;

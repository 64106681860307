import { Facility } from '@/lib/types';
import { cn } from '@/lib/utils';
import StaticMapComponent from '@/staticMap/StaticMapComponent';
import { useState } from 'react';

interface Props {
    isRegional?: boolean;
    facility: Facility;
}

export function MapSection({ facility, isRegional = false }: Props) {
    const [mapReady, setMapReady] = useState(false);
    const { lat, lng } = facility;
    return (
        <div
            className={cn(
                'relative w-full h-[280px] flex items-center justify-center bg-neutral-100',
                'rounded-2xl overflow-hidden',
            )}
        >
            {isRegional && mapReady && (
                <div
                    className={cn(
                        'absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-[400]',
                        'h-[90%] rounded-full bg-primary/20 aspect-square',
                        'border-2 border-white',
                        'flex items-center justify-center',
                    )}
                >
                    <div className={cn('w-4 h-4 bg-primary rounded-full')} />
                </div>
            )}

            <div className='relative w-full h-full scale-110 translate-x-[5%] translate-y-[5%]'>
                {isRegional ? (
                    <StaticMapComponent
                        lat={lat}
                        lng={lng}
                        zoom={11}
                        ready={mapReady}
                        setReady={setMapReady}
                    />
                ) : (
                    <StaticMapComponent
                        lat={lat}
                        lng={lng}
                        zoom={14}
                        dragging
                        zoomControl
                        touchZoom
                        doubleClickZoom
                        locations={[
                            {
                                lat,
                                lng,
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
}

export default MapSection;

import { OrganizationsFetcherData, Processes } from '@/_selectors';
import { useSelector } from 'react-redux';

import useOrganizations from './useOrganizations';
import useProcessModels from './useProcessesModels';

function useProcesses() {
    const { hasMissingOrgs } = useOrganizations();
    const { initiated: isReadyOrganizations, isLoading: isLoadingOrganizations } =
        useSelector(OrganizationsFetcherData);
    const isWaitingOrganizations = !isReadyOrganizations && isLoadingOrganizations;
    const {
        isReady: isReadyProcesses,
        isWaiting: isWaitingProcesses,
        refresh,
    } = useProcessModels();
    const processes = useSelector(Processes);
    return {
        isReady: isReadyProcesses && (isReadyOrganizations || !hasMissingOrgs),
        isWaiting: isWaitingOrganizations || isWaitingProcesses,
        refresh,
        data: processes,
    };
}

export default useProcesses;

import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function AdditionalAttributesStep() {
    const { hasCar, hasDrivingLicense, setHasCar, setHasDrivingLicense } =
        useRegisterFormContext();
    const toggleHasCarOption = (value: boolean) => {
        if (value === hasCar) {
            setHasCar(null);
        } else {
            setHasCar(value);
        }
    };
    const toggleHasLicenseOption = (value: boolean) => {
        if (value === hasDrivingLicense) {
            setHasDrivingLicense(null);
        } else {
            setHasDrivingLicense(value);
        }
    };

    return (
        <RegistrationStepLayout canGoNext>
            <div className={cn('flex flex-col items-center gap-8')}>
                <StepTitle title={strings.additionalAttributes.title} />
                <div className={cn('w-full max-w-[350px]')}>
                    <div className={cn('text-15 font-medium')}>
                        {strings.additionalAttributes.hasCarQuestion}
                    </div>
                    <Button
                        variant={hasCar ? 'default' : 'secondary'}
                        className={cn('w-full mt-4')}
                        size={'default'}
                        onClick={() => toggleHasCarOption(true)}
                    >
                        {strings.answerYes}
                    </Button>
                    <Button
                        variant={hasCar === false ? 'default' : 'secondary'}
                        className={cn('w-full mt-4')}
                        size={'default'}
                        onClick={() => toggleHasCarOption(false)}
                    >
                        {strings.answerNo}
                    </Button>
                </div>
                <div className={cn('w-full max-w-[350px]')}>
                    <div className={cn('text-15 font-medium mt-2')}>
                        {strings.additionalAttributes.hasDrivingLicenseQuestion}
                    </div>
                    <Button
                        variant={hasDrivingLicense ? 'default' : 'secondary'}
                        className={cn('w-full mt-4')}
                        size={'default'}
                        onClick={() => toggleHasLicenseOption(true)}
                    >
                        {strings.answerYes}
                    </Button>
                    <Button
                        variant={hasDrivingLicense === false ? 'default' : 'secondary'}
                        className={cn('w-full mt-4')}
                        size={'default'}
                        onClick={() => toggleHasLicenseOption(false)}
                    >
                        {strings.answerNo}
                    </Button>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default AdditionalAttributesStep;

import Logger from '@/_helpers/logger';
import {
    CANNOT_REGISTER_ERROR_CODE,
    FIREBASE_INVALID_CODE_ERROR_CODE,
    INVALID_PHONE_NUMBER,
} from '@/firebase';

import { extractErrorMsg, extractErrorType } from './errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function alertFirebaseSignInError(e: any) {
    const type = extractErrorType(e);
    if ([CANNOT_REGISTER_ERROR_CODE, INVALID_PHONE_NUMBER].includes(type)) {
        return;
    }
    Logger.warn(`Got unknown in firebase error on phone sign in: ${extractErrorMsg(e)}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function alertFirebaseCodeVerificationError(e: any) {
    const type = extractErrorType(e);
    if ([FIREBASE_INVALID_CODE_ERROR_CODE].includes(type)) {
        return;
    }
    Logger.warn(
        `Got unknown in firebase error on code verification: ${extractErrorMsg(e)}`,
    );
}

import Loader from '@/components/lotties/Loader';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';
import { useEffect, useState } from 'react';

import useActionWithRecaptchaObserver from './recaptcha/useActionWithRecaptchaObserver';

const COUNTDOWN_SECS = 15;

function ResendCodeButton({
    resendCode: resendCodeAction,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    resendCode: (callback: () => void) => Promise<void>;
}) {
    const [countdown, setCountdown] = useState(COUNTDOWN_SECS);
    const isDisabled = countdown > 0;

    const resetCountdown = () => {
        setCountdown(COUNTDOWN_SECS);
    };

    const { action: resendCode, isLoading } = useActionWithRecaptchaObserver(
        async () => await resendCodeAction(resetCountdown),
    );

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [countdown]);

    return (
        <>
            <div
                {...props}
                className={cn(
                    'relative h-8 flex items-center justify-center',
                    { 'pointer-events-none': isLoading },
                    props.className,
                )}
            >
                {isLoading && (
                    <div
                        className={cn(
                            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                        )}
                    >
                        <Loader sizing={'md'} variant={'primary'} />
                    </div>
                )}
                <span
                    className={cn(
                        'text-14 font-bold cursor-pointer select-none px-6 py-2 rounded-2xl hover:bg-neutral100',
                        {
                            'opacity-0': isLoading,
                            'cursor-default pointer-events-none text-neutral300 font-medium bg-neutral100/50':
                                isDisabled,
                        },
                    )}
                    onClick={resendCode}
                >
                    {isDisabled && (
                        <span>
                            {strings.verificationCode.resendCodeButtonDisabled.part1}{' '}
                            <span className={cn('inline-block w-10')}>
                                {strings.verificationCode.resendCodeButtonDisabled.part2.func(
                                    countdown,
                                )}
                            </span>
                        </span>
                    )}
                    {!isDisabled && <>{strings.verificationCode.resendCodeButton}</>}
                </span>
            </div>
        </>
    );
}

export default ResendCodeButton;

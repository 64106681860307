import { NumberString } from '@/lib/base';
import { MouseEvent, RefObject } from 'react';

export function handlerNoPropagation<T>(
    func: (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => T,
) {
    return (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        return func(e);
    };
}

export function isOverflown(elementRef: RefObject<HTMLDivElement>) {
    if (!elementRef.current) return false;
    return elementRef.current.scrollHeight > elementRef.current.clientHeight;
}

export const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAsyncFunction = (fn: (...args: any) => any) => {
    return fn.constructor.name === 'AsyncFunction';
};

export const stringToDigitsOnly = (text: string) => {
    return text.replace(/\D/g, '') as NumberString;
};

import Logger from '@/_helpers/logger';
import { localStorageGraceful } from '@/lib/utils';
import { isEmpty } from 'lodash-es';

export const MAIN_KEY = 'carefam_register_form';

export const getStoredState = () => {
    if (!localStorageGraceful) {
        return {};
    }
    const storedValue = localStorageGraceful.getItem(MAIN_KEY);
    const _parseStoredValue = () => {
        if (!storedValue) {
            return {};
        }
        try {
            const parsedState = JSON.parse(storedValue);
            if (typeof parsedState !== 'object') {
                return {};
            }
            return parsedState;
        } catch (e) {
            Logger.error(`Error parsing stored state ${e}`);
            return {};
        }
    };

    const storedState = _parseStoredValue();
    if (isEmpty(storedState) && storedValue !== '{}') {
        if (storedValue !== null) {
            Logger.warn(`reseting old stored state value: "${storedValue}"`);
        }
        localStorageGraceful.setItem(MAIN_KEY, '{}');
    }
    return storedState;
};

import {
    AnonumousPageId,
    NoFooterRoute,
    anonymousPageIds,
    noFooterRoutes,
} from '@/lib/types';
import { getCurrentPageId } from '@/lib/utils';
import { useLocation } from 'react-router-dom';

function useCurrentPageId() {
    const location = useLocation();
    return getCurrentPageId(location.pathname);
}

export function useIsCurrentPageIdAnonymous() {
    const currentPageId = useCurrentPageId();
    return anonymousPageIds.includes(currentPageId as AnonumousPageId);
}

export function useShouldShowFooterMenu() {
    const currentPageId = useCurrentPageId();
    if (noFooterRoutes.includes(currentPageId as NoFooterRoute)) {
        return false;
    }
    return true;
}

export default useCurrentPageId;

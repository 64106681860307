import { XIcon } from '@/components/svgs';
import { Env } from '@/lib/types';
import { cn } from '@/lib/utils';
import { PinIcon, VenetianMaskIcon } from 'lucide-react';

import EnvIndication from './EnvIndication';
import { ImpersonationContentProps } from './types';

function ImpersonationIndicationContentMobile({
    pinned,
    setPinned,
    exitImpersonation,
    impersonatedUser,
    env,
}: ImpersonationContentProps) {
    const isProd = env === Env.Prod;
    const togglePinned = () => setPinned(!pinned);
    const collapsed = !pinned && !isProd;
    return (
        <div className={cn('flex flex-col items-center justify-between w-full')}>
            <div className={cn('relative w-full')}>
                <div className={cn('w-full flex items-center justify-between gap-1')}>
                    <div
                        className={cn(
                            'p-2 rounded-full cursor-pointer hover:bg-black/10 group',
                        )}
                        onClick={togglePinned}
                    >
                        <PinIcon
                            className={cn('h-6 w-6 group-hover:fill-black/30', {
                                'group-hover:fill-black/10 fill-black/40': pinned,
                            })}
                        />
                    </div>
                    <div className={cn('relative flex-grow')}>
                        <div className={cn('w-full flex justify-end')}>
                            <div className={cn('flex items-center')}>
                                <EnvIndication pinned={pinned} env={env} />
                                <XIcon
                                    onClick={exitImpersonation}
                                    className={cn('w-5 h-5', { hidden: collapsed })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cn('w-full flex items-center gap-1')}>
                    <div className={cn('p-1')}>
                        <VenetianMaskIcon
                            className={cn('h-8 w-8 min-w-8 min-h-8  stroke-white', {
                                'stroke-gray-700': !isProd,
                            })}
                        />
                    </div>

                    <p
                        className={cn(
                            'w-full text-ellipsis whitespace-nowrap overflow-hidden text-white text-15 font-medium',
                            {
                                hidden: collapsed,
                                'text-gray-700': !isProd,
                            },
                        )}
                    >
                        <span
                            className={cn('ml-2 underline')}
                        >{`${impersonatedUser.name}`}</span>
                        <span
                            className={cn('font-bold')}
                        >{` (${impersonatedUser.phoneNumber})`}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ImpersonationIndicationContentMobile;

import L from 'leaflet';
import { LocateFixed } from 'lucide-react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useMap } from 'react-leaflet';

function ReturnToCenterButton({ lat, lng }: { lat: number; lng: number }) {
    const map = useMap();

    useEffect(() => {
        const button = new L.Control({ position: 'topleft' });
        button.onAdd = () => {
            const buttonDiv = L.DomUtil.create(
                'button',
                'leaflet-bar leaflet-control leaflet-control-custom',
            );
            buttonDiv.style.cursor = 'pointer';
            buttonDiv.onclick = (e) => {
                map.setView([lat, lng]);
                e.preventDefault;
            };
            ReactDOM.createRoot(buttonDiv).render(
                <a
                    className='!flex items-center justify-center'
                    onClick={(e) => e.preventDefault()}
                >
                    <LocateFixed size={20} />
                </a>,
            );
            return buttonDiv;
        };
        button.addTo(map);
        return () => {
            button.remove();
        };
    }, [lat, lng, map]);

    return null;
}

export default ReturnToCenterButton;

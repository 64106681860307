import { AppDispatch, RootState } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { components } from '@/lib/api/v1';
import { AvailabilityWindow, FetchModelStatus, FetcherState } from '@/lib/types';
import { generateEmptyFetcherState } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchAvailabilityWindows = createAsyncThunk<
    components['schemas']['AvailabileTimeFramesOut'],
    { processId: string },
    { state: RootState }
>(
    'processAvailabilityWindows/fetchAvailabilityWindows',
    async ({ processId }, { rejectWithValue }) => {
        try {
            const response = await apiClient.GET(
                '/api/v1/candidate-events/time-frames',
                {
                    params: {
                        query: {
                            process_id: processId,
                        },
                    },
                },
            );
            return response.data!;
        } catch (error) {
            return rejectWithValue(extractErrorMsg(error));
        }
    },
);

export const resetProcessEventSlots = () => (dispatch: AppDispatch) => {
    dispatch(processAvailabilityWindowsSlice.actions.reset());
};

interface ProcessAvailabilityData {
    gap: number;
    duration: number;
    windows: AvailabilityWindow[];
}

interface SliceState {
    data: Record<string, ProcessAvailabilityData>;
    fetchers: Record<string, FetcherState>;
}

const initialState: SliceState = {
    data: {},
    fetchers: {},
};

const processAvailabilityWindowsSlice = createSlice({
    name: 'processAvailabilityWindows',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAvailabilityWindows.pending, (state, action) => {
                const { processId } = action.meta.arg;
                if (!state.fetchers[processId]) {
                    state.fetchers[processId] = generateEmptyFetcherState();
                }
                state.fetchers[processId].status = FetchModelStatus.LOADING;
            })
            .addCase(fetchAvailabilityWindows.fulfilled, (state, action) => {
                const { processId } = action.meta.arg;
                const { gap, duration, items } = action.payload;
                state.fetchers[processId].status = FetchModelStatus.SUCCESS;
                state.fetchers[processId].initiated = true;
                if (action.payload) {
                    state.data[processId] = {
                        gap,
                        duration,
                        windows: items,
                    };
                }
            })
            .addCase(fetchAvailabilityWindows.rejected, (state, action) => {
                const { processId } = action.meta.arg;
                state.fetchers[processId].status = FetchModelStatus.FAILURE;
                state.fetchers[processId].error = action.error.message;
            });
    },
});

export default processAvailabilityWindowsSlice;

import { ToastProvider, ToastViewport } from './toast';
import { ToasterView } from './toasterView';
import { useToast } from './use-toast';

export function Toaster() {
    const { toasts } = useToast();

    return (
        <ToastProvider>
            {toasts.map((toast) => (
                <ToasterView key={toast.id} toast={toast} />
            ))}
            <ToastViewport />
        </ToastProvider>
    );
}

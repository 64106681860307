import { extractApiError } from '@/lib/api/utils';
import { FirebaseError } from 'firebase/app';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractErrorMsg(error: any) {
    if (error instanceof FirebaseError) {
        return error.message;
    }
    if (typeof error === 'string') {
        return error;
    }
    if (typeof error === 'object' && typeof error?.message === 'string') {
        return error.message as string;
    }
    return extractApiError(error).detail;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractErrorType(error: any) {
    if (error instanceof FirebaseError) {
        return error.code;
    }
    if (typeof error === 'object' && !!error?.type) {
        return error.type;
    }
    return undefined;
}

import { fetchAllProcesses } from '@/_reducers/processes';
import { ProcessModels, ProcessesFetcherData } from '@/_selectors';

import useFetcherBase from './useFetcherBase';

function useProcessModels() {
    return useFetcherBase({
        fetchFunc: fetchAllProcesses,
        dataSelector: ProcessModels,
        fetcherSelector: ProcessesFetcherData,
    });
}

export default useProcessModels;

import { cn } from '@/lib/utils';
import { forwardRef, useEffect, useRef } from 'react';

import { usePopoverContext } from './context';
import {
    CustomContentProps,
    IconWithLabelProps,
    PopoverMenuDelimiterProps,
    PopoverMenuItemProps,
    PopoverMenuProps,
} from './types';

export function PopoverMenuItemContent({
    Icon,
    iconCustom,
    label,
    labelProps,
    iconProps,
}: IconWithLabelProps) {
    return (
        <div className={cn('flex items-center')}>
            {iconCustom ? iconCustom : <Icon width={16} height={16} {...iconProps} />}
            <p
                {...labelProps}
                className={cn(
                    'ml-4 text-13 font-medium text-black',
                    labelProps?.className,
                )}
            >
                {label}
            </p>
        </div>
    );
}
export const PopoverMenuItemView = forwardRef<HTMLDivElement, PopoverMenuItemProps>(
    function PopoverMenuItemView(
        { content, className, onClick, dontClose = false, rootProps },
        ref,
    ) {
        const { custom, Icon, iconCustom, label, labelProps, iconProps } =
            content as IconWithLabelProps & CustomContentProps;
        const { handleClose } = usePopoverContext();
        const handleClick = () => {
            onClick();
            if (!dontClose) {
                handleClose();
            }
        };
        return (
            <div
                {...rootProps}
                ref={ref}
                className={cn(
                    'bg-white hover:bg-accent100 active:bg-accent300 py-8 pl-6 pr-14 h-[64px] flex items-center cursor-pointer transition-colors ease-out duration-150',
                    rootProps?.className,
                    className,
                )}
                onClick={handleClick}
            >
                {custom ?? null}
                {!custom && (
                    <PopoverMenuItemContent
                        Icon={Icon}
                        label={label}
                        labelProps={labelProps}
                        iconProps={iconProps}
                        iconCustom={iconCustom}
                    />
                )}
            </div>
        );
    },
);

export function PopoverMenuDelimiterView({ className }: PopoverMenuDelimiterProps) {
    return (
        <div
            className={cn(
                'my-2 h-0 w-full cursor-default',
                'border-b border-neutral200',
                className,
            )}
        />
    );
}

export function PopoverMenu({
    rootProps,
    items,
    selectedItemId,
    itemsProps: itemsCommonProps,
}: PopoverMenuProps) {
    const menuRef = useRef<HTMLDivElement>(null);
    const selectedItemRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (selectedItemId !== undefined && menuRef.current) {
            if (selectedItemRef.current) {
                menuRef.current.scrollTop = selectedItemRef.current.offsetTop;
            }
        }
    }, [selectedItemId, items]);
    return (
        <div
            {...rootProps}
            ref={menuRef}
            className={cn('rounded-2xl', rootProps?.className)}
        >
            {items.map((item, index) =>
                item.isDelimiter ? (
                    <PopoverMenuDelimiterView
                        key={`poporver_menu_delimiter-${index}`}
                        {...item}
                    />
                ) : (
                    <PopoverMenuItemView
                        key={item.id}
                        ref={selectedItemId === item.id ? selectedItemRef : null}
                        {...item}
                        {...itemsCommonProps}
                    />
                ),
            )}
        </div>
    );
}

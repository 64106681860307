import { Dialog, DialogHeaderAnchor } from '@/components/ui/dialog';
import useSetOpen from '@/hooks/useSetOpen';

import { PopoverContext } from './context';
import { CustomPopoverProps } from './types';

export function CustomPopover({
    children,
    open: externalOpen,
    setOpen: setExternalOpen,
    trigger,
    triggerProps,
    onOpenCallback,
}: CustomPopoverProps) {
    const { open, setOpen, handleClose, handleOpen } = useSetOpen(
        externalOpen,
        setExternalOpen,
        onOpenCallback,
    );
    return (
        <PopoverContext.Provider value={{ handleClose, handleOpen }}>
            <Dialog
                trigger={trigger}
                open={open}
                setOpen={setOpen}
                {...{ triggerProps }}
                customHeader={<DialogHeaderAnchor />}
            >
                {children}
            </Dialog>
        </PopoverContext.Provider>
    );
}

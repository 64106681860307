import { useCallback } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

function useControlledSetValue<T extends FieldValues>(
    setValue: UseFormSetValue<T>,
): UseFormSetValue<T> {
    const controlledSetValue = useCallback(
        (...args: Parameters<UseFormSetValue<T>>) => {
            args[2] = Object.assign(
                { shouldDirty: true, shouldTouch: true, shouldValidate: true },
                args[2] ?? {},
            );
            return setValue(...args);
        },
        [setValue],
    ) as UseFormSetValue<T>;
    return controlledSetValue;
}

export default useControlledSetValue;

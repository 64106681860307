import { CheckboxButton } from '@/components/ui/button';
import { candidateJobType } from '@/lib/api/v1';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function JobTypesStep() {
    const { jobTypes, toggleJobType } = useRegisterFormContext();
    const canGoNext = jobTypes.length > 0;
    return (
        <RegistrationStepLayout canGoNext={canGoNext}>
            <div className={cn('flex flex-col items-center gap-8')}>
                <StepTitle
                    title={strings.jobTypesTitle}
                    subtitle={strings.selectManyDescription}
                />
                <div className={cn('flex flex-wrap gap-6 mt-2')}>
                    {candidateJobType.map((jt) => (
                        <CheckboxButton
                            key={jt}
                            className={cn('w-full')}
                            checked={jobTypes.includes(jt)}
                            onClick={() => toggleJobType(jt)}
                            label={strings.jobTypes[jt]}
                        />
                    ))}
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default JobTypesStep;

import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

export const buttonVariants = cva(
    cn(
        'inline-flex items-center justify-center rounded-xl whitespace-nowrap text-sm font-semibold',
        'border border-primary transition-colors focus-visible:outline-none',
        'disabled:pointer-events-none disabled:opacity-30',
        'ease-in-out	transition-all duration-100',
    ),
    {
        variants: {
            variant: {
                default: cn('bg-accent600 text-accent100'),
                neutral: cn('bg-accent300 border-accent300 text-black'),
                destructive: cn('border-primary bg-transparent text-black'),
                danger: cn('text-white bg-red200 border-red200'),
                secondary: cn('border-primary bg-white text-black'),
                outlinedAction: cn('border-primary bg-white text-black'),
            },
            size: {
                default: cn('h-14 px-8 text-12'),
                smThin: cn('h-12 px-5 text-12'),
                sm: cn('h-12 px-8 text-11'),
                tiny: cn('h-10 px-5 text-11'),
                md: cn('h-16 text-13 font-semibold px-8'),
                lg: cn('h-[54px] text-15 font-semibold rounded-2xl px-8'),
                xl: cn('h-20 px-15 text-14'),
                square: cn('p-0 h-12 w-12'),
                icon: cn('h-10 w-10'),
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;

import { useAppDispatch } from '@/_helpers/store';
import { exitDevMode } from '@/_reducers/devMode';
import { IsDevModeOn } from '@/_selectors';
import { AlertIcon, ArrowLeft, HamburgerIcon } from '@/components/svgs';
import useEnterDevMode from '@/hooks/useEnterDevMode';
import { cn } from '@/lib/utils';
import { XIcon } from 'lucide-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function DevModeIndication() {
    const dispatch = useAppDispatch();
    const exit = () => {
        console.log('Developer mode deactivated!');
        dispatch(exitDevMode());
    };
    const [isTop, setIsTop] = useState(false);
    const [isLeft, setIsLeft] = useState(true);
    const [expand, setExpand] = useState(false);

    return (
        <div
            className={cn(
                'fixed p-2 bg-sky-300/80 z-[1000] flex flex-col items-stretch gap-2',
                {
                    'rounded-t-2xl bottom-0 flex-col-reverse': !isTop,
                    'rounded-b-2xl top-0': isTop,
                    'left-0': isLeft,
                    'right-0': !isLeft,
                },
            )}
        >
            <div
                className={cn('flex items-center gap-4', {
                    'flex-row-reverse': isLeft,
                })}
            >
                <ArrowLeft
                    className={cn('h-6 w-6 cursor-pointer', { 'rotate-180': isLeft })}
                    onClick={() => setIsLeft((current) => !current)}
                />
                <ArrowLeft
                    className={cn('h-6 w-6 cursor-pointer', {
                        'rotate-90': !isTop,
                        '-rotate-90': isTop,
                    })}
                    onClick={() => setIsTop((current) => !current)}
                />
                <div className={cn('text-18 font-semibold mx-6')}>Dev Mode</div>
                <HamburgerIcon
                    className={cn('ml-4 h-6 w-6 cursor-pointer')}
                    onClick={() => setExpand((val) => !val)}
                />

                <XIcon
                    className={cn('h-7 w-7 cursor-pointer stroke-[3]')}
                    onClick={exit}
                />
            </div>
            {expand && (
                <>
                    <div className={cn('w-full h-0 border-t border-black/10')} />
                    <div className={cn('flex flex-col items-stretch gap-2 ')}>
                        <div
                            className={cn(
                                'flex w-full items-center gap-4 cursor-pointer px-4 bg-black/5 hover:bg-black/10 rounded-md',
                            )}
                            onClick={() => {
                                throw new Error(
                                    'Dev-Mode triggered TestError (this is an error for testing)',
                                );
                            }}
                        >
                            <AlertIcon className={cn('h-4 w-4 stroke-[1]')} />
                            <div className={cn('text-16 font-medium')}>
                                Trigger error
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

function DevModeProvider() {
    useEnterDevMode();
    const isOn = useSelector(IsDevModeOn);

    return isOn ? <DevModeIndication /> : null;
}

export default DevModeProvider;

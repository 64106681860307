import { useAppDispatch } from '@/_helpers/store';
import { setDialogOpen } from '@/_reducers/dialogs';
import {
    IsSupportDialogOpen,
    SupportDialogBaseData,
} from '@/_selectors/generation_1/dialogs';
import { DialogName } from '@/lib/types';
import { useSelector } from 'react-redux';

import SupportDialog from './SupportDialog';

function SupportDialogProvider() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(IsSupportDialogOpen);
    const { triggerSection } = useSelector(SupportDialogBaseData);

    const setOpen = (open: boolean) => {
        dispatch(setDialogOpen(DialogName.Support, open));
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            {isOpen && (
                <SupportDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    triggerSection={triggerSection}
                />
            )}
        </>
    );
}

export default SupportDialogProvider;

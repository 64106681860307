import { CarIcon, FacilityIcon, LocationIcon } from '@/components/svgs';
import { JobMatch } from '@/lib/types';
import { cn } from '@/lib/utils';
import { processes as processesStrings } from '@/strings';
import { ReactNode } from 'react';

const strings = processesStrings.processCard;

function SelectedFacilitySection({
    jobMatch,
    statusRow,
    noBorder,
    orgName,
}: {
    jobMatch: JobMatch;
    orgName: string;
    statusRow?: ReactNode;
    noBorder?: boolean;
}) {
    return (
        <div
            className={cn('relative p-8 border-b border-neutral100', {
                'border-none': noBorder,
            })}
        >
            <div className={cn('flex items-center gap-2')}>
                <FacilityIcon
                    className={cn(
                        'h-4 w-4 stroke-neutral300 fill-neutral300 stroke-[3]',
                    )}
                />
                <div className={cn('text-12 font-semibold leading-[1] text-neutral300')}>
                    {orgName}
                </div>
            </div>
            <div className={cn('text-18 font-bold mt-3')}>{jobMatch.facility.name}</div>
            <div className={cn('mt-2 flex w-full justify-between gap-3')}>
                <div className={cn('flex items-center gap-2')}>
                    <LocationIcon
                        className={cn(
                            'stroke-neutral300 fill-neutral300 w-3.5 shrink-0',
                        )}
                    />
                    <div className={cn('text-12 font-medium text-neutral300')}>
                        {jobMatch.facility.address}
                    </div>
                </div>
                <div className={cn('flex items-center gap-2 shrink-0')}>
                    <div className={cn('text-12 font-semibold')}>
                        {strings.facilityDistance.func(jobMatch.commuteDuration)}
                    </div>
                    <CarIcon />
                </div>
            </div>
            {statusRow && <div className={cn('w-full pt-6')}>{statusRow}</div>}
        </div>
    );
}
export default SelectedFacilitySection;

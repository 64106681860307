import { TextInput } from '@/components/ui';
import { Profession } from '@/lib/types';
import { cn, isCustomProfession } from '@/lib/utils';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function YearsExpStep() {
    const { profession, yearsExp, setYearsExp } = useRegisterFormContext();
    const updateVal = (val: string) => {
        if (val === '') {
            setYearsExp(null);
        } else if (/^\d*$/.test(val)) {
            setYearsExp(Number(val));
        }
    };
    const customProf = isCustomProfession(profession);
    return (
        <RegistrationStepLayout canGoNext={yearsExp !== null}>
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('flex flex-col items-center gap-8')}>
                    <StepTitle
                        title={
                            customProf
                                ? strings.yearsExp.customProfTitle.func(profession)
                                : strings.yearsExp.title.func(profession as Profession)
                        }
                    />
                    <div className={cn('w-full max-w-[350px]')}>
                        <TextInput
                            type='tel'
                            maxLength={2}
                            value={String(yearsExp ?? '')}
                            setValue={updateVal}
                        />
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default YearsExpStep;

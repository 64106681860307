import { DialogContentWithFooter, useDialogContext } from '@/components/ui';
import { OrgPerkCategory } from '@/lib/api/v1';
import {
    ComputedProcessStep,
    OrganizationAdvantageModel,
    OrganizationBenefitModel,
    Process,
    Profession,
} from '@/lib/types';
import {
    cn,
    getActionItemType,
    getComputedProcessStep,
    getSelectedJobMatch,
    isNewProcess,
} from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import ProcessActions from '@/processes/ProcessActions/ProcessActions';
import ProcessHistoryFlow from '@/processes/ProcessHistory/ProcessHistoryFlow';
import ProcessStatusBadge from '@/processes/ProcessStatusBadge';
import { getStatusBadgeText } from '@/processes/utils';
import { processes as processesStrings } from '@/strings';

import AdvantagesSection from './AdvantagesSection';
import MapSection from './MapSection';
import PotentialBenefitsSection from './PotentialBenefitsSection';
import SelectedFacilitySection from './SelectedFacilitySection';
import StatusActionItemRow from './StatusActionItemRow';
import { FULL_HEADER_HEIGHT } from './consts';

const strings = processesStrings.processCard;

interface Props {
    process: Process;
    handleClose: () => void;
}

export function ProcessDetailsDialogContent({ process }: Props) {
    const isNew = isNewProcess(process);
    const showHistory = !isNew;
    const selectedJobMatch = getSelectedJobMatch(process.jobMatches);
    const benefits = process.organization.perks.filter(
        (p) => p.category === OrgPerkCategory.Benefit,
    ) as OrganizationBenefitModel[];
    const advantages = process.organization.perks.filter(
        (p) => p.category === OrgPerkCategory.Advantage,
    ) as OrganizationAdvantageModel[];

    const hasStatusBadge = !!getStatusBadgeText(process);
    const actionItemType = getActionItemType(process);
    const showActionItemRow = !!actionItemType;

    const computedStep = getComputedProcessStep(process);
    const hasActions = [
        ComputedProcessStep.InterviewRequested,
        ComputedProcessStep.New,
    ].includes(computedStep);
    const { isExpandedToTop } = useDialogContext();

    return (
        <DialogContentWithFooter
            footer={
                hasActions ? (
                    <div className={cn('flex justify-center')}>
                        <ProcessActions
                            process={process}
                            shouldShowEventActions
                            contextComponentName={'ExpandedProcessDetailsCard'}
                            isProcessExpaneded
                        />
                    </div>
                ) : null
            }
            footerProps={{ className: cn('px-7 py-10') }}
            bodyProps={{ className: cn('p-0') }}
            onScrollToBottomCallback={() => {
                trackEvent(MixpanelEvent.ProcessCardScrollToBottom, {
                    ...extractProcessDataForMixpanel(process),
                });
            }}
            scrollToBottomDistanceConfig={30}
        >
            <div style={{ height: isExpandedToTop ? FULL_HEADER_HEIGHT : 0 }} />

            <div className={cn('flex flex-col items-stretch gap-10 pb-10 bg-white')}>
                <div>
                    {selectedJobMatch && (
                        <SelectedFacilitySection
                            jobMatch={selectedJobMatch}
                            orgName={process.organization.name}
                            statusRow={
                                hasStatusBadge && !showActionItemRow ? (
                                    <ProcessStatusBadge process={process} />
                                ) : null
                            }
                            noBorder={showActionItemRow}
                        />
                    )}
                    {showActionItemRow && (
                        <StatusActionItemRow
                            process={process}
                            actionItemType={actionItemType}
                        />
                    )}
                </div>

                <div className={cn('w-full px-10 flex flex-col gap-2')}>
                    <div className={cn('text-16 font-bold')}>{strings.aboutOrg}</div>
                    <div className={cn('whitespace-pre-wrap text-14 font-medium')}>
                        {process.organization.description}
                    </div>
                </div>

                {selectedJobMatch && (
                    <div className={cn('relative w-full px-4')}>
                        <MapSection
                            facility={selectedJobMatch.facility}
                            isRegional={process.candidate.profession === Profession.HHA}
                        />
                    </div>
                )}

                <AdvantagesSection advantages={advantages} />

                <PotentialBenefitsSection benefits={benefits} />
                {showHistory && (
                    <>
                        <div className={cn('w-full px-10')}>
                            <div
                                className={cn('w-full h-0 border-b border-neutral100')}
                            />
                        </div>
                        <ProcessHistoryFlow process={process} />
                    </>
                )}
            </div>
        </DialogContentWithFooter>
    );
}

export default ProcessDetailsDialogContent;

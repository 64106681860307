import { useAppDispatch } from '@/_helpers/store';
import { updateCandidateAppState } from '@/_reducers/appState';
import useCandidateAppState from '@/fetchers/useCandidateAppState';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useProcessModels from '@/fetchers/useProcessesModels';
import { toISOString } from '@/lib/base';
import { isNewProcess, isTerminated, valuesOf } from '@/lib/utils';
import { differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';

import { HomePageStatus } from './types';

const RADAR_SEARCH_TIME_SECONDS = 5;

function getHomePageStatus({
    isAppStateReady,
    isCurrentCandidateReady,
    isProcessesReady,
    radarSearchShownSeconds,
    hasProcesses,
    hasExistingProcesses,
    hasOnlyTerminatedProcesses,
}: {
    isAppStateReady: boolean;
    isCurrentCandidateReady: boolean;
    isProcessesReady: boolean;
    radarSearchShownSeconds: number;
    hasProcesses: boolean;
    hasExistingProcesses: boolean;
    hasOnlyTerminatedProcesses: boolean;
}): HomePageStatus {
    if (!isAppStateReady) {
        return HomePageStatus.WaitingData;
    }
    if (!isProcessesReady) {
        return HomePageStatus.WaitingData;
    }
    if (radarSearchShownSeconds <= RADAR_SEARCH_TIME_SECONDS) {
        if (!isCurrentCandidateReady) {
            return HomePageStatus.WaitingData;
        }
        if (!hasExistingProcesses) {
            return HomePageStatus.SearchingFacilities;
        }
    }
    if (!hasProcesses) {
        return HomePageStatus.NoProcesses;
    }
    if (hasOnlyTerminatedProcesses) {
        return HomePageStatus.NoMoreProcesses;
    }
    return HomePageStatus.Active;
}

function useRadarShowTiming(): number {
    const dispatch = useAppDispatch();
    const [recalculate, setRecalculate] = useState(false);
    const {
        isReady: isAppStateReady,
        data: { reachedRadarSearchAt },
    } = useCandidateAppState();
    const { isReady: isCurrentCandidateReady } = useCurrentCandidate();

    const radarSearchShownSeconds = reachedRadarSearchAt
        ? Math.floor(differenceInSeconds(new Date(), new Date(reachedRadarSearchAt)))
        : 0;

    useEffect(() => {
        if (isAppStateReady && !reachedRadarSearchAt && isCurrentCandidateReady) {
            dispatch(
                updateCandidateAppState({
                    reachedRadarSearchAt: toISOString(new Date()),
                }),
            );
        }
        if (radarSearchShownSeconds < RADAR_SEARCH_TIME_SECONDS) {
            const timeout = setTimeout(
                () => {
                    setRecalculate(!recalculate);
                },
                (RADAR_SEARCH_TIME_SECONDS - radarSearchShownSeconds + 1) * 1000,
            );
            return () => clearTimeout(timeout);
        }
    }, [
        isAppStateReady,
        reachedRadarSearchAt,
        radarSearchShownSeconds,
        isCurrentCandidateReady,
        recalculate,
        dispatch,
    ]);

    return radarSearchShownSeconds;
}

function useHomePageStatus() {
    const { isReady: isAppStateReady } = useCandidateAppState();
    const { isReady: isCurrentCandidateReady } = useCurrentCandidate();
    const { isReady: isProcessesReady, data: processes } = useProcessModels();

    const radarShownSeconds = useRadarShowTiming();
    const status = getHomePageStatus({
        isAppStateReady,
        isProcessesReady,
        isCurrentCandidateReady,
        radarSearchShownSeconds: radarShownSeconds,
        hasProcesses: Object.keys(processes).length > 0,
        hasExistingProcesses:
            valuesOf(processes).filter((p) => !isNewProcess(p)).length > 0,
        hasOnlyTerminatedProcesses:
            valuesOf(processes).filter((p) => !isTerminated(p)).length === 0,
    });

    return status;
}

export default useHomePageStatus;

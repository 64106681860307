import apiClient from '@/lib/api';
import { useCallback } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import useRegisterData from '../useRegisterData';
import PhoneNumberForm from './registration/PhoneNumberForm';

function PhoneNumberStep() {
    const { phoneNumber, setPhoneNumber, sendPhoneCode, defaultGoNext } =
        useRegisterFormContext();
    const registerData = useRegisterData();

    const register = useCallback(async () => {
        await apiClient.POST('/api/v1/public/register', {
            body: registerData,
        });
    }, [registerData]);

    const onSendPhoneCode = useCallback(
        async (phone: string) => {
            await sendPhoneCode(phone).then(defaultGoNext);
        },
        [sendPhoneCode, defaultGoNext],
    );

    return (
        <PhoneNumberForm
            sendPhoneCode={onSendPhoneCode}
            phone={phoneNumber}
            setPhone={setPhoneNumber}
            register={register}
        />
    );
}

export default PhoneNumberStep;

import { cn } from '@/lib/utils';
import { statusPage as strings } from '@/strings';
import UserMenu from '@/userMenu/UserMenu';

export default function StatusPageHeader() {
    return (
        <div className={cn('relative w-full flex items-center justify-between')}>
            <div className={cn('text-16 font-bold')}>{strings.header.title}</div>
            <UserMenu />
        </div>
    );
}

import { QueryParam } from '@/lib/types';

export const utmParams = [
    QueryParam.UTM_SOURCE,
    QueryParam.UTM_MEDIUM,
    QueryParam.UTM_CAMPAIGN,
    QueryParam.UTM_TERM,
    QueryParam.UTM_CONTENT,
    QueryParam.UTM_ID,
    QueryParam.AD_ID,
    QueryParam.ADSET_ID,
    QueryParam.PLACEMENT,
    QueryParam.SITE_SOURCE_NAME,
];

import { useAppDispatch } from '@/_helpers/store';
import { syncNewScreeningEvent } from '@/_reducers/screening';
import { useState } from 'react';
import { EventScheduledEvent } from 'react-calendly';

function useRegisterScheduledEvent() {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const syncNew = async (uri: string) => dispatch(syncNewScreeningEvent(uri));
    const registerEvent = async (e: EventScheduledEvent) => {
        const invitee_uri = e.data.payload.invitee.uri;
        setLoading(true);
        await syncNew(invitee_uri).finally(() => setLoading(false));
    };

    return {
        isLoading: loading,
        registerEvent,
    };
}

export default useRegisterScheduledEvent;

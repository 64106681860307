import { LoadingButton } from '@/components/ui';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from './RegisterContext';
import { RegistrationFooterProps } from './types';

function RegistrationFooter({
    disabled = false,
    verifier,
    sideEffect,
    additionalButtons,
    nextButtonProps,
}: RegistrationFooterProps) {
    const { defaultGoNext, customGoNextAction, currentStep, currentStepIndex } =
        useRegisterFormContext();
    const goNext = () => {
        trackEvent(MixpanelEvent.RegistrationClickNextStep, {
            current_step: currentStep,
            current_step_index: currentStepIndex,
        });
        if (verifier && !verifier()) {
            return;
        }
        sideEffect && sideEffect();
        defaultGoNext();
    };
    return (
        <div
            className={
                'relative w-full flex flex-col items-center gap-8 py-8 px-12 border-t border-neutral200'
            }
        >
            <LoadingButton
                className={cn('w-full max-w-[350px] text-14 font-bold')}
                size={'lg'}
                disabled={disabled}
                onClick={customGoNextAction ?? goNext}
                {...nextButtonProps}
            >
                {strings.nextStep}
            </LoadingButton>
            {additionalButtons}
        </div>
    );
}

export default RegistrationFooter;

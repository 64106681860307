import { fetchScreeningLink } from '@/_reducers/screening';
import { CurrentScreeningLink, ScreeningLinkFetcherData } from '@/_selectors';

import useFetcherBase from './useFetcherBase';

function useScreeningScheduleLink() {
    return useFetcherBase({
        fetchFunc: fetchScreeningLink,
        dataSelector: CurrentScreeningLink,
        fetcherSelector: ScreeningLinkFetcherData,
    });
}

export default useScreeningScheduleLink;

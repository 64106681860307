import { FetcherState, ScreeningEvent } from '@/lib/types';

export enum FetcherName {
    Link = 'link',
    Event = 'screeningEvent',
}

export interface ScreeningSliceState {
    data: {
        link: string | null;
        screening: ScreeningEvent | null;
    };
    fetchers: Record<FetcherName, FetcherState>;
}

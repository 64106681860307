import { AppPageId } from '@/lib/types';
import { HomePage, ProfilePage, RegisterPage, StatusPage } from '@/pages';
import OnboardingIntroCallPage from '@/pages/introCall/OnboardingIntroCallPage';
import ScheduleIntroCallPage from '@/pages/introCall/ScheduleIntroCallPage';

export const pageIdToView = {
    [AppPageId.Home]: <HomePage />,
    [AppPageId.Profile]: <ProfilePage />,
    [AppPageId.Status]: <StatusPage />,
    [AppPageId.Register]: <RegisterPage />,
    [AppPageId.ScheduleIntroCall]: <ScheduleIntroCallPage />,
    [AppPageId.OnboardingScheduleIntroCall]: <OnboardingIntroCallPage />,
};

export const anonymousPageIdToView = {
    [AppPageId.Register]: <RegisterPage />,
};

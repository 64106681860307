import useDigitsMaskInputVal from '@/hooks/useDigitsMaskInputVal';
import { formatPhoneNumberToInputVal } from '@/lib/utils';
import { useEffect } from 'react';

import { PHONE_NUMBER_LENGTH } from './consts';

function isFullAmericanPhoneWithPrefix(phoneNumber: string) {
    return phoneNumber.length === PHONE_NUMBER_LENGTH + 1 && phoneNumber.startsWith('1');
}

function usePhoneInputVal({
    phone: externalPhoneState,
    setPhone: externalSetPhoneState,
    phoneInputRef: externalInputRef,
}: {
    phone?: string;
    setPhone?: (phone: string) => void;
    phoneInputRef?: React.RefObject<HTMLInputElement>;
}) {
    const {
        textValue: phone,
        setTextValue: setPhone,
        digitsValue: phoneNumber,
        inputRef: phoneInputRef,
    } = useDigitsMaskInputVal({
        value: externalPhoneState,
        setValue: externalSetPhoneState,
        inputRef: externalInputRef,
        formatDigitsToTextVal: formatPhoneNumberToInputVal, // Format digits to phone number
    });

    // Handle cases where phone number exceeds the allowed length
    useEffect(() => {
        if (phoneNumber.length > PHONE_NUMBER_LENGTH) {
            let formattedDigits = phoneNumber.slice(0, PHONE_NUMBER_LENGTH);
            if (isFullAmericanPhoneWithPrefix(phoneNumber)) {
                formattedDigits = phoneNumber.slice(1);
            }
            setPhone(formatPhoneNumberToInputVal(formattedDigits));
        }
    }, [phoneNumber, setPhone]);

    return {
        phone, // formatted phone number for text input
        setPhone, // handle new input value
        phoneNumber, // phone number without formatting
        additionalChars: phone.length - phoneNumber.length, // additional characters count in the formatted phone number
        phoneInputRef, // ref for the input element
    };
}

export default usePhoneInputVal;

import Loader from '@/components/lotties/Loader';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useCurrentScreeningEvent from '@/fetchers/useCurrentScreeningEvent';
import useProcessModels from '@/fetchers/useProcessesModels';
import { ScreeningFlowState, useScreeningState } from '@/introCall';
import { cn, isInProgress, valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useEffect } from 'react';

import PostScreeningMsg from './PostScreeningMsg';
import ScheduleIntroCallSection from './ScheduleIntroCallSection';
import ScreeningEventView from './ScreeningEventView';
import ScreeningSectionFooter from './ScreeningSectionFooter';

enum ExtraViewState {
    ActiveProcesses = 'hasActiveProcesses',
}
type ScreeningViewState = ScreeningFlowState | ExtraViewState;

function getScreeningViewState({
    screeningState,
    hasActiveProcesses,
    isProcessesReady,
}: {
    screeningState: ScreeningFlowState;
    hasActiveProcesses: boolean;
    isProcessesReady: boolean;
}): ScreeningViewState {
    if (screeningState === ScreeningFlowState.Done) {
        if (!isProcessesReady) {
            return ScreeningFlowState.WaitingData;
        }
        if (hasActiveProcesses) {
            return ExtraViewState.ActiveProcesses;
        }
    }
    return screeningState;
}

function ScreeningSection() {
    const screeningFlowState = useScreeningState();
    const { refresh } = useCurrentScreeningEvent();
    const { data: currentCandidate } = useCurrentCandidate();
    const { isReady: isProcessesReady, data: processes } = useProcessModels();
    const hasOngoingProcesses = valuesOf(processes).some(isInProgress);

    const screeningViewState = getScreeningViewState({
        screeningState: screeningFlowState,
        isProcessesReady,
        hasActiveProcesses: hasOngoingProcesses,
    });

    useEffect(() => {
        if (screeningViewState === ScreeningFlowState.WaitingData) {
            return;
        }
        trackEvent(MixpanelEvent.VisitIntroCallSection, {
            intro_call_status:
                screeningViewState === ScreeningFlowState.Pending
                    ? 'should_schedule'
                    : screeningViewState,
        });
    }, [screeningViewState]);

    const isScreeningEventDataNeeded =
        !currentCandidate?.hadIntroConversation &&
        screeningViewState !== ScreeningFlowState.WaitingData;
    useEffect(() => {
        if (isScreeningEventDataNeeded) {
            refresh();
        }
    }, [refresh, isScreeningEventDataNeeded]);

    return (
        <>
            {screeningViewState === ScreeningFlowState.WaitingData && (
                <>
                    <div
                        className={cn(
                            'flex justify-center items-center py-12 h-64 w-full',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' delay={700} />
                    </div>
                    <ScreeningSectionFooter
                        hasOngoingProcesses={hasOngoingProcesses}
                        withDelimiter
                    />
                </>
            )}
            {screeningViewState === ScreeningFlowState.Pending && (
                <ScheduleIntroCallSection />
            )}
            {screeningViewState === ScreeningFlowState.Scheduled && (
                <>
                    <ScreeningEventView />
                    <ScreeningSectionFooter
                        hasOngoingProcesses={hasOngoingProcesses}
                        withDelimiter={false}
                    />
                </>
            )}
            {screeningViewState === ScreeningFlowState.Done && (
                <>
                    <PostScreeningMsg />
                    <ScreeningSectionFooter
                        hasOngoingProcesses={hasOngoingProcesses}
                        withDelimiter
                    />
                </>
            )}
        </>
    );
}

export default ScreeningSection;

import { EditIcon } from '@/components/svgs';
import useExternalState from '@/hooks/useExternalState';
import { cn } from '@/lib/utils';

import { BaseTextInput } from '../textInput';
import { EditableFieldProps, FieldClearErrorsPlan } from './types';

function EditableField({
    editElement,
    displayValue,
    label,
    bottomElement,
    error,
    setError,
    onEdit: externalOnEdit,
    setOnEdit: externalSetOnEdit,
    showError = true,
    showOnEdit = false,
    handleFocus = () => {},
    clearErrorsPlan = FieldClearErrorsPlan.ChangeOnly,
    errorElemProps = {},
    labelProps = {},
    rootProps = {},
    ...props
}: EditableFieldProps) {
    const [onEdit, setOnEdit] = useExternalState(externalOnEdit, externalSetOnEdit);
    const indicateError = !!error && showError;

    const clearError = () => setError && setError('');
    const resetError = () => {
        if (indicateError) {
            clearError();
        }
    };

    const enterEditMode = () => {
        if (clearErrorsPlan === FieldClearErrorsPlan.All) {
            resetError();
        }
        if (!onEdit) {
            setOnEdit(true);
        }
    };
    const handleClick = () => {
        enterEditMode();
    };
    const onFocus = () => {
        handleFocus();
        enterEditMode();
    };

    return (
        <div {...rootProps} className={cn('w-full relative', props.className)}>
            {label && (
                <div
                    {...labelProps}
                    className={cn('font-semibold text-13 mb-2', labelProps?.className)}
                >
                    {label}
                </div>
            )}
            <div className={cn('relative w-full')}>
                {(!onEdit || showOnEdit) && (
                    <BaseTextInput
                        rootProps={{
                            onClick: handleClick,
                        }}
                        value={displayValue}
                        onChange={() => {}}
                        onFocus={onFocus}
                        {...props}
                        suffixElement={
                            onEdit ? null : (
                                <div className={cn('p-2')}>
                                    <EditIcon className={cn('w-4 h-4')} />
                                </div>
                            )
                        }
                        className={cn(
                            { 'cursor-pointer border-neutral300': !onEdit },
                            {
                                'border-red200 text-red200': indicateError,
                            },
                            props.className,
                        )}
                        inputClassName={cn('cursor-pointer overflow-ellipsis')}
                    />
                )}
                {editElement}
            </div>
            {bottomElement}
            {indicateError && (
                <p
                    {...errorElemProps}
                    className={cn(
                        'mt-4 font-medium text-red200 text-14',
                        errorElemProps?.className,
                    )}
                >
                    {error}
                </p>
            )}
        </div>
    );
}

export default EditableField;

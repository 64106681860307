import store from '@/_helpers/store';
import SentryController from '@/sentry/SentryController';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';

import App from './App';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <SentryController>
            <StoreProvider store={store}>
                <App />
            </StoreProvider>
        </SentryController>
    </React.StrictMode>,
);

import { useCallback, useState } from 'react';

const useSetOpen = (
    externalOpen?: boolean,
    setExternalOpen?: (bool: boolean) => void,
    onOpenCallback?: () => void,
) => {
    const [internalOpen, setInternalOpen] = useState(false);
    const isControlled = setExternalOpen !== undefined && externalOpen !== undefined;
    const open = isControlled ? externalOpen : internalOpen;
    const setOpen = isControlled ? setExternalOpen : setInternalOpen;

    const handleOpenChange = useCallback(
        (isOpen: boolean) => {
            setOpen(isOpen);
            if (isOpen) {
                onOpenCallback && onOpenCallback();
            }
        },
        [setOpen, onOpenCallback],
    );
    const handleClose = () => {
        handleOpenChange(false);
    };
    const handleOpen = () => {
        handleOpenChange(true);
    };

    return {
        open,
        setOpen: handleOpenChange,
        handleOpen,
        handleClose,
    };
};

export default useSetOpen;

import { fetchCurrentCandidate } from '@/_reducers/candidate';
import { CurrentCandidate, CurrentCandidateFetcherData } from '@/_selectors';

import useFetcherBase from './useFetcherBase';

function useCurrentCandidate() {
    return useFetcherBase({
        fetchFunc: fetchCurrentCandidate,
        dataSelector: CurrentCandidate,
        fetcherSelector: CurrentCandidateFetcherData,
    });
}

export default useCurrentCandidate;

import useStateWithChangeCallback from '@/hooks/useStateWithChangeCallback';
import { cn } from '@/lib/utils';
import { useCallback, useEffect, useRef } from 'react';

import { BaseTextInput } from '../textInput';
import EditableField from './EditableField';
import { EditableTextFieldProps } from './types';

function EditableTextField({
    originalValue,
    value,
    setValue,
    error,
    setError,
    onEditStateChange,
    extraInputs,
    extraInputsContainerProps,
    hasChangeOverride,
    ...props
}: EditableTextFieldProps) {
    const [onEdit, setOnEdit] = useStateWithChangeCallback(onEditStateChange, {
        defaultValue: false,
    });
    const hasChange = hasChangeOverride || value !== originalValue;
    const showError = hasChange;
    const indicateError = showError && !!error;
    const inputRef = useRef<HTMLInputElement>(null);
    const extraInputsContainerRef = useRef<HTMLDivElement>(null);
    const inputContainerRef = useRef<HTMLDivElement>(null);

    const clearError = () => setError && setError('');
    const resetError = () => {
        if (indicateError) {
            clearError();
        }
    };

    const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setValue(ev.target.value);
        resetError();
    };

    const handleFocusOut = useCallback(
        (target: Element | null) => {
            if (
                extraInputsContainerRef.current?.contains(target) ||
                inputContainerRef.current?.contains(target)
            ) {
                return;
            }
            setOnEdit(false);
        },
        [setOnEdit],
    );

    const onBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
        handleFocusOut(e.relatedTarget);
    };

    useEffect(() => {
        if (onEdit && inputRef.current) {
            inputRef.current.focus();
        }
    }, [onEdit]);

    useEffect(() => {
        if (!onEdit) {
            return;
        }
        const onFocusOut = (e: FocusEvent) => {
            handleFocusOut(e.relatedTarget as Element | null);
        };
        const extraInputsContainer = extraInputsContainerRef.current;
        extraInputsContainer?.addEventListener('focusout', onFocusOut);
        return () => {
            extraInputsContainer?.removeEventListener('focusout', onFocusOut);
        };
    }, [onEdit, handleFocusOut]);

    return (
        <EditableField
            displayValue={value}
            showError={showError}
            error={error}
            setError={setError}
            onEdit={onEdit}
            setOnEdit={setOnEdit}
            editElement={
                onEdit ? (
                    <BaseTextInput
                        rootProps={{
                            onBlur,
                            tabIndex: -1,
                        }}
                        containerRef={inputContainerRef}
                        ref={inputRef}
                        value={value}
                        onChange={handleTextChange}
                        {...props}
                        className={cn(
                            {
                                'border-red200 text-red200': indicateError,
                            },
                            props.className,
                        )}
                        inputClassName={cn('overflow-ellipsis')}
                    />
                ) : null
            }
            {...props}
            bottomElement={
                extraInputs && onEdit ? (
                    <div
                        ref={extraInputsContainerRef}
                        {...extraInputsContainerProps}
                        className={cn('mt-2', extraInputsContainerProps?.className)}
                    >
                        {extraInputs}
                    </div>
                ) : null
            }
        />
    );
}

export default EditableTextField;

import { RootState } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { FetchModelStatus, FetcherState, ProcessHistory } from '@/lib/types';
import { generateEmptyFetcherState } from '@/lib/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchProcessHistory = createAsyncThunk<
    ProcessHistory[],
    { processId: string },
    { state: RootState }
>('processHistory/fetchProcessHistory', async ({ processId }) => {
    const response = await apiClient.GET('/api/v1/processes/{process_id}/history', {
        params: { path: { process_id: processId } },
    });
    return response.data!;
});

type ProcessHistoryData = {
    history: ProcessHistory[];
    fetcher: FetcherState;
};
type ProcessHistoryState = Record<string, ProcessHistoryData>;

const historySlice = createSlice({
    name: 'processHistory',
    initialState: {} as ProcessHistoryState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProcessHistory.pending, (state, action) => {
                const { processId } = action.meta.arg;
                if (!state[processId]) {
                    state[processId] = {
                        history: [],
                        fetcher: generateEmptyFetcherState(),
                    };
                }
                state[processId].fetcher.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchProcessHistory.fulfilled, (state, action) => {
                const { processId } = action.meta.arg;
                const processState = state[processId];
                processState.fetcher.status = FetchModelStatus.SUCCESS;
                processState.fetcher.initiated = true;
                processState.history = action.payload;
            })
            .addCase(fetchProcessHistory.rejected, (state, action) => {
                const { processId } = action.meta.arg;
                const processState = state[processId];
                processState.fetcher.status = FetchModelStatus.FAILURE;
                processState.fetcher.error = action.error.message;
            });
    },
});

export default historySlice;

import useStateWithChangeCallback from '@/hooks/useStateWithChangeCallback';
import { cn } from '@/lib/utils';

import { MultiSelect } from '../select';
import { multiValueToStringVal } from '../select/utils';
import EditableField from './EditableField';
import { EditableMultiSelectFieldProps } from './types';

function EditableMultiSelectField<T extends string>({
    originalValue,
    value,
    setValue,
    error,
    options,
    transformator,
    onEditStateChange,
    ...props
}: EditableMultiSelectFieldProps<T>) {
    const [onEdit, setOnEdit] = useStateWithChangeCallback(onEditStateChange, {
        defaultValue: false,
    });
    const hasChange =
        multiValueToStringVal(value) !== multiValueToStringVal(originalValue);
    const showError = hasChange && !onEdit;

    const transformValFunc = transformator ?? multiValueToStringVal;

    return (
        <EditableField
            displayValue={transformValFunc(value)}
            showOnEdit={true}
            showError={showError}
            error={error}
            onEdit={onEdit}
            setOnEdit={setOnEdit}
            editElement={
                <MultiSelect
                    value={value}
                    onChange={setValue}
                    open={onEdit}
                    onOpenChange={setOnEdit}
                    triggerProps={{
                        className: cn(
                            'opacity-0 w-full h-[54px] absolute top-0 left-0 cursor-pointer',
                        ),
                    }}
                    options={options}
                />
            }
            {...props}
        />
    );
}

export default EditableMultiSelectField;

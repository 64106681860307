import { stringToDigitsOnly } from './common';

export const INTERNATIONAL_PREFIX = '+1';

export function formatPhoneNumberToInputVal(phoneNumber: string) {
    const phoneLength = phoneNumber.length;
    if (phoneLength < 4) return phoneNumber;
    if (phoneLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
}

export function formatInternationalPhoneNumber({
    phone,
    prefix,
}: {
    phone: string;
    prefix: string;
}) {
    const phoneNumber = stringToDigitsOnly(phone);
    const inputStyleFormatted = formatPhoneNumberToInputVal(phoneNumber);
    const formatted = `${inputStyleFormatted.replace(/-/g, ' ').replace(/[()]/g, '')}`;
    return `${prefix} ${formatted}`;
}

export function formattedPhoneToInternationalNumber(phone: string) {
    const phoneNumber = stringToDigitsOnly(phone);
    return `${INTERNATIONAL_PREFIX}${phoneNumber}`;
}
